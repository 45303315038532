import React, { useEffect, useRef, useState } from "react"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"
import { LoaderSpinner } from "@/components/PartsViewer/v2/icon"
import Conditional from "@/components/core/Conditional/Conditional"
import PopModal from "@/components/PartsViewer/v2/PartsDiagramViewer/PopModal"
import PartsControls from "@/components/PartsViewer/v2/PartsDiagramViewer/PartsControls"
import { LOCAL_PREFIX } from "@/components/PartsViewer/v2/utils"
import createInteractiveSVGElement from "@/components/PartsViewer/v2/PartsDiagramViewer/interactiveSvg"

const PartsDiagramViewer = props => {
  const { selectedFile, fullscreen, expandHandler, showPopup, title, sku } =
    props
  const transformComponentRef = useRef(null)
  const wrapper = useRef(null)
  const container = useRef(null)
  const [svgData, setSvgData] = useState()
  const [status, setStatus] = useState("loading")
  const [scale, setScale] = useState(1)
  const [initScale, setInitScale] = useState(1)
  const [message, setMessage] = useState(false)
  const [position, setPosition] = useState({
    left: 0,
    top: 0,
    textWidth: 0,
    textHeight: 0,
    showTop: 0,
    textBounds: {},
  })
  useEffect(() => {
    const aspect =
      (container.current.offsetHeight / container.current.offsetWidth) * 0.85
    setInitScale(aspect > 0.9 ? aspect : 0.9)
    return () => {
      container.current = false
    }
  }, [])

  useEffect(() => {
    setMessage(false)
    setStatus("loading")
    const abortController = new AbortController()
    getSelectedFile(abortController)
    return () => {
      abortController.abort()
      setMessage(false)
      setStatus("loading")
      setSvgData("")
    }
  }, [selectedFile?.URL])

  useEffect(() => {
    if (wrapper?.current) wrapper.current.appendChild(svgData)
  }, [svgData, position])

  const getSelectedFile = async abortController => {
    try {
      const response = await fetch(LOCAL_PREFIX + selectedFile?.URL, {
        signal: abortController?.signal,
      })
      const svgText = await response.text()
      const svgElement = createInteractiveSVGElement(svgText, {
        click: clickAction,
      })
      setStatus("loaded")
      setSvgData(svgElement)
    } catch (error) {
      setStatus("error")
    }
  }

  const clickAction = async (e, relations) => {
    setMessage(false)
    let text = e.currentTarget.getAttribute("class")
    text = text.replaceAll("active", "").replaceAll(" ", "")
    if (selectedFile.Parts.filter(item => item.Cod == text).length == 0) {
      partNotFoundMessage(e.currentTarget)
      setMessage(true)
      showPopup(text)
    } else {
      showPopup(text)
    }
  }

  const partNotFoundMessage = textNode => {
    const svgElement = wrapper.current.children[0]
    const viewBox = svgElement.getAttribute("viewBox").split(" ")
    const boxWidth = viewBox?.[2]
    const boxHeight = viewBox?.[3]
    const boxAspect = boxWidth / boxHeight
    const { top } = textNode.getBoundingClientRect()
    const svgWidth = svgElement.width.baseVal.value
    const svgHeight = svgElement.height.baseVal.value

    const wrapperWidth = wrapper.current.offsetWidth
    const wrapperHeight = wrapper.current.offsetHeight
    const wrapperAspect = wrapperWidth / wrapperHeight

    let leftOffset = 0
    let topOffset = 0
    let leftValue = 0
    let topValue = 0
    let actualSVGWidth = 0
    let actualSVGHeight = 0
    let svgScaleVertical = 1
    let svgScaleHorizontal = 1

    if (wrapperAspect > boxAspect) {
      actualSVGWidth = svgHeight * boxAspect
      actualSVGHeight = svgHeight
      leftOffset = (svgWidth - actualSVGWidth) / 2
      topOffset = 0
    } else {
      actualSVGWidth = svgWidth
      actualSVGHeight = svgWidth / boxAspect
      leftOffset = 0
      topOffset = (svgHeight - actualSVGHeight) / 2
    }
    svgScaleHorizontal = actualSVGWidth / boxWidth
    svgScaleVertical = actualSVGHeight / boxHeight
    leftValue = (textNode.getAttribute("x") / boxWidth) * actualSVGWidth
    topValue = (textNode.getAttribute("y") / boxHeight) * actualSVGHeight
    setPosition({
      left: leftValue + leftOffset,
      top: topValue + topOffset,
      textWidth: textNode.getComputedTextLength() * svgScaleHorizontal,
      textHeight: (textNode.getBBox().height * svgScaleVertical) / scale,
      showTop: top,
      textBounds: textNode.getBoundingClientRect(),
    })
  }

  return (
    <>
      <Conditional show={status == "loading"}>
        <div className="d-flex w-100 h-100 justify-content-center align-items-center">
          <LoaderSpinner />
        </div>
        <div className="image-wrapper" ref={container}></div>
      </Conditional>
      <Conditional show={status == "loaded"}>
        <TransformWrapper
          initialScale={initScale}
          velocityAnimation={{ disabled: true }}
          ref={transformComponentRef}
          zoomAnimation={{ disabled: true }}
          centerOnInit={true}
          minScale={0.5}
          onTransformed={e => setScale(e?.state?.scale)}
          onInit={() => {}}
          pinch={{ step: 2 }}
          wheel={{ step: 0.1 }}
        >
          {utils => (
            <React.Fragment>
              <TransformComponent>
                {message ? (
                  <PopModal
                    position={position}
                    setMessage={setMessage}
                    scale={scale}
                  />
                ) : null}
                <div className="image-wrapper" ref={wrapper}></div>
              </TransformComponent>
              <PartsControls
                {...utils}
                expandHandler={expandHandler}
                fullscreen={fullscreen}
                scale={scale}
                initScale={initScale}
                setMessage={setMessage}
                title={title}
                sku={sku}
              />
            </React.Fragment>
          )}
        </TransformWrapper>
      </Conditional>
    </>
  )
}

export default PartsDiagramViewer
