import React from "react"
import { NUMBER_HEADING_REGEX } from "@/constants/regex"

const Heading = props => {
  const { level = 6, fontSize, fontWeight, className = "", children } = props
  const Tag = NUMBER_HEADING_REGEX.test(`${level}`) ? "h" + level : "h6"
  let classes = ""
  classes = (fontSize ? `fs-${fontSize}` : "") + " "
  classes += fontWeight ? `fw-${fontWeight}` : ""
  if (!classes) classes = ""
  return <Tag className={`${classes} ${className}`}>{children}</Tag>
}

export default Heading
