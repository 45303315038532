import MenuItem from "@/components/Shared/HeaderNavTabs/MenuItem/index"

const NavigationAccordion = props => {
  const isMobile = typeof window !== "undefined" && window.innerWidth <= 1200

  const {
    levelTwo: {
      groupLink = "",
      mobileLabel = "",
      groupTitle = "",
      levelThreeNavMulti = [],
    } = {},
    getInternalLinkURLS = () => {},
    darkThemeFlag = false,
    selectedTab = "",
    servicesAnalyticsClick = () => {},
  } = props

  return (
    <>
      {groupLink && mobileLabel && isMobile ? (
        <MenuItem
          getInternalLinkURLS={getInternalLinkURLS}
          itemLink={groupLink}
          itemLabel={mobileLabel}
          darkThemeFlag={darkThemeFlag}
          selectedTab={selectedTab}
          groupTitle={groupTitle}
          servicesAnalyticsClick={servicesAnalyticsClick}
        />
      ) : null}
      {levelThreeNavMulti?.map((columnItems, index) => {
        const { link = "", label = "" } = columnItems
        return (
          <div key={label + index}>
            <MenuItem
              getInternalLinkURLS={getInternalLinkURLS}
              itemLink={link}
              itemLabel={label}
              darkThemeFlag={darkThemeFlag}
              selectedTab={selectedTab}
              groupTitle={groupTitle}
              servicesAnalyticsClick={servicesAnalyticsClick}
            />
          </div>
        )
      })}
    </>
  )
}

export default NavigationAccordion
