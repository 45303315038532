import {
  formatPhoneNumber,
  getAnalyticsUrl,
  getInternalLinkTypeForFindStore,
  isShowAnalyticsEventLoaded,
  navigationType,
  pushAnalyticsObjToDataLayer,
  sanitizeTextForAnalytics,
} from "@/utils/helper"

const pageType = "find a store"

export const addFindAStoreAnalytics = params => {
  const {
    total,
    errorMessage,
    status,
    action,
    authorData,
    staticTexts,
    searchTerm,
    geolocationEvent,
  } = params

  const errMsg = sanitizeTextForAnalytics(
    authorData.locationErrormessage
      ? authorData.locationErrormessage
      : staticTexts.locationErrormessage
  )

  let eventAction = "find a store:landing:location info:store search"
  let eventName = "find a store:landing:location info:store search"
  const eventStatus = status === "success" && total ? "success" : "failure"
  let internalLinkName = "store search"
  let internalLinkZoneName = "find a store:landing:location info:filters"

  if (geolocationEvent) {
    eventAction = "find a store:utility nav​:view store details:use my location"
    eventName = "find a store:utility nav​:view store details:use my location"
    internalLinkName = "use my location"
    internalLinkZoneName = "find a store:utility nav"
  }

  const currDate = new Date()
  const eventInfo = {
    searchCount: sessionStorage.getItem("findStores")
      ? Number(sessionStorage.getItem("findStores")) + 1
      : 1,
    searchResult: total,
    internalLinkName,
    internalLinkPosition: "find a store",
    internalLinkType: "find a store:navigation",
    searchType: isNaN(searchTerm) || geolocationEvent ? "location" : "zipcode",
    searchTerm: searchTerm,
    eventAction,
    eventName,
    eventType: "navigation",
    clickInternalLinks: "true",
    internalLinkURL: `${window.location.origin}${window.location.pathname}`,
    internalLinkZoneName,
    eventMsg:
      status === "success" && total ? "n/a" : errorMessage || errMsg || "n/a",
    eventStatus,
    pageName: "find a store result",
    pageURL: window.location.href,
    referrer: document.referrer || "n/a",
    previousPage: document.referrer || "n/a",
    timeStamp:
      currDate.toISOString().split("T")[0] +
      " " +
      currDate.toTimeString().split(" ")[0],
  }
  if (
    (action === "cmp:show" && !isShowAnalyticsEventLoaded()) ||
    action !== "cmp:show"
  ) {
    sessionStorage.setItem("findStores", eventInfo.searchCount)
    const { adobeDataLayer: { getState } = {} } = window
    const event = !isShowAnalyticsEventLoaded() ? "cmp:show" : action
    const page =
      event === "cmp:show"
        ? window.pageObj
        : (getState && getState("page")) || {}

    if (
      (navigationType() !== 1 && event === "cmp:show") ||
      event === "cmp:click"
    ) {
      pushAnalyticsObjToDataLayer(eventInfo, event, page)
    }

    delete window.pageObj
  }
}

export const findAStoreLoadMoreAnalytics = () => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: "find a store:landing:location info:load more results",
    eventMsg: "n/a",
    eventName: "find a store:landing:location info:load more results",
    eventStatus: "n/a",
    eventType: "navigation",
    internalLinkName: "load more results",
    internalLinkPosition: "find a store",
    internalLinkType: "find a store:load more results",
    internalLinkURL: `${window.location.origin}${window.location.pathname}`,
    internalLinkZoneName: "find a store:landing:location info",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

export const getFilterEventInfo = showFilter => {
  const type = showFilter ? "show filter" : "hide filter"
  const internalLinkType = `find a store:${showFilter ? "show" : "hide"}`
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `find a store:landing:location info:${type}`,
    eventName: `find a store:landing:location info:${type}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: `${type}s`,
    internalLinkPosition: "find a store",
    internalLinkType,
    internalLinkURL: `${window.location.origin}${window.location.pathname}`,
    internalLinkZoneName: "find a store:landing:filters",
  }

  pushAnalyticsObjToDataLayer(eventInfo)
}

export const getStoreEventInfo = (type, internalLinkName, link) => {
  return JSON.stringify({
    eventAction: "find a store:landing:location info:" + type,
    eventName: "find a store:landing:location info:" + type,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: internalLinkName ? internalLinkName.toLowerCase() : type,
    internalLinkPosition: "find a store",
    internalLinkType: "find a store:navigation",
    internalLinkZoneName: "find a store:landing:location info",
    internalLinkURL: link
      ? (link.indexOf("http") === -1 ? window.location.origin : "") + link
      : "n/a",
    clickInternalLinks: "true",
  })
}

export const getEventStoreFilterInfo = (data, type, link) => {
  let internalLinkName
  let internalLinkType = sanitizeTextForAnalytics(data.LocationName)
  const internalLinkTypeForFindStore = getInternalLinkTypeForFindStore(
    data.LocationName,
    data.Locality,
    data.StateLong
  )
  if (type === "store name") {
    internalLinkName = sanitizeTextForAnalytics(data.LocationName)
    internalLinkType = `find a store:${internalLinkTypeForFindStore}`
  } else if (type === "store contact number") {
    internalLinkName = formatPhoneNumber(data.Phone)
    internalLinkType = "find a store:navigation"
  } else if (
    type === "set as preferred store" ||
    type === "unset as preferred store"
  ) {
    internalLinkType = `find a store:${internalLinkTypeForFindStore}`
  }
  return JSON.stringify({
    eventAction: `find a store:landing:location info:${type}`,
    eventName: `find a store:landing:location info:${type}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: internalLinkName || type,
    internalLinkPosition: "find a store",
    internalLinkType,
    internalLinkZoneName: "find a store:landing:location info​",
    internalLinkURL: link
      ? (type !== "store contact number" && link.indexOf("http") === -1
          ? window.location.origin
          : "") + link
      : "n/a",
    clickInternalLinks: "true",
  })
}

export const getEventStorePhoneInfo = (PhoneNumber, tabTitle, link) => {
  const internalLinkPhoneType = formatPhoneNumber(PhoneNumber)
  const eventInfo = {
    eventAction: `find a store:landing:location info:${internalLinkPhoneType}`,
    eventName: `find a store:landing:location info:${internalLinkPhoneType}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: internalLinkPhoneType,
    internalLinkPosition: "find a store",
    internalLinkType: `find a store:${tabTitle?.toLowerCase()}`,
    internalLinkZoneName: "find a store:landing:location info​",
    internalLinkURL:
      internalLinkPhoneType === null ? link || "n/a" : `tel:${PhoneNumber}`,
    clickInternalLinks: "true",
  }
  window?.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
  })
}

export // Reason of handling this in a seperate function is to add the appliedFilters in filterArray prop of dataLayer
const addAnalyticsData = (curFilters, isChecked, obj, isFilterCancel) => {
  const { facet, value: label } = obj

  const filterLabel = label || obj.display

  const appliedOrRemoved = isFilterCancel
    ? "cancel"
    : isChecked
    ? "applied"
    : "removed"
  const eventInfo = {
    eventAction: `find a store:landing:filters:${appliedOrRemoved}`,
    eventName: `find a store:landing:filters:${appliedOrRemoved}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: `${filterLabel?.toLowerCase()}:${appliedOrRemoved}`,
    internalLinkPosition: "find a store",
    internalLinkType: `find a store:${facet?.toLowerCase()}`,
    filterArray: `${facet?.toLowerCase() + ":" + filterLabel?.toLowerCase()}`,
    internalLinkZoneName: "find a store:landing:filters",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }

  let filterArray = ""
  if (curFilters.length > 0) {
    curFilters.forEach(facetObj => {
      facetObj.value.forEach(facetValue => {
        filterArray +=
          facetObj.facet.toLowerCase() + ":" + facetValue.toLowerCase() + "|"
      })
    })
  }
  eventInfo.filterArray = filterArray?.slice(0, -1)

  if (window.adobeDataLayer) {
    const { adobeDataLayer: { getState } = {} } = window
    const page = (getState && getState("page")) || {}
    window.adobeDataLayer.push({
      event: "cmp:click",
      eventInfo,
      page,
    })
  }
}

export const addFilterExpandCollapseAnalytics = (
  facet,
  formatFacetName,
  collapse
) => {
  const collapseOrExpand = collapse[facet] ? "expand" : "collapse"

  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `find a store:landing:filters:${collapseOrExpand}`,
    eventName: `find a store:landing:filters:${collapseOrExpand}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: `${collapseOrExpand}`,
    internalLinkPosition: "find a store",
    internalLinkType: `find a store:${formatFacetName?.toLowerCase()}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: "find a store:landing:filters​",
  }

  pushAnalyticsObjToDataLayer(eventInfo)
}

export const getEventClearFilterInfo = () => {
  return JSON.stringify({
    eventAction: "find a store:landing:filters:clear all",
    eventName: "find a store:landing:filters:clear all",
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "clear all",
    internalLinkPosition: "find a store",
    internalLinkType: "find a store:filters",
    internalLinkZoneName: "find a store:landing:filters",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  })
}

export const addAnalyticsEvent = (action, isForLocateMe = false) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const type = isForLocateMe ? "" : "zoom "
  const linkType = isForLocateMe ? "navigation" : "zoom"
  const eventInfo = {
    eventAction: `find a store:landing:map:${type}${action}`,
    eventName: `find a store:landing:map:${type}${action}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: `${type}${action}`,
    internalLinkPosition: "find a store",
    internalLinkType: `find a store:${linkType}`,
    internalLinkZoneName: "find a store:landing:map",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    page,
  })
}

export const setStoreAnalytics = (checked, locationName) => {
  const type = checked ? "set as preferred store" : "unset as preferred store"

  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `find a store:landing:location info:${type}`,
    eventMsg: "n/a",
    eventName: `find a store:landing:location info:${type}`,
    eventStatus: "n/a",
    eventType: "navigation",
    internalLinkName: type,
    internalLinkPosition: "find a store",
    internalLinkType: `find a store:${locationName?.toLowerCase()}`,
    internalLinkURL: `${window.location.origin}${window.location.pathname}`,
    internalLinkZoneName: "find a store:landing:location info",
  }

  pushAnalyticsObjToDataLayer(eventInfo)
}

export const zipCodeInfoModalContinueAnalytics = (
  ctaLabel,
  dataLayerPosition
) => {
  const label = ctaLabel?.toLowerCase()
  let eventName = `find a store:utility nav:view store details:${label}`
  let internalLinkPosition = "find a store"
  let internalLinkType = "find a store:navigation"
  let internalLinkZoneName = "find a store:modal:cant access your location"
  if (dataLayerPosition === "global header") {
    eventName = `find a store:utility nav:use my location:${label}`
    internalLinkPosition = "global header:find a store"
  }

  if (dataLayerPosition === "find a pro") {
    eventName = `find a pro:see local professionals:use my location:${label}`
    internalLinkPosition = "find a pro"
    internalLinkType = "find a pro:use my location"
    internalLinkZoneName = "find a pro:see local professionals"
  }

  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: eventName,
    eventMsg: "n/a",
    eventName,
    eventStatus: "n/a",
    eventType: "navigation",
    internalLinkName: `${label}`,
    internalLinkPosition,
    internalLinkType,
    internalLinkURL: `${window.location.origin}${window.location.pathname}`,
    internalLinkZoneName,
  }

  pushAnalyticsObjToDataLayer(eventInfo)
}

export const locationMessageNavigationAnalytics = ({
  link = "",
  label = "",
  zone = "",
}) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:${zone}:${label}`,
    eventMsg: "n/a",
    eventName: `${pageType}:${zone}:${label}`,
    eventStatus: "n/a",
    eventType: "navigation",
    internalLinkName: label,
    internalLinkPosition: pageType,
    internalLinkType: `${pageType}:navigation`,
    internalLinkURL: getAnalyticsUrl(link),
    internalLinkZoneName: `${pageType}:${zone}`,
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}
