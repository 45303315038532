export const getEventInfo = (phone, url, partnerName, isEmail) => {
  const internalLinkName = isEmail
    ? "services@kohler.com"
    : phone || partnerName
  const type = phone ? `contact ${isEmail ? "email" : "phone"}` : partnerName
  return JSON.stringify({
    clickInternalLinks: "true",
    eventAction: `find a pro:designer:main content area:${type}`,
    eventName: `find a pro:designer:main content area:${type}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName,
    internalLinkPosition: "find a pro results",
    internalLinkType: `find a pro:designer:${partnerName}:contact`,
    internalLinkURL: url,
    internalLinkZoneName: "find a pro:designer:main content area",
  })
}
