const createInteractiveSVGElement = (svgText, actions) => {
  const wrapper = document.createElement("div")
  wrapper.innerHTML = svgText
  const svg = wrapper.getElementsByTagName("svg")?.[0]

  const groupNodeList = svg.querySelectorAll("g")
  const groupList = [...groupNodeList]?.filter(
    item => item.getAttribute("itedo:apstype") == "Part"
  )
  groupList.forEach(item => {
    mutateGroupText(item)
  })
  const textNodeList = svg.querySelectorAll("text")
  const itemList = [...textNodeList]?.filter(
    item => item.getAttribute("itedo:apstype") == "Part"
  )
  itemList.forEach(item => bindEvents(item, actions, svg))

  return svg
}

export default createInteractiveSVGElement

const bindEvents = (item, actions, context = document) => {
  item.addEventListener(
    "mouseover",
    e => {
      const list = getObjectRelations(e.currentTarget)
      const relations = getNodes(list, context)
      addActionMultiple(relations, mouseOver)
    },
    false
  )

  item.addEventListener(
    "mouseout",
    e => {
      const list = getObjectRelations(e.currentTarget)
      const relations = getNodes(list, context)
      addActionMultiple(relations, mouseOut)
    },
    false
  )

  item.addEventListener(
    "click",
    e => {
      const list = getObjectRelations(e.currentTarget)
      const relations = getNodes(list, context)
      actions.click(e, relations)
    },
    false
  )
}

const mutateGroupText = groupNode => {
  const textList = groupNode.querySelectorAll("text")
  const hotspotList = groupNode.getAttribute("kohler_illustration:hotspot_list")

  ;[...textList]?.forEach(item => {
    item.classList.add(groupNode.classList)
    item.setAttribute("kohler_illustration:hotspot_list", hotspotList)
    item.setAttribute("itedo:apstype", "Part")
  })
}

const addActionMultiple = (list, action) => list.forEach(node => action(node))

const getNodes = (list, context) => list.map(id => context.getElementById(id))

const getObjectRelations = node => {
  const listString = node.getAttribute("kohler_illustration:hotspot_list")
  let list = []
  if (typeof listString == "string") list = listString.split(",")
  return list
}

const mouseOver = node => node.classList.add("active")
const mouseOut = node => node.classList.remove("active")
