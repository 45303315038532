import DOMPurify from "isomorphic-dompurify"

import { IS, NS, keyCodes, selectors } from "@/constants"

const that = {}
let dataLayerEnabled
let dataLayer

export const getDataLayerId = componentDataLayer => {
  return Object.keys(JSON.parse(componentDataLayer))[0]
}
export const readData = (element = {}) => {
  const { dataset: data = {} } = element

  const options = []
  let capitalized = IS
  capitalized = capitalized?.charAt(0)?.toUpperCase() + capitalized?.slice(1)
  const reserved = ["is", "hook" + capitalized]
  for (const key in data) {
    if (data?.hasOwnProperty(key)) {
      const value = data[key]

      if (key?.indexOf(NS) === 0) {
        const newKey = key?.slice(NS.length)
        const finalKey = newKey?.charAt(0)?.toLowerCase() + newKey?.substring(1)
        if (reserved?.indexOf(finalKey) === -1) {
          options[finalKey] = value
        }
      }
    }
  }

  return options
}
export const getActiveIndex = (tabs = []) => {
  if (tabs) {
    return tabs?.findIndex(tab =>
      tab?.classList?.contains(selectors?.active?.tab)
    )
  }
  return 0
}
export const cacheElements = (wrapper = {}) => {
  that._elements = {}
  that._elements.self = wrapper
  const hooks = that?._elements?.self?.querySelectorAll(
    "[data-" + NS + "-hook-" + IS + "]"
  )

  for (const hook of hooks) {
    if (hook?.closest("." + NS + "-" + IS) === that?._elements?.self) {
      // only process own tab elements
      let capitalized = IS
      capitalized =
        capitalized?.charAt(0)?.toUpperCase() + capitalized?.slice(1)
      const key = hook?.dataset[NS + "Hook" + capitalized]
      if (that?._elements[key]) {
        if (!Array?.isArray(that?._elements[key])) {
          const tmp = that?._elements[key]
          that._elements[key] = [tmp]
        }
        that?._elements[key]?.push(hook)
      } else {
        that._elements[key] = hook
      }
    }
  }
}
export const focusWithoutScroll = (element = {}) => {
  const x = window?.scrollX || window?.pageXOffset
  const y = window?.scrollY || window?.pageYOffset
  element?.focus()
  window?.scrollTo(x, y)
}
export const refreshActive = () => {
  const tabpanels = that?._elements["tabpanel"]
  const tabs = that?._elements["tab"]

  const headerTabs = document?.querySelectorAll(
    ".header-mega-menu-container .cmp-tabs div.cmp-tabs__tabpanel"
  )

  if (tabpanels) {
    if (Array.isArray(tabpanels)) {
      tabpanels?.forEach((tabpanel, i) => {
        if (
          i === parseInt(that?._active) &&
          !headerTabs[that?._active]?.classList?.contains(
            "cmp-tabs__tabpanel--active"
          )
        ) {
          tabpanel?.classList?.add(selectors?.active?.tabpanel)
          tabpanel?.removeAttribute("aria-hidden")
          tabs[i]?.classList?.add(selectors?.active?.tab)
          tabs[i]?.setAttribute("aria-selected", true)
          tabs[i]?.setAttribute("tabindex", "0")
        } else {
          tabpanel?.classList?.remove(selectors?.active?.tabpanel)
          tabpanel?.setAttribute("aria-hidden", true)
          tabs[i]?.classList?.remove(selectors?.active?.tab)
          tabs[i]?.setAttribute("aria-selected", false)
        }
      })
    } else {
      // only one tab
      tabpanels?.classList?.add(selectors?.active?.tabpanel)
      tabs?.classList?.add(selectors?.active?.tab)
    }
  }
}
export const navigate = (index = 0) => {
  that._active = index
  refreshActive()
}
export const navigateAndFocusTab = (index = 0) => {
  const exActive = that?._active
  navigate(index)
  focusWithoutScroll(that?._elements["tab"][index])

  if (dataLayerEnabled) {
    const activeItem = getDataLayerId(
      that?._elements?.tabpanel[index]?.dataset?.gbhDataLayer
    )
    const exActiveItem = getDataLayerId(
      that?._elements?.tabpanel[exActive]?.dataset?.gbhDataLayer
    )

    dataLayer?.push({
      event: "cmp:show",
      eventInfo: {
        path: "component." + activeItem,
      },
    })

    dataLayer?.push({
      event: "cmp:hide",
      eventInfo: {
        path: "component." + exActiveItem,
      },
    })

    const tabsId = that?._elements?.self?.id
    const uploadPayload = { component: {} }
    uploadPayload.component[tabsId] = { shownItems: [activeItem] }

    const removePayload = { component: {} }
    removePayload.component[tabsId] = { shownItems: undefined }

    dataLayer.push(removePayload)
    dataLayer.push(uploadPayload)
  }
}
export const onKeyDown = (event = {}) => {
  const index = that?._active
  const lastIndex = that?._elements["tab"]?.length - 1
  const { ARROW_LEFT, ARROW_UP, ARROW_RIGHT, ARROW_DOWN, HOME, END } = keyCodes
  const { keyCode = "" } = event
  switch (keyCode) {
    case ARROW_LEFT:
    case ARROW_UP:
      event?.preventDefault()
      if (index > 0) {
        navigateAndFocusTab(index - 1)
      }
      break
    case ARROW_RIGHT:
    case ARROW_DOWN:
      event?.preventDefault()
      if (index < lastIndex) {
        navigateAndFocusTab(index + 1)
      }
      break
    case HOME:
      event?.preventDefault()
      navigateAndFocusTab(0)
      break
    case END:
      event?.preventDefault()
      navigateAndFocusTab(lastIndex)
      break
    default:
      return
  }
}
export const bindEvents = () => {
  const tabs = that?._elements["tab"]
  if (tabs) {
    tabs?.forEach((tab, index) => {
      tab.addEventListener("click", () => {
        navigateAndFocusTab(index)
      })
      tab.addEventListener("keydown", event => {
        onKeyDown(event)
      })
    })
  }
}

export const init = (config = {}) => {
  that._config = config

  // prevents multiple initialization
  config?.element?.removeAttribute("data-" + NS + "-is")

  cacheElements(config?.element)
  that._active = getActiveIndex(that?._elements["tab"])

  if (that?._elements?.tabpanel) {
    refreshActive()
    bindEvents()
  }
}

export const tabs = (config = {}) => {
  if (config?.element) {
    init(config)
  }
}

export const onDocumentReady = () => {
  dataLayerEnabled = false
  dataLayer = dataLayerEnabled ? window?.adobeDataLayer || [] : undefined

  const elements = document?.querySelectorAll(selectors?.self)
  elements?.forEach((element = {}) => {
    tabs({
      element: element,
      options: readData(element),
    })
  })

  const MutationObserver =
    window?.MutationObserver ||
    window?.WebKitMutationObserver ||
    window?.MozMutationObserver
  const body = document?.querySelector("body")

  const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      // needed for IE
      const nodesArray = []?.slice?.call(mutation.addedNodes)
      if (nodesArray?.length > 0) {
        nodesArray?.forEach(addedNode => {
          if (addedNode?.querySelectorAll) {
            const elementsArray = []?.slice?.call(
              addedNode?.querySelectorAll(selectors?.self)
            )
            elementsArray?.forEach(element => {
              tabs({
                element: element,
                options: readData(element),
              })
            })
          }
        })
      }
    })
  })

  observer?.observe(body, {
    subtree: true,
    childList: true,
    characterData: true,
  })
}

export const getActiveHash = cb => {
  const url = new URL(window?.location?.href)
  const hashed = DOMPurify?.sanitize(url?.hash)

  if (hashed?.length === 0) {
    return false
  }

  const hashValue = decodeURIComponent(hashed?.slice(1))
  if (hashValue !== "") {
    const tabElements = document?.querySelectorAll(
      ".cmp-tabs .cmp-tabs__tablist .cmp-tabs__tab"
    )
    tabElements?.forEach((child = {}) => {
      const attr = child?.getAttribute("id")
      if (attr !== null) {
        child?.classList?.remove("cmp-tabs__tab--active")
      }
      if (attr === hashValue) {
        child?.classList?.add("cmp-tabs__tab--active")
      }
    })

    const tabPanelElements = document?.querySelectorAll(
      ".cmp-tabs .cmp-tabs__tabpanel"
    )
    tabPanelElements?.forEach((child = {}) => {
      const attr = child?.getAttribute("data-hashid")?.replaceAll("-", " ")
      if (attr !== null) {
        child?.classList?.remove("cmp-tabs__tabpanel--active")
      }
      if (attr === hashValue) {
        child?.classList?.add("cmp-tabs__tabpanel--active")
        cb && cb(hashValue)
      }
    })
  }
}
export const setTabAnimation = (
  tab = {},
  isTransitionDurationRemove = false
) => {
  const parent = tab?.closest(".tabs")
  const vertical =
    parent?.classList?.contains("vertical-tabs") &&
    window?.matchMedia("(min-width: 1024px)")?.matches
  const borderClr =
    parent?.classList?.contains("bg-charcoal") ||
    parent?.classList?.contains("bg-slate") ||
    parent?.closest(".container")?.classList?.contains("bg-charcoal") ||
    parent?.closest(".container")?.classList?.contains("bg-slate")
      ? "#FFF"
      : "#212121"
  const tabList = tab?.parentElement
  const selectionBar = tabList?.querySelector(".selectionBarcss")
  selectionBar.style.borderColor = borderClr
  selectionBar.style.transitionDuration = isTransitionDurationRemove
    ? "0s"
    : ".5s"
  document?.querySelectorAll(".cmp-tabs__tablist")?.forEach((element = {}) => {
    element.style.position = "relative"
  })

  const rectTargetTab = tab?.getBoundingClientRect()
  const rectTabList = tabList?.getBoundingClientRect()

  if (vertical) {
    const selectionBarWidth = rectTargetTab?.height / rectTabList?.height
    const selectionBarPosition = rectTargetTab?.bottom - rectTabList?.bottom
    selectionBar.style.height = rectTabList?.height + "px"
    selectionBar.style.transform = `matrix(1, 0, 0, ${
      selectionBarWidth + tabList?.scrollTop
    }, 0, ${selectionBarPosition})`
  } else {
    const selectionBarWidth = rectTargetTab?.width / rectTabList?.width
    const selectionBarPosition = rectTargetTab?.left - rectTabList?.left
    selectionBar.style.transform = `matrix(${selectionBarWidth}, 0, 0, 1, ${
      selectionBarPosition + tabList?.scrollLeft
    }, -1)`
  }
}
export const handleResizeEvent = () => {
  const tabContainer = document?.querySelectorAll(".tabs")
  tabContainer?.forEach((tabs = {}) => {
    if (!tabs?.classList?.contains("gbh-global-tab")) {
      const activeTab = tabs?.querySelector(
        ".cmp-tabs__tab.cmp-tabs__tab--active"
      )
      if (activeTab) {
        setTabAnimation(activeTab, true)
      }
    }
  })
}
export const handleHashchangeEvent = () => {
  getActiveHash()
  const tabContainer = document?.querySelectorAll(".tabs")
  tabContainer?.forEach((tabs = {}) => {
    if (!tabs?.classList?.contains("gbh-global-tab")) {
      const activeTab = tabs?.querySelector(
        ".cmp-tabs__tab.cmp-tabs__tab--active"
      )
      if (activeTab) {
        setTabAnimation(activeTab, false)
      }
    }
  })
}
