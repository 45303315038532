import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"

const RadioButton = ({
  onKeydown,
  analyticsData,
  id = "",
  name = "",
  value = undefined,
  checked = undefined,
  onClick = () => {},
}) => {
  return (
    <label
      role="radio"
      onKeyDown={onKeydown}
      className={cx({ radio: true })}
      htmlFor={id}
      aria-checked={checked}
      tabIndex={checked ? 0 : -1}
    >
      <input
        type="radio"
        className={"radio__input " + (analyticsData ? "gbh-data-layer" : "")}
        data-gbh-data-layer={analyticsData}
        name={name}
        id={id}
        checked={checked}
        onClick={onClick}
        onChange={onClick}
      />
      <span className="radio__custom"></span>
      <span>{value}</span>
    </label>
  )
}

RadioButton.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string || PropTypes.number,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
}

export default RadioButton
