import React, { useContext, useEffect } from "react"
import { useDispatch } from "react-redux"

import useIsSsr from "@/hooks/useIsSsr"
import { COMPONENT_TYPES, groupedComponents } from "@/constants"
import {
  isAemForm,
  isBrandHeader,
  isCarousel,
  isFooter,
  isHeaderNav,
  isSlick,
  isTab,
  isTeaser,
  isText,
  isVideo,
  isImage,
  isImageV3,
  isCartLanding,
  isPairsWellWth,
  isCheckoutLanding,
  isEmbed,
  isSharedCart,
  isOrderConfirmation,
  isOrderDetails,
  isOrderHistory,
  isOrderStatus,
  isAuthModal,
  isNewsLetter,
  isSpacer,
  ConditionalWrapper,
  isBreadcrumb,
  isProductList,
  isProductDetails,
  isSearch,
  isSiteWideWarning,
  isPromoBanner,
  isToaster,
  isCompareProducts,
  isNewsLetterSignUp,
  isHelpSupport,
  isAccountSettings,
  isMyservice,
  isProductAccordion,
  isProductReview,
  isRegisterOffline,
  isRegisterOnline,
  isMyFavorites,
  isChangePassword,
  isMyproducts,
  isProductcard,
  isProductcardV3,
  isProductExfPdp,
  isKnowledgeArticle,
  isBrowseAllStores,
  isLiterature,
  isContactUs,
  isFindingModelNumber,
  isTechnicalSpec,
  isContainer,
  isPressLanding,
  isUpSellContainer,
  isFindStore,
  isStoreFilter,
  isInPageSearch,
  isTitle,
  isKeySpecifier,
  isDynamicSearch,
  isAssociateMembership,
  isInspireVideo,
  isImageCluster,
  isShopTheRoomCard,
  isShopTheRoomDetail,
  isRoomDetail,
  isInstallDesignServices,
  isProfessionalDetail,
  isFriendsAndFamily,
  isFindAProByZip,
  isStoreAppointment,
  isGatedContent,
  isProductCardV4,
  isPDPCuratedProducts,
  isShopTheRoomLandingPage,
  isHeaderNavV3,
} from "@/utils"

import AemGridColumn from "@/components/AemGridColumn"

import BrandHeader from "@/components/Shared/BrandHeader"
import HeaderNav from "@/components/Shared/HeaderNav"
import Video from "@/components/Default/Video/v1/Video"
import Carousel from "@/components/Default/Carousel/v1/Carousel"
import CarouselSlick from "@/components/Default/CarouselSlick/v1/CarouselSlick"
import Footer from "@/components/Shared/Footer"
import CustomImage from "@/components/Default/Image/v3/Image"
import HelpSupport from "@/components/HelpSupport/v1/HelpSupport"
import Teaser from "@/components/Default/Teaser/v1/Teaser"
import Text from "@/components/Default/Text/v1/Text"
import Tab from "@/components/Default/Tab/v1/Tab"
import Embed from "@/components/Shared/Embed"
import Spacer from "@/components/Default/Spacer/v1/Spacer"
import PromoBanner from "@/components/Default/PromoBanner/v1/PromoBanner"
import AemForm from "@/components/Default/AemForm"
import ProductCard from "@/components/productCard/v1/ProductCard"
import ProductCardV3 from "@/components/productCard/v3/ProductCard"
import FindingModelNumber from "@/components/FindingModelNumber/v1/FindingModelNumber"
import ImageV1 from "@/components/Default/Image/v1/Image"
import InPageSearch from "@/components/InPageSearch/v1/InPageSearch"
import Title from "@/components/Default/Title"
import DynamicSearch from "@/components/Default/DynamicSearch/v1/DynamicSearch"
import InspireVideo from "@/components/Default/Video/v2/Video"
import ImageCluster from "@/components/ImageCluster/v1/ImageCluster"
import ShopTheRoomCard from "@/components/ShopTheRoomCard/v1/ShopTheRoomCard"

import { setAuthModalStaticText } from "@/store/features/authSlice"
import {
  setSubscribeModalContent,
  setSitewideWarningContent,
  setCompareproductsStaticText,
  setSitewideNewsLetterContent,
  setToastContent,
  setGatedContentData,
} from "@/store/features/genericSlice"
import { setStoreFilter } from "@/store/features/locationSlice"
import VirtualDesignerDetails from "@/components/VirtualDesignerDetails/v1/VirtualDesignerDetails"
import FindAProByZip from "@/components/FindAProByZip/v1/FindAProByZip"
import StoreAppointment from "@/components/StoreAppointment/v1/StoreAppointment"
import ShopTheRoomDetail from "@/components/ShopTheRoomDetail/v1/ShopTheRoomDetail"
import RoomDetailPage from "@/components/RoomDetailPage/v1/RoomDetailPage"
import ProductCardForUpsell from "@/components/Shared/UpsellInstallServices/ProductCardForUpsell"
import ComponentContext from "@/utils/ComponentImport"

const AemGrid = props => {
  const {
    data = {},
    itemKey = "",
    count = 0,
    eventHandler = {},
    fullData = {},
    containerId = "",
    ...extraProps
  } = props
  const dispatch = useDispatch()
  const isSsr = useIsSsr()
  const componentContexts = useContext(ComponentContext)

  useEffect(() => {
    if (isAuthModal(data)) {
      dispatch(setAuthModalStaticText(data))
    }

    if (isCompareProducts(data)) {
      dispatch(setCompareproductsStaticText(data))
    }

    if (isNewsLetter(data)) {
      dispatch(setSubscribeModalContent(data))
    }

    if (isSiteWideWarning(data)) {
      dispatch(setSitewideWarningContent(data))
    }
    if (isNewsLetterSignUp(data)) {
      dispatch(setSitewideNewsLetterContent(data))
    }
    if (isToaster(data)) {
      dispatch(setToastContent(data))
    }
    if (isStoreFilter(data)) {
      dispatch(setStoreFilter(data))
    }
    if (isGatedContent(data)) {
      dispatch(setGatedContentData(data))
    }
  }, [data])
  const {
    title = "",
    urlNames = "",
    knowledgeArticle = () => {},
    technicalSpecifications = () => {},
    myFavorites = () => {},
    myProducts = () => {},
    myGenericFiller = () => {},
    myServices = () => {},
    accountSettings = () => {},
    contactUs = () => {},
    contactUsFiller = () => {},
    pressReleaseLanding = () => {},
    findStore = () => {},
    keySpecifier = () => {},
    associateMembership = () => {},
    friendsAndFamily = () => {},
    installDesignServices = () => {},
    isScrollMarginRequired = false,
    changePassword = () => {},
  } = extraProps

  const itemsOrderValue = data[":itemsOrder"] || []
  const isWrapperRequired = groupedComponents?.every(value =>
    itemsOrderValue?.some(val => val?.includes(value))
  )

  if (isInspireVideo(data)) {
    return <InspireVideo data={data} />
  }

  if (isVideo(data)) {
    return <Video data={data} />
  }

  if (isSpacer(data)) {
    return <Spacer data={data} />
  }

  if (isImageCluster(data)) {
    return !isSsr ? <ImageCluster data={data} /> : null
  }

  if (isImage(data)) {
    return !isSsr ? <ImageV1 data={data} /> : null
  }

  if (isImageV3(data)) {
    return <CustomImage data={data} />
  }

  if (isAemForm(data)) {
    return <AemForm data={data} fullData={fullData} />
  }

  if (isProductCardV4(data)) {
    return !isSsr ? <ProductCardForUpsell authData={data} /> : null
  }

  if (isProductcardV3(data)) {
    const { skewId = "", presetConfigs = "" } = data
    return !isSsr ? (
      <div className="productcard-v3 productcard">
        <ProductCardV3
          authorData={{ skewId, presetConfigs }}
          fullData={fullData}
        />
      </div>
    ) : null
  }

  if (isProductcard(data)) {
    return <ProductCard data={data} fullData={fullData} />
  }

  if (isCarousel(data)) {
    return <Carousel data={data} fullData={fullData} />
  }

  if (isPromoBanner(data)) {
    return <PromoBanner data={data} />
  }

  if (isSlick(data)) {
    return <CarouselSlick fullData={fullData} data={data} />
  }

  if (isTeaser(data)) {
    return <Teaser data={data} fullData={fullData} containerId={containerId} />
  }

  if (isText(data)) {
    return <Text data={data} />
  }

  if (isInPageSearch(data)) {
    return !isSsr ? <InPageSearch data={data} /> : null
  }

  if (isTab(data) && data["appliedCssClassNames"]) {
    return <Tab data={data} itemKey={itemKey} {...extraProps} />
  }

  if (isBrandHeader(data)) {
    return <BrandHeader data={data} eventHandler={eventHandler} />
  }

  if (isHeaderNav(data)) {
    return <HeaderNav data={data} eventHandler={eventHandler} />
  }
  if (isHeaderNavV3(data)) {
    return <HeaderNav data={data} eventHandler={eventHandler} v3 />
  }

  if (isEmbed(data)) {
    return <Embed data={data} eventHandler={eventHandler} />
  }

  if (isFooter(data)) {
    return <Footer data={data} eventHandler={eventHandler} />
  }

  if (isShopTheRoomDetail(data)) {
    return <ShopTheRoomDetail data={data} />
  }

  if (isRoomDetail(data)) {
    return <RoomDetailPage data={data} />
  }

  if (isShopTheRoomCard(data)) {
    return (
      <ShopTheRoomCard
        cardTitle={data?.cardTitle}
        cardHoverTitle={data?.cardHoverTitle}
        ctalink={data?.ctalink}
        landingImageLink={data?.landingImageLink}
        modelImageLink={data?.modelImageLink}
        skuIds={data?.skuIds}
        presetConfigs={data?.presetConfigs ?? "{}"}
        altModel={data?.altModel}
        altLanding={data?.altLanding}
        linkNewTab={data?.linkNewTab}
      />
    )
  }

  if (isCartLanding(data)) {
    if (!isSsr) {
      return extraProps && extraProps.landing && extraProps.landing(data)
    } else {
      return (
        extraProps && extraProps.landingFiller && extraProps.landingFiller(data)
      )
    }
  }

  if (isUpSellContainer(data)) {
    return (
      extraProps &&
      extraProps.upSellContainer &&
      extraProps.upSellContainer(data)
    )
  }

  if (isCheckoutLanding(data) && !isSsr) {
    return (
      extraProps && extraProps.checkoutView && extraProps.checkoutView(data)
    )
  }

  if (isOrderConfirmation(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.orderConfirmation &&
        extraProps.orderConfirmation(data)
      )
    } else {
      return (
        extraProps &&
        extraProps.orderConfirmationTagFiller &&
        extraProps.orderConfirmationTagFiller()
      )
    }
  }

  if (isOrderStatus(data)) {
    if (!isSsr) {
      return (
        extraProps && extraProps.orderStatus && extraProps.orderStatus(data)
      )
    } else {
      return (
        extraProps &&
        extraProps.orderStatusFiller &&
        extraProps.orderStatusFiller()
      )
    }
  }

  if (isOrderHistory(data) && !isSsr) {
    return (
      extraProps && extraProps.orderHistory && extraProps.orderHistory(data)
    )
  }

  if (isOrderDetails(data)) {
    if (!isSsr) {
      return (
        extraProps && extraProps.orderDetails && extraProps.orderDetails(data)
      )
    } else {
      return (
        extraProps &&
        extraProps.orderDetailsFiller &&
        extraProps.orderDetailsFiller(data)
      )
    }
  }

  if (isPairsWellWth(data) && !isSsr) {
    return (
      extraProps && extraProps.pairsWellWith && extraProps.pairsWellWith(data)
    )
  }

  if (isSharedCart(data) && !isSsr) {
    return extraProps && extraProps.sharedCart && extraProps.sharedCart(data)
  }

  if (isShopTheRoomLandingPage(data)) {
    return (
      <>
        {componentContexts.map(({ Component, componentProps }, index) => (
          <Component
            key={`${Component.name}-${index}`}
            data={data}
            {...(componentProps.isFullData
              ? { ...componentProps, fullData: fullData }
              : componentProps)}
          />
        ))}
      </>
    )
  }

  if (isBreadcrumb(data)) {
    return (
      extraProps &&
      extraProps.breadCrumb &&
      extraProps.breadCrumb(data, fullData)
    )
  }

  if (isProductList(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.productList &&
        extraProps.productList(data, eventHandler)
      )
    } else {
      return (
        extraProps &&
        extraProps.productListFiller &&
        extraProps.productListFiller(data)
      )
    }
  }
  if (isSearch(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.searchComponent &&
        extraProps.searchComponent(data, eventHandler)
      )
    } else {
      return (
        extraProps && extraProps.searchFiller && extraProps.searchFiller(data)
      )
    }
  }

  if (isHelpSupport(data) && !isSsr) {
    return <HelpSupport data={data} />
  }

  if (isAccountSettings(data)) {
    if (!isSsr) {
      return accountSettings(data)
    } else {
      return myGenericFiller(data, title)
    }
  }
  if (isMyservice(data)) {
    if (!isSsr) {
      return myServices(data)
    } else {
      return myGenericFiller(data, title)
    }
  }
  if (isMyFavorites(data)) {
    if (!isSsr) {
      return myFavorites(data)
    } else {
      return myGenericFiller(data, title)
    }
  }
  if (isChangePassword(data)) {
    if (!isSsr) {
      return changePassword(data)
    } else {
      return "Loading..."
    }
  }

  if (isMyproducts(data)) {
    if (!isSsr) {
      return myProducts(data)
    } else {
      return myGenericFiller(data, title)
    }
  }
  if (isLiterature(data) && !isSsr) {
    return extraProps && extraProps.literature && extraProps.literature(data)
  }

  if (isTechnicalSpec(data)) {
    return technicalSpecifications && technicalSpecifications(data)
  }

  if (isProductDetails(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.productDetail &&
        extraProps.productDetail(data, extraProps?.slug, extraProps?.skuId)
      )
    } else {
      return (
        extraProps &&
        extraProps.productDetailFiller &&
        extraProps.productDetailFiller(data)
      )
    }
  }
  if (isProductAccordion(data) && !isSsr) {
    return (
      extraProps &&
      extraProps.productAccordion &&
      extraProps.productAccordion(data)
    )
  }

  if (isProductExfPdp(data) && !isSsr) {
    return extraProps && extraProps.productExf && extraProps.productExf(data)
  }

  if (isProductReview(data) && !isSsr) {
    return extraProps && extraProps.review && extraProps.review(data)
  }

  if (isRegisterOffline(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.offlineProducts &&
        extraProps.offlineProducts(data)
      )
    } else {
      return (
        extraProps &&
        extraProps.offlineProducts &&
        extraProps.offlineProductsFiller()
      )
    }
  }

  if (isRegisterOnline(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.onlineProducts &&
        extraProps.onlineProducts(data)
      )
    } else {
      return (
        extraProps &&
        extraProps.onlineProducts &&
        extraProps.offlineProductsFiller()
      )
    }
  }

  if (isKnowledgeArticle(data)) {
    return extraProps && knowledgeArticle(data, urlNames)
  }

  if (isBrowseAllStores(data) && !isSsr) {
    return (
      extraProps &&
      extraProps.browseAllStores &&
      extraProps.browseAllStores(data)
    )
  }

  if (isContactUs(data)) {
    if (!isSsr) {
      return contactUs(data)
    } else {
      return contactUsFiller()
    }
  }
  if (isFindingModelNumber(data) && !isSsr) {
    return <FindingModelNumber data={data} />
  }
  if (isPressLanding(data) && !isSsr) {
    return pressReleaseLanding(data)
  }
  if (isFindStore(data) && !isSsr) {
    return findStore(data)
  }
  if (isTitle(data) && !isSsr) {
    return <Title data={data} />
  }
  if (isKeySpecifier(data)) {
    return keySpecifier(data)
  }

  if (isDynamicSearch(data) && !isSsr) {
    const {
      presetConfigs = {},
      disableParts = false,
      enablePotraitTile = false,
    } = data
    return (
      <DynamicSearch
        presetConfigs={presetConfigs}
        disableParts={disableParts}
        enablePotraitTile={enablePotraitTile}
      />
    )
  }

  if (isAssociateMembership(data)) {
    return associateMembership(data)
  }

  if (isInstallDesignServices(data) && !isSsr) {
    return installDesignServices(data)
  }

  if (isProfessionalDetail(data) && !isSsr) {
    return <VirtualDesignerDetails data={data} />
  }

  if (isFriendsAndFamily(data)) {
    return friendsAndFamily(data)
  }

  if (isFindAProByZip(data)) {
    return !isSsr ? <FindAProByZip data={data} /> : null
  }
  if (isStoreAppointment(data) && !isSsr) {
    return <StoreAppointment data={data} />
  }
  if (isPDPCuratedProducts(data)) {
    return (
      extraProps &&
      extraProps.pdpCuratedProduct &&
      extraProps.pdpCuratedProduct(data)
    )
  }

  return (
    <>
      <ConditionalWrapper
        condition={
          (isContainer(data) && itemKey === COMPONENT_TYPES.ROOT_CONTAINER) ||
          isWrapperRequired
        }
        wrapper={children => (
          <div
            className={`${data?.gridClassNames} ${
              isScrollMarginRequired ? "unsetPageScrollMargin" : ""
            }`}
          >
            {children}
          </div>
        )}
      >
        <AemGridColumn
          data={data}
          itemKey={itemKey}
          count={count}
          eventHandler={eventHandler}
          containerId={containerId}
          {...extraProps}
        />
      </ConditionalWrapper>
    </>
  )
}

export default AemGrid
