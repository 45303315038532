import { getInternalLinkTypeForFindStore } from "@/utils/helper"
export const getLegalPageAnalytics = (
  storeData,
  title,
  link,
  needJSON = false
) => {
  const internalLinkType = `find a store:${getInternalLinkTypeForFindStore(
    storeData.BillingStreet,
    storeData.BillingCity,
    storeData.BillingState,
    storeData.AEM_Microsite_Name__c
  )}`

  const eventAction = `find a store:store detail page:store experience:modal:book an appointment:${title}`

  const internalLinkZoneName =
    "find a store:store detail page:store experience:modal:book an appointment"

  let internalLinkName

  switch (title) {
    case "thankyou:done":
      internalLinkName = "done"
      break
    case "thankyou:view store details":
      internalLinkName = "view store details"
      break
    case "thankyou:close":
      internalLinkName = "close"
      break
    default:
      internalLinkName = title
  }

  let modifiedInternalLinkZoneName = internalLinkZoneName

  if (
    title === "thankyou:done" ||
    title === "thankyou:view store details" ||
    title === "thankyou:close"
  ) {
    modifiedInternalLinkZoneName += ":thankyou"
  }

  const eventInfo = {
    eventAction,
    eventName: eventAction,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName,
    internalLinkPosition: "store appointment",
    internalLinkType,
    internalLinkZoneName: modifiedInternalLinkZoneName,
    internalLinkURL: link
      ? link.indexOf("http") === -1
        ? window.location.origin + link
        : link
      : "n/a",
    clickInternalLinks: "true",
  }

  if (needJSON) {
    return eventInfo
  }

  return JSON.stringify(eventInfo)
}

export const addBookAppointmentAnalytics = params => {
  const {
    storeData,
    status,
    action,
    errorMessage,
    appointmentDate,
    appointmentTime,
  } = params
  let eventMsg = "n/a"
  if (status && status !== "success" && errorMessage?.length) {
    eventMsg = errorMessage.join("|").toLowerCase()
  }
  // adding analytics
  const eventInfo = getLegalPageAnalytics(storeData, "submit", null, true)
  eventInfo.eventStatus = status
  eventInfo.eventMsg = eventMsg
  eventInfo.appointmentDate = appointmentDate || "n/a"
  eventInfo.appointmentTime = appointmentTime || "n/a"

  const { adobeDataLayer: { getState } = {} } = window
  const page =
    action === "cmp:show"
      ? window.pageObj
      : (getState && getState("page")) || {}

  window.adobeDataLayer.push({
    event: action,
    page,
    eventInfo,
  })
  delete window.pageObj
}

export const getRecaptchaBookAppointmentAnalytics = internalLinkType => {
  return {
    pageTitle: "find a store:landing:modal:book appointment",
    componentName: "book an appointment",
    parentComponent1: "find a store",
    parentComponent2: "store detail page",
    internalLinkPosition: "find a store",
    internalLinkType: `${
      internalLinkType.indexOf("find a store") === -1 ? "find a store:" : ""
    }${internalLinkType}:google`,
  }
}
