import React, { useEffect, useState } from "react"

import useSearchi18n from "@/i18n/useSearchi18n"
import { downloadArticle, getShortenedUrl } from "@/utils/helper"
import { authorBaseUrl } from "@/constants"
import { getDownloadEventInfo } from "@/components/Default/Teaser/v1/analytics"

const DownloadUtility = ({
  pageType = "",
  componentPosition = "",
  dataLayerLinkType = "",
  downLoadLink = null,
  mainTitle = "",
}) => {
  const { download, downloadSuccess, downloadFailure } = useSearchi18n({})
  const [downloadUrl, setUrl] = useState("")

  useEffect(() => {
    if (downLoadLink) {
      updateUrl()
    }
  }, [downLoadLink])

  const updateUrl = async () => {
    const url = await getShortenedUrl(downLoadLink)
    setUrl(`${authorBaseUrl}${url}`)
  }

  const downloadHandle = event => {
    event?.preventDefault()
    event?.stopPropagation()
    const getDownloadUrl = downloadUrl ? downloadUrl : window.location.href
    downloadArticle(getDownloadUrl, event, "", downloadSuccess, downloadFailure)
  }

  return (
    <div className="download" aria-label="download" tabIndex="0">
      {downLoadLink ? (
        <a
          href={downLoadLink}
          className="download-trigger"
          data-gbh-data-layer={getDownloadEventInfo(
            downLoadLink,
            pageType,
            componentPosition,
            mainTitle,
            dataLayerLinkType
          )}
          data-gbh-clickable="true"
          onClick={downloadHandle}
        >
          &nbsp;
          {download}
        </a>
      ) : (
        <>
          &nbsp;
          <span
            className="download-trigger"
            data-gbh-data-layer={getDownloadEventInfo(
              downLoadLink,
              pageType,
              componentPosition,
              mainTitle,
              dataLayerLinkType
            )}
            data-gbh-clickable="true"
            onClick={downloadHandle}
          >
            {download}
          </span>
        </>
      )}
    </div>
  )
}

export default DownloadUtility
