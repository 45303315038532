import React, { useEffect, useState } from "react"
import { sanitizeInnerHtml, getShortenedUrl } from "@/utils/helper"
import Image from "@/components/ImageCluster/v1/Image"
import { COMPONENT_TYPES } from "@/constants"
import { recursiveDataFunction } from "@/utils"

const ImageCluster = props => {
  const {
    data: {
      header = "",
      description = "",
      ctaLink = "",
      ctaLabel = "",
      newTab = false,
      pageType = "",
      productCategory = "",
      productCollectionsName = "",
      productRoom = "",
      componentLinkType = "",
      tabName = "",
      dataHeader = "",
      componentPosition = "",
      appliedCssClassNames = "",
    },
    data = {},
  } = props

  const [imageData, setImageData] = useState([])
  const [link, setCtaLink] = useState("")
  const [newappliedCssClassNames, setappliedCssClassNames] = useState("")

  useEffect(() => {
    if (ctaLink) {
      getUrl()
    }
  }, [ctaLink])

  useEffect(() => {
    if (appliedCssClassNames) {
      setappliedCssClassNames(appliedCssClassNames)
    } else {
      setappliedCssClassNames(
        "image-right gbh-img-desc-indent font-style-1 bg-off-white"
      )
    }
  }, [appliedCssClassNames])

  useEffect(() => {
    if (data) {
      fetchDataLayer(data)
    }
  }, [data])

  const getUrl = async () => {
    setCtaLink(await getShortenedUrl(ctaLink))
  }

  const fetchDataLayer = data => {
    const result = recursiveDataFunction(data, COMPONENT_TYPES?.IMAGE)
    setImageData(result)
  }

  const getCustomDataLayer = () => {
    return JSON.stringify({
      productInfo: `{"productCategory":"${productCategory || "n/a"}`,
      productCollectionsName: `${productCollectionsName || "n/a"}`,
      productRoom: `${productRoom || "n/a"}`,
    })
  }

  const getDataLayer = () => {
    return JSON.stringify({
      eventName: `${pageType}:teaser:${ctaLabel.toLowerCase()}`,
      eventAction: `${pageType}:teaser:${ctaLabel.toLowerCase()}`,
      internalLinkName: `${ctaLabel.toLowerCase()}`,
      internalLinkType: `${pageType}:${
        componentLinkType || tabName || "rm" || dataHeader
      }`,
      internalLinkZoneName: `${pageType}:teaser`,
      clickInternalLinks: `${newTab ? "false" : "true"}`,
      eventType: "navigation",
      internalLinkURL: `${ctaLink}`,
      internalLinkPosition: `${pageType}:${
        componentPosition || "image cluster"
      }`,
    })
  }

  return (
    <div
      className={`imagecluster container responsivegrid ${newappliedCssClassNames}`}
    >
      <div className="gbh-img-cluster">
        <div className="gbh-img-container" id="mainContent" role="main">
          <div className="gbh-img-left-half">
            <div className="gbh-img-left-half__inner">
              <div className="gbh-img-left__full img-pos-rel">
                {imageData[0]?.imageComponentProperties?.src ? (
                  <Image imageData={imageData[0]} />
                ) : null}
              </div>
              <div className="gbh-img-left__half img-pos-rel">
                {imageData[1]?.imageComponentProperties?.src ? (
                  <Image imageData={imageData[1]} />
                ) : null}
              </div>
            </div>
            <div className="gbh-img-left__half img-pos-rel">
              {imageData[2]?.imageComponentProperties?.src ? (
                <Image imageData={imageData[2]} />
              ) : null}
            </div>
          </div>
          <div className="gbh-img-right-half">
            <div className="gbh-imgcluster-title">
              {sanitizeInnerHtml(header)}
            </div>
            <div className="gbh-img-right__descrip">
              {sanitizeInnerHtml(description)}
            </div>
            <a
              className="gbh__btn--link"
              href={link}
              target={newTab ? "_blank" : "_self"}
              data-gbh-clickable="true"
              rel="noreferrer"
              data-gbh-data-layer={getDataLayer()}
              data-gbh-data-layer-custom={getCustomDataLayer()}
            >
              {ctaLabel ? (
                <span aria-label={ctaLabel} data-gbh-clickable="true">
                  {ctaLabel}
                </span>
              ) : null}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageCluster
