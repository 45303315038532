import React from "react"
import Coach from "@/components/core/Coach/Coach"
import useViewport from "@/hooks/useViewport"
import ToolTip from "@/components/PartsViewer/v2/ToolTip"
import {
  IconCollapse,
  IconExpand,
  IconReset,
  IconZoomIn,
  IconZoomOut,
  ResetActive,
} from "@/components/PartsViewer/v2/icon"

const PartsControls = props => {
  const {
    zoomIn,
    zoomOut,
    expandHandler,
    fullscreen,
    scale,
    initScale,
    centerView,
    setMessage,
  } = props
  const device = useViewport()
  const isDesktop =
    device.isLargeScreen || device.isExtraLargeScreen || device.isMediumScreen
  const inMargin = (value, expected, margin) =>
    expected - margin < value && value < expected + margin

  return (
    <div
      className={`viewer-controls ${
        isDesktop ? "position-absolute" : "position-fixed mobile-controls"
      }`}
    >
      {fullscreen ? (
        <Coach text="Exit Full Screen" displayKey="bottom" hide={false} />
      ) : null}
      <button
        onClick={() => {
          expandHandler()
          setMessage(false)
        }}
        className="btn-expand"
        data-testid="parts-fullscreen"
      >
        {fullscreen ? <IconCollapse /> : <IconExpand />}
        {isDesktop && !fullscreen ? (
          <div className="tool-tip-custom">
            <span> {!fullscreen ? "Full screen" : ""}</span>
          </div>
        ) : null}
      </button>

      <ToolTip text="Reset" show={isDesktop ? true : false}>
        <button
          className={`${inMargin(scale, initScale, 0.05) ? "" : "acitve"}`}
          onClick={() => {
            centerView(initScale)
          }}
          data-testid="parts-center"
        >
          {inMargin(scale, initScale, 0.05) ? <IconReset /> : <ResetActive />}
        </button>
      </ToolTip>

      {isDesktop ? (
        <ToolTip text="Zoom" show={isDesktop ? true : false}>
          <div className="groups-buttons">
            <button
              onClick={() => {
                zoomIn()
              }}
              data-testid="parts-zoomin"
            >
              <IconZoomIn />
            </button>
            <button
              onClick={() => {
                zoomOut()
              }}
              data-testid="parts-zoomout"
            >
              <IconZoomOut />
            </button>
          </div>
        </ToolTip>
      ) : (
        ""
      )}
    </div>
  )
}

export default PartsControls
