/* eslint-disable no-undef */
import React, { useState, useEffect } from "react"
import { useTranslation } from "next-i18next"
import { useDispatch, useSelector } from "react-redux"

import { aemAxiosInstance } from "@/constants/api"
import { getConfig } from "@/constants/config"
import { getSiteWideModal } from "@/utils/helper"
import {
  getLegalPageLinks,
  selectGenericState,
} from "@/store/features/genericSlice"
import {
  addAnalyticsForRecaptchLinks,
  setLinkAnalytics,
} from "@/components/core/ReCaptcha/analytics"
import Styles from "@/components/core/ReCaptcha/Recaptcha.module.scss"

let promiseResolve
let promiseReject

export const validateRecaptcha = () => {
  return new Promise((resolve, reject) => {
    getConfig().then(CONFIG => {
      try {
        const { isDisableRecaptcha } = CONFIG.general
        if (isDisableRecaptcha) {
          return resolve({
            success: isDisableRecaptcha,
          })
        }
        const {
          captcha: { captchaSiteKeyV3, captchaThreshold },
          general: { siteName },
        } = CONFIG
        promiseResolve = resolve
        promiseReject = reject
        grecaptcha.ready(() => {
          grecaptcha.reset()
          grecaptcha
            .execute(captchaSiteKeyV3)
            .then(async token => {
              try {
                const { data } = await aemAxiosInstance({
                  url: `/bin/kohler/google/captchaAuth?type=V3&access_token=${token}&brandName=${siteName.toLowerCase()}`,
                  method: "GET",
                })
                if (data.score < captchaThreshold) {
                  grecaptcha.reset()
                  grecaptcha.execute()
                } else {
                  return resolve(data)
                }
              } catch (error) {
                reject(error)
              }
            })
            .catch(err => reject(err))
        })
      } catch (error) {
        reject(error)
      }
    })
  })
}

const ReCaptcha = props => {
  const { t } = useTranslation("common")
  const [links, setLinks] = useState({})
  const [state, setState] = useState({})
  const [i18n, seti18n] = useState({})
  const dispatch = useDispatch()
  const { legalPageLinks } = useSelector(selectGenericState)

  const getTranslations = t => {
    return {
      protectedByGoogle: t("kf.associatemembership.label.protectedByGoogle"),
      privacyPolicyGoogle: t(
        "kf.associatemembership.label.privacyPolicyGoogle"
      ),
      privacyPolicy: t("kf.contactus.label.privacyPolicy"),
      termsOfService: t("kf.contactus.label.termsOfService"),
      and: t("kf.contactus.message.and"),
      apply: t("kf.contactus.message.apply"),
    }
  }

  useEffect(() => {
    seti18n(getTranslations(t))
    getConfig().then(config => {
      const {
        captcha: { captchaSiteKeyV2, captchaSiteKeyV3 },
        general: { siteName },
      } = config
      setState({ captchaSiteKeyV2, captchaSiteKeyV3, siteName })
    })
  }, [])

  useEffect(() => {
    const { captchaSiteKeyV2, captchaSiteKeyV3, siteName } = state
    if (captchaSiteKeyV2 && captchaSiteKeyV3 && siteName) {
      loadRecaptcha()
      getLegalPages()
    }
  }, [state.captchaSiteKeyV2, state.captchaSiteKeyV3, state.siteName])

  useEffect(() => {
    if (legalPageLinks) {
      const { googlePrivacyPolicy, googleTermsOfService } = legalPageLinks
      setLinks({ googlePrivacyPolicy, googleTermsOfService })
    }
  }, [legalPageLinks])

  const getLegalPages = async () => {
    dispatch(getLegalPageLinks())
  }
  const loadRecaptcha = () => {
    const { captchaSiteKeyV2, captchaSiteKeyV3 } = state
    const scriptExist = document.getElementById("recaptcha-key")
    if (!scriptExist) {
      const script = document.createElement("script")
      script.src = `https://www.google.com/recaptcha/api.js?render=${captchaSiteKeyV3}`
      script.id = "recaptcha-key"
      script.onload = function onload() {
        grecaptcha.ready(() => {
          grecaptcha.render("recaptcha-container", {
            sitekey: captchaSiteKeyV2,
            callback: t => verifyToken(t),
          })
        })
      }
      document.body.appendChild(script)
    } else {
      grecaptcha.ready(() => {
        grecaptcha.render("recaptcha-container", {
          sitekey: captchaSiteKeyV2,
          callback: t => verifyToken(t),
        })
      })
    }
  }

  const verifyToken = async token => {
    const CONFIG = await getConfig()
    const { isDisableRecaptcha } = CONFIG.general
    if (isDisableRecaptcha) {
      promiseResolve({
        success: isDisableRecaptcha,
      })
    }
    try {
      const { data } = await aemAxiosInstance({
        url: `/bin/kohler/google/captchaAuth?type=V2&access_token=${token}&brandName=${state.siteName.toLowerCase()}`,
        method: "GET",
      })
      promiseResolve(data)
    } catch (error) {
      promiseReject(err)
    }
  }

  const { getAnalyticsData = {}, isPopupNeededBeforeRedirection = true } = props

  const { googlePrivacyPolicy = "n/a", googleTermsOfService = "n/a" } = links
  const { privacyPolicyGoogle = "", termsOfService = "" } = i18n

  const handleClickOfLinks = (e, text, url) => {
    const eventType = isPopupNeededBeforeRedirection
      ? "navigation"
      : "informative"
    if (props.addReviewRegistrationEventInfo) {
      const eventReviewInfo = props.addReviewRegistrationEventInfo(
        text,
        url,
        "false"
      )
      addAnalyticsForRecaptchLinks(eventReviewInfo)
    } else {
      setLinkAnalytics(url, text, getAnalyticsData, eventType)
    }
    if (isPopupNeededBeforeRedirection) getSiteWideModal(e, url)
    else window.open(url)
  }

  return (
    <>
      <div className={Styles.reCatichWrapper}>
        <div
          id="recaptcha-container"
          className="g-recaptcha"
          data-size="invisible"
        ></div>
        <div className="mt-20 mb-20 recaptcha-links">
          <span>{`${i18n.protectedByGoogle} `}</span>
          <a
            className="gpolicies"
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            onClick={e =>
              handleClickOfLinks(e, privacyPolicyGoogle, googlePrivacyPolicy)
            }
          >
            {privacyPolicyGoogle}
          </a>
          <span> {i18n.and} </span>
          <a
            className="gpolicies"
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            onClick={e =>
              handleClickOfLinks(e, termsOfService, googleTermsOfService)
            }
          >
            {termsOfService}
          </a>
          <span> {i18n.apply}</span>
        </div>
      </div>
    </>
  )
}

export default ReCaptcha
