import React from "react"
const CountryUtilNavTabLink = props => {
  const { activeTab, countryArea, onclickVal, countryAreaValue } = props

  return (
    <li
      onClick={() => {
        onclickVal(countryArea)
      }}
      data-tab={countryArea}
      role="tab"
      tabIndex="0"
      data-region={`#${countryArea}`}
      aria-label={countryArea}
      className={`gbh-tabs__tab ${
        activeTab === countryArea ? "gbh-tabs__tab--active" : ""
      }`}
      data-testid="countrySelectorSection_tabLink"
    >
      {countryAreaValue}
    </li>
  )
}

export default CountryUtilNavTabLink
