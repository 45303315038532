import { useRouter } from "next/router"
import { FRAGMENT_TYPES } from "@/constants"
import { useEffect } from "react"

const ContainerComponent = ({ children, extraProps, item, count }) => {
  const router = useRouter()
  useEffect(() => {
    const timeOut = setTimeout(() => {
      const hashValue = router?.asPath?.split("#")[1] ?? ""
      if (hashValue) {
        const element = document.querySelector(`[id='${hashValue}']`) ?? {}
        const offsetTop = element?.parentNode?.offsetTop ?? 0
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        })
      }
    }, 1000)
    return () => {
      clearTimeout(timeOut)
    }
  }, [])
  return (
    <div
      className={`cmp-container${
        extraProps?.fragment === FRAGMENT_TYPES.HEADER &&
        extraProps?.hasGlobalNavigation &&
        count === 0
          ? " desk-header-height"
          : ""
      }`}
      id={item?.containerId}
    >
      {children}
    </div>
  )
}

export default ContainerComponent
