import { CONTACT_US_PAGE_URL_US } from "@/constants/index"

const ErrorFallbackUI = () => {
  return (
    <div className="fallback-wrapper d-flex flex-column w-100 h-100">
      <div className="error-message">
        <div className="text-section">
          <div className="line-first">Sorry, this diagram cannot be found</div>
          <div className="questions">
            If you have any questions please&nbsp;
            <a href={CONTACT_US_PAGE_URL_US} target="_blank" rel="noreferrer">
              contact us
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorFallbackUI
