const Arrow = props => {
  const {
    className = "",
    style = "",
    onClick = () => {},
    label = "",
    topPosition = 0,
    addAnalyticsForArrows = () => {},
  } = props
  const clickHandler = (e, label) => {
    onClick && onClick(e)
    addAnalyticsForArrows(label)
  }
  return (
    <button
      className={className}
      type="button"
      aria-label={label}
      style={{
        ...style,
        top: topPosition ? topPosition : "",
      }}
      onClick={e => clickHandler(e, label)}
    />
  )
}

export default Arrow
