import React from "react"
import { useSelector } from "react-redux"

import BrandSwitcher from "@/components/Shared/BrandSwitcher"
import UtilityNavHeader from "@/components/Shared/UtilityNavHeader"
import { selectGenericState } from "@/store/features/genericSlice"
import useIsSsr from "@/hooks/useIsSsr"
import BrandHeaderFiller from "@/components/Shared/BrandHeaderFiller"
import CheckoutUtilityNavHeader from "./CheckoutUtilityNavheader"

const BrandHeader = props => {
  const { data, eventHandler } = props
  const { datalayerPageName } = useSelector(selectGenericState)
  const isSsr = useIsSsr()

  return (
    <>
      <>
        {data[":itemsOrder"].map((key, index) => {
          const item = data[":items"][key]
          if (key === "brand_switcher" || key === "brand_switcher_copy") {
            return (
              <BrandSwitcher
                data={item}
                key={key}
                datalayerPageName={datalayerPageName}
              />
            )
          }
          if (key === "utilitynavheader" || key === "utilitynavheader_v2") {
            return (
              <React.Fragment key={key}>
                {isSsr ? (
                  <BrandHeaderFiller />
                ) : key === "utilitynavheader_v2" ? (
                  <CheckoutUtilityNavHeader
                    data={item}
                    eventHandler={eventHandler}
                  />
                ) : (
                  <UtilityNavHeader data={item} eventHandler={eventHandler} />
                )}
              </React.Fragment>
            )
          }
          return null
        })}
      </>
    </>
  )
}

export default BrandHeader
