import React from "react"

const ImagePlaceHolder = ({ assetID = "" }) => {
  if (!assetID) return null
  const imgPlaceholder =
    "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
  return (
    <img
      src={imgPlaceholder}
      data-src={imgPlaceholder}
      data-mode="smartcrop"
      alt={"Image Placeholder"}
      className={"fluidimage cmp-image__image image-placeholder"}
    />
  )
}

export default ImagePlaceHolder
