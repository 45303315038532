import { useTranslation } from "next-i18next"

const useStoreAppointmenti18n = () => {
  const { t } = useTranslation()
  const i18n = {
    requestAnAppointment: t("kf.reqAppointment.label.requestAnAppointment"),
    reqAppointmentDesc: t("kf.reqAppointment.label.reqAppointmentDesc"),
    location: t("kf.reqAppointment.label.location"),
    stepOne: t("kf.reqAppointment.label.stepOne"),
    selectData: t("kf.reqAppointment.label.selectData"),
    availableTimes: t("kf.reqAppointment.label.availableTimes"),
    stepTwo: t("kf.reqAppointment.label.stepTwo"),
    fillYourContact: t("kf.reqAppointment.label.fillYourContact"),
    requiredFields: t("kf.reqAppointment.label.requiredFields"),
    firstName: t("kf.reqAppointment.label.firstName"),
    lastName: t("kf.reqAppointment.label.lastName"),
    email: t("kf.reqAppointment.label.email"),
    phone: t("kf.reqAppointment.label.phone"),
    fnameError: t("kf.reqAppointment.label.fnameError"),
    lnameError: t("kf.reqAppointment.label.lnameError"),
    noEmailError: t("kf.reqAppointment.label.noEmailError"),
    validEmail: t("kf.reqAppointment.label.validEmail"),
    validPhone: t("kf.reqAppointment.label.validPhone"),
    dateError: t("kf.reqAppointment.label.dateError"),
    timeError: t("kf.reqAppointment.label.timeError"),
    submit: t("kf.reqAppointment.label.submit"),
    privacyPolicy: t("kf.reqAppointment.label.privacyPolicy"),
    subjectAccess: t("kf.reqAppointment.label.subjectAccess"),
    termsOfService: t("kf.reqAppointment.label.termsOfService"),
    leftArrowAlt: t("kf.reqAppointment.label.leftArrowAlt"),
    rightArrowAlt: t("kf.reqAppointment.label.rightArrowAlt"),
    thankYouMsg: t("kf.reqAppointment.label.thankYouMsg"),
    thankYouDesc: t("kf.reqAppointment.label.thankYouDesc"),
    dateAndTime: t("kf.reqAppointment.label.dateAndTime"),
    viewStore: t("kf.reqAppointment.label.viewStore"),
    contactInfo: t("kf.reqAppointment.label.contactInfo"),
    done: t("kf.reqAppointment.label.done"),
    noImage: t("kf.reqAppointment.label.noImage"),
    date: t("kf.reqAppointment.label.date"),
    datePlaceholder: t("kf.reqAppointment.label.datePlaceholder"),
    at: t("kf.reqAppointment.label.at"),
    sunday: t("kf.reqAppointment.label.sunday"),
    monday: t("kf.reqAppointment.label.monday"),
    tuesday: t("kf.reqAppointment.label.tuesday"),
    wednesday: t("kf.reqAppointment.label.wednesday"),
    thursday: t("kf.reqAppointment.label.thursday"),
    friday: t("kf.reqAppointment.label.friday"),
    saturday: t("kf.reqAppointment.label.saturday"),
    january: t("kf.reqAppointment.label.january"),
    february: t("kf.reqAppointment.label.february"),
    march: t("kf.reqAppointment.label.march"),
    april: t("kf.reqAppointment.label.april"),
    may: t("kf.reqAppointment.label.may"),
    june: t("kf.reqAppointment.label.june"),
    july: t("kf.reqAppointment.label.july"),
    august: t("kf.reqAppointment.label.august"),
    september: t("kf.reqAppointment.label.september"),
    october: t("kf.reqAppointment.label.october"),
    november: t("kf.reqAppointment.label.november"),
    december: t("kf.reqAppointment.label.december"),
    weekDays: t("kf.reqAppointment.label.weekDays"),
    weekEnds: t("kf.reqAppointment.label.weekEnds"),
    wholeWeek: t("kf.reqAppointment.label.wholeWeek"),
    sat: t("kf.reqAppointment.label.sat"),
    sun: t("kf.reqAppointment.label.sun"),
    ariaLabel: {
      prevMonth: t("kf.reqAppointment.aria.label.prevMonth"),
      nextMonth: t("kf.reqAppointment.aria.label.nextMonth"),
      changeYear: t("kf.reqAppointment.aria.label.changeYear"),
      selectYear: t("kf.reqAppointment.aria.label.selectYear"),
      prevSlots: t("kf.reqAppointment.aria.label.prevSlots"),
      nextSlots: t("kf.reqAppointment.aria.label.nextSlots"),
      sunday: t("kf.reqAppointment.aria.label.sunday"),
      monday: t("kf.reqAppointment.aria.label.monday"),
      tuesday: t("kf.reqAppointment.aria.label.tuesday"),
      wednesday: t("kf.reqAppointment.aria.label.wednesday"),
      thursday: t("kf.reqAppointment.aria.label.thursday"),
      friday: t("kf.reqAppointment.aria.label.friday"),
      saturday: t("kf.reqAppointment.aria.label.saturday"),
      days: t("kf.reqAppointment.label.days"),
      selected: t("kf.reqAppointment.label.selected"),
      notSelected: t("kf.reqAppointment.label.notSelected"),
    },
    genError: t("kf.error.message.genericError"),
  }

  return i18n
}

export default useStoreAppointmenti18n
