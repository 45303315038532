import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import useSearchi18n from "@/i18n/useSearchi18n"
import useIsSsr from "@/hooks/useIsSsr"
import ProductSocialShare from "@/components/Shared/ProductSocialShare/v1/ProductSocialShare"

import { selectGenericState } from "@/store/features/genericSlice"

import {
  addDatalayerShare,
  getEventInfo,
} from "@/components/Default/Teaser/v1/analytics"

const ShareUtility = ({
  pageType = "",
  componentPosition = "",
  dataLayerLinkType = "",
  containerId: zone = "",
}) => {
  const [showShare, setShowShare] = useState(false)
  const [pageUrl, setPageUrl] = useState("")
  const { pageIsInteractive } = useSelector(selectGenericState)

  const { share } = useSearchi18n({})
  const isSsr = useIsSsr()

  useEffect(() => {
    if (pageIsInteractive) setPageUrl(window.location.href)
  }, [pageIsInteractive])

  const handleShare = e => {
    e.stopPropagation()
    e.preventDefault()
    setShowShare(!showShare)

    const { adobeDataLayer: { getState } = {} } = window
    const {
      pageInfo: { contentType },
    } = (getState && getState("page")) || {}

    let eventInfo = getEventInfo(
      pageType,
      componentPosition,
      "",
      "share",
      dataLayerLinkType,
      "informative"
    )
    const isPdp = contentType === "pdp"

    if (isPdp) {
      const { internalLinkType, internalLinkZoneName, internalLinkURL } =
        getEventInfo(
          pageType,
          componentPosition,
          "",
          "share",
          dataLayerLinkType,
          "informative",
          zone,
          true
        )
      const eventInfoObj = {
        clickInternalLinks: "true",
        eventAction: "pdp:product details:zone:share",
        eventName: "pdp:product details:zone:share",
        eventType: "navigation",
        eventMsg: "n/a",
        eventStatus: "n/a",
        internalLinkName: "share",
        internalLinkPosition: "pdp",
        internalLinkType: `pdp:${internalLinkType.split(":")[1] || ""}`,
        internalLinkZoneName: `pdp:${internalLinkZoneName.split(":")[1] || ""}`,
        internalLinkURL: internalLinkURL || "n/a",
      }
      eventInfo = JSON.stringify(eventInfoObj)
    }
    addDatalayerShare(eventInfo, isPdp)
  }

  return !isSsr ? (
    <>
      <div
        className="share share-trigger"
        aria-label="share"
        tabIndex="0"
        onClick={handleShare}
      >
        &nbsp;
        <span>{share}</span>
      </div>
      <div className="share-cmp">
        <div>
          <ProductSocialShare
            showModal={showShare}
            url={pageUrl}
            onClose={() => setShowShare(false)}
            disableInstagram
            analyticData={getEventInfo(
              pageType,
              componentPosition,
              "",
              "share",
              dataLayerLinkType,
              "informative",
              zone,
              true
            )}
          />
        </div>
      </div>
    </>
  ) : null
}

export default ShareUtility
