import { useState, useEffect } from "react"
import _debounce from "lodash/debounce"
import useIsSsr from "@/hooks/useIsSsr"

/**
 *
 * @return {String}
 */
const useScreenOrientation = () => {
  const isSsr = useIsSsr()
  const [orientation, setOrientation] = useState("")

  useEffect(() => {
    const getOrientation = () => window.screen?.orientation?.type
    setOrientation(getOrientation())

    const updateOrientation = _debounce(() => {
      setOrientation(getOrientation())
    }, 300)
    if (!isSsr) {
      window.addEventListener("resize", updateOrientation)
    }
    return () => {
      window.removeEventListener("resize", updateOrientation)
    }
  }, [isSsr])

  return orientation
}

export default useScreenOrientation
