import React from "react"

const Title = ({ data = {} }) => {
  const { appliedCssClassNames = "", text = "" } = data
  return (
    <div
      className={`title ${appliedCssClassNames ? appliedCssClassNames : ""}`}
    >
      <div className="cmp-title">
        <h1 className="cmp-title__text">{text} </h1>
      </div>
    </div>
  )
}

export default Title
