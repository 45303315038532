import { useState, useEffect } from "react"
import _debounce from "lodash/debounce"
import useIsSsr from "@/hooks/useIsSsr"

/**
 *
 * @return {{width, height}}
 */
const useWindowDimensions = () => {
  const isSsr = useIsSsr()
  const [windowDimensions, setWindowDimensions] = useState({
    winWidth: 0,
    winHeight: 0,
  })

  useEffect(() => {
    if (isSsr) {
      return
    }
    const { innerWidth: width, innerHeight: height } = window
    /**
     * Set window dimensions on resize
     */
    const handleResize = _debounce(() => {
      setWindowDimensions({ winWidth: width, winHeight: height })
    }, 300)
    if (!isSsr) {
      setWindowDimensions({ winWidth: width, winHeight: height })
      window.addEventListener("resize", handleResize)
    }
    return () => window.removeEventListener("resize", handleResize)
  }, [isSsr])

  return windowDimensions
}

export default useWindowDimensions
