import { getConfig } from "@/constants/config"
import { addToCart, setCart, setCartCount } from "@/store/features/cartSlice"
import { showPreviewCart, showToast } from "@/store/features/genericSlice"
import { store } from "@/store/index"
import { getQuantityCount } from "@/utils/cart"

export const productCardAddToCart = async (items, showPreview, message, cb) => {
  const CONFIG = await getConfig()
  const salesChannels = CONFIG?.salesChannelConfig?.map ?? {}
  const actions = []
  let userZipCode
  items.forEach(product => {
    const fields = { salesChannel: salesChannels[product.brand] ?? "KOUS" }
    if (product.backorder) fields.isBackOrderable = true
    actions.push({
      action: product.isBundle ? "addbundle" : "addLineItem",
      sku: product.sku,
      quantity: product.quantity,
      custom: product.additionalInfo
        ? {
            type: { key: "lineItemShipping" },
            fields: {
              salesChannel: salesChannels[product.brand],
              roomInfo: product.additionalInfo.roomInfo,
              customQuantity: product.additionalInfo.customQuantity,
              overage: product.additionalInfo.overage,
            },
          }
        : {
            type: { key: "lineItemShipping" },
            fields: fields,
          },
    })
    userZipCode = product?.userZipCode
  })

  const body = {
    actions: actions,
  }
  if (userZipCode) {
    body.servicesku = true
    body.zipCode = userZipCode
  }
  store
    .dispatch(addToCart(body))
    .unwrap()
    .then(res => {
      if (res) {
        store.dispatch(setCart(res))
        const quantityLineItems = res?.lineItems
        const payload = getQuantityCount(quantityLineItems)
        store.dispatch(setCartCount(payload))
        cb && cb({ status: 200, message: "success" })
        if (message) {
          store.dispatch(showToast(message))
        }
        if (showPreview) store.dispatch(showPreviewCart())
      }
    })
}
