import { aemAxiosInstance, apim } from "@/constants/api"
import qs from "qs"
import { getConfig } from "@/constants/config"
import { getSessionId, getSessionIdObjWithEpoc } from "@/utils/ApiUtil"
import { GLOBAL_CONFIGURATION_ENDPOINT, getStockEndPoint } from "@/constants"
import { ADOBE_TARGET_PRODUCT_RECOMMENDETION_ENDPOINT } from "@/constants/routes"

const getListOfParamsToFetch = persona => {
  const fl = [
    "id",
    "slug_s",
    "productName_s",
    "masterSKU_s",
    "sku_s",
    "Product_Category",
    "ProductBrandName_s",
    "productImages.url_s",
    "productImages.url_ss",
    "SKUColorSwatchFilename_s",
    "SKUColorSwatchFilename_ss",
    "ProductDescriptionProductShort_s",
    `priceStartingAt.${persona}_d`,
    `discountedPriceStartingAt.${persona}_d`,
    `priceList.${persona}.saleOffer_s`,
    "Color.SKU.Details_ss",
    "Color.SKU.Details_s",
    "ProductWebFeatures_ss",
    "ProductWebFeatures_s",
    "ProductIsExclusive_s",
    "RegionReleaseforShipment_dt",
    "title_s",
    "ProductNarrativeDescription_s",
    `priceList.${persona}.finalPrice_d`,
    "CustomerFacingBrand_s",
    "RegionBrandName_s",
    `priceList.${persona}.discountedPrice_d`,
    `priceList.${persona}.price_d`,
    `CrossSellRecommendationSKUList_ss`,
    "productImages.labelWithUrl_ss",
  ]

  return fl.join(",")
}

const getProductDataFromSkuIds = async (
  persona = "GST",
  skus = [],
  lwAppName = ""
) => {
  if (!skus.length) return
  const fl = getListOfParamsToFetch(persona)
  const fq = `sku_s:(${skus.map(ele => `"${ele}"`).join(" ")})`

  const config = await getConfig()
  const language = config?.internationalization?.language ?? "en"
  const brand = lwAppName?.toLowerCase()
  return apim.get(`/search/plp`, {
    params: {
      fl,
      q: "*:*",
      fq: [fq, `language_s:("${language}")`],
      collections: brand,
      profilename: `profile_${brand}_Full_Catalog`,
      rows: skus?.length ?? 10,
      sessionId: getSessionIdObjWithEpoc(),
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: "repeat", encode: false })
    },
  })
}

const getZipCodes = async jsonFileUrl =>
  await aemAxiosInstance({
    url: GLOBAL_CONFIGURATION_ENDPOINT,
    params: {
      path: jsonFileUrl,
    },
  })

export const getListOfRequiredSkus = async (
  persona = "GST",
  slug = "",
  sku = "",
  lwAppName = ""
) => {
  try {
    const fl = getListOfParamsToFetch(persona)
    const config = await getConfig()
    const language = config?.internationalization?.language ?? "en"
    const fq = `sku_s:("${sku}")`

    return apim.post(`search/pdp/${slug}`, {
      fl,
      q: "*:*",
      fq: `${fq} AND language_s:("${language}")`,
      collections: lwAppName,
      profilename: `profile_${lwAppName}_General`,
      sessionId: getSessionIdObjWithEpoc(),
    })
  } catch (err) {
    return {}
  }
}

export const getProductInventory = (skus = []) => {
  return apim.get(`${getStockEndPoint}/?sku=${skus}`)
}

export const getListOfRequiredAdobeTargetSkus = async payload => {
  try {
    const res = await apim.post(
      `${ADOBE_TARGET_PRODUCT_RECOMMENDETION_ENDPOINT}?sessionId=${getSessionId()}`,
      payload
    )
    return res?.data?.execute?.mboxes?.[0] ?? []
  } catch (err) {
    return []
  }
}

export { getProductDataFromSkuIds, getZipCodes }
