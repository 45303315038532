import React from "react"
import Scroll from "react-scroll"

import { COMPONENT_TYPES, staticClasses } from "@/constants"
import {
  ConditionalWrapper,
  isAemForm,
  isBreadcrumb,
  isCarousel,
  isContainer,
  isImageV3,
  isSlick,
  isTab,
  isTeaser,
  isProductAccordion,
  isSpacer,
  isVideo,
  isImage,
  isInPageSearch,
  isText,
  isShopTheRoomCard,
  isProductcardV3,
} from "@/utils"

import AemGrid from "@/components/AemGrid"
import ContainerComponent from "@/components/Shared/ContainerComponent"

const AemGridColumn = props => {
  const { data, itemKey, count, eventHandler, containerId, ...extraProps } =
    props
  const Element = Scroll.Element
  const scrollElementCondition = (item, key) =>
    data?.columnClassNames &&
    data?.columnClassNames[key] &&
    !isTeaser(item) &&
    !isBreadcrumb(item) &&
    !isCarousel(item) &&
    !isAemForm(item) &&
    !isProductAccordion(item) &&
    !isVideo(item) &&
    !isImage(item) &&
    !isInPageSearch(item) &&
    !isText(item) &&
    !isShopTheRoomCard(item) &&
    !isProductcardV3(item)
  return (
    <>
      {data[":itemsOrder"] &&
        data[":itemsOrder"].map((key, index) => {
          const item = data[":items"][key]
          return (
            <React.Fragment key={item.id ? item.id : index}>
              <ConditionalWrapper
                condition={scrollElementCondition(item, key)}
                wrapper={children => (
                  <Element
                    className={`${isImageV3(item) ? "image-v3 image" : ""}${
                      isContainer(item) ? staticClasses.CONTAINER : ""
                    }${
                      item?.appliedCssClassNames &&
                      (isContainer(item) || isTab(item))
                        ? ` ${item?.appliedCssClassNames}`
                        : ""
                    }${
                      item?.appliedCssClassNamesCustom
                        ? ` ${item?.appliedCssClassNamesCustom}`
                        : ""
                    }${
                      isSlick(item)
                        ? `carousel-slick carousel ${item["carouselComponentProperties"]?.appliedCssClassNames} `
                        : ""
                    }${
                      data?.columnClassNames[key]
                        ? !isSlick(item)
                          ? ` ${data?.columnClassNames[key]} `
                          : ""
                        : ""
                    }${isSpacer(item) ? `spacer separator` : ""} ${
                      isTab(item) ? "tabs" : ""
                    }`}
                  >
                    {children}
                  </Element>
                )}
              >
                <ConditionalWrapper
                  condition={
                    isContainer(item) && key !== COMPONENT_TYPES.ROOT_CONTAINER
                  }
                  wrapper={children => (
                    <ContainerComponent
                      item={item}
                      extraProps={extraProps}
                      count={count}
                    >
                      {children}
                    </ContainerComponent>
                  )}
                >
                  <AemGrid
                    fullData={data[":items"] && data[":items"]}
                    data={item}
                    itemKey={key ? key : itemKey}
                    count={count + 1}
                    containerId={item?.containerId || containerId}
                    eventHandler={eventHandler}
                    {...extraProps}
                  />
                </ConditionalWrapper>
              </ConditionalWrapper>
            </React.Fragment>
          )
        })}
    </>
  )
}

export default AemGridColumn
