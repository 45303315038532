import React from "react"
import DOMPurify from "isomorphic-dompurify"

import styles from "@/components/HelpSupport/v1/index.module.scss"
import { removeAndCleanLink } from "@/utils/helper"
const HelpSupport = ({ data }) => {
  const {
    helpTitle = "",
    helpDescription = "",
    chatTitle = "",
    chatDescription = "",
    chatLink = "",
    chatLinkRef = false,
    contactTitle = "",
    contactDescription = "",
    prodSupportTitle = "",
    prodSupportDescription = "",
    prodSupportItems = [],
    chatText = "",
    contactItems = [],
    pageName = "",
    enableChat = false,
  } = data
  return (
    <div className={styles.helpSupportWrapper}>
      <div className={`help-support `}>
        <div className="gbh-container">
          <div className="help-support-left">
            <div
              className="help-support-left-title"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(helpTitle),
              }}
            />
            <div
              className="help-support-left-description"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(helpDescription),
              }}
            />
          </div>
          <div className="help-support-right">
            {chatTitle && (
              <div className="chat">
                <div
                  className="chat-title"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(chatTitle),
                  }}
                />
                <div
                  className="chat-description"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(chatDescription),
                  }}
                />
                {chatLink && enableChat && (
                  <a
                    href={removeAndCleanLink(chatLink)}
                    target={chatLinkRef ? "_blank" : "_self"}
                    rel="noopener noreferrer"
                    data-gbh-data-layer={`{"clickInternalLinks":"${!chatLinkRef}","internalLinkPosition":"help support","internalLinkZoneName":"${pageName}:help and support","internalLinkURL":"${removeAndCleanLink(
                      chatLink
                    )}","internalLinkName":"${chatTitle}","eventName":"${pageName}:help and support:${chatTitle}","internalLinkType":"${pageName}:navigation","eventType":"help support","eventAction":"${pageName}:help and support:${chatTitle}"}`}
                    className="gbh-data-layer"
                  >
                    {chatText}
                  </a>
                )}
              </div>
            )}

            {contactTitle && (
              <div className="chat">
                <div
                  className="chat-title"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(contactTitle),
                  }}
                />
                <div
                  className="chat-description"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(contactDescription),
                  }}
                />
                {contactItems && (
                  <div className="contact-number">
                    <ul>
                      {contactItems.map((item, index) => (
                        <li key={`${item.phoneNumber}+${index}`}>
                          <a
                            href={`tel:${item.phoneNumber}`}
                            data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"help support","internalLinkZoneName":"${pageName}:help and support","internalLinkURL":"tel:${item.phoneNumber}","internalLinkName":"${item.phoneNumber}","eventName":"${pageName}:help and support:${item.phoneNumber}","internalLinkType":"${pageName}:call our team","eventType":"help support","eventAction":"${pageName}:help and support:${item.phoneNumber}"}`}
                            className="gbh-data-layer"
                          >
                            {item.phoneNumber}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}

            <div className="chat">
              {prodSupportTitle && (
                <div
                  className="chat-title"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(prodSupportTitle),
                  }}
                />
              )}
              {prodSupportDescription && (
                <div
                  className="chat-description"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      removeAndCleanLink(prodSupportDescription)
                    ),
                  }}
                />
              )}
              {prodSupportItems && (
                <div className="support-links">
                  <ul>
                    {prodSupportItems.map(item => (
                      <li
                        key={item.labelProdSup}
                        className={item.isDisplayButton ? "chat-button" : ""}
                      >
                        <a
                          href={removeAndCleanLink(item?.linkProdSup)}
                          target={item.isNewTabProd ? "_blank" : "_self"}
                          rel="noopener noreferrer"
                          data-gbh-data-layer={`{"clickInternalLinks": "${
                            item.isNewTabProd ? "false" : "true"
                          }", "internalLinkPosition": "help support", "internalLinkZoneName": "${pageName}:help and support", "internalLinkURL": "${removeAndCleanLink(
                            item?.linkProdSup
                          )}", "internalLinkName": "${
                            item.labelProdSup
                          }", "eventName": "${pageName}:help and support:${
                            item.labelProdSup
                          }", "internalLinkType": "${pageName}:product support", "eventType": "help support", "eventAction": "${pageName}:help and support:${
                            item.labelProdSup
                          }"}`}
                          className="gbh-data-layer"
                        >
                          {item.labelProdSup}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HelpSupport
