import { getUserPersona } from "@/utils/helper.js"

const isObjectEmpty = objectName => {
  return Object.keys(objectName).length === 0
}

export const getPersonaPriceDetails = priceDtl => {
  const persona = getUserPersona()
  const result = []
  const filerPrice = priceDtl?.prices?.filter(
    elemet => !isObjectEmpty(elemet?.prices)
  )
  filerPrice?.map(price => {
    if (Object.keys(price?.prices) != 0) {
      result.push({
        currencySign: price?.currencySign ? price?.currencySign : $,
        sku: price?.sku,
        discountedPrice: Object.values(price?.discountPrices).length
          ? (price?.discountPrices[persona]?.centAmount / 100).toFixed(2)
          : null,
        price: price?.prices[persona]
          ? (price?.prices[persona]?.centAmount / 100).toFixed(2)
          : null,
        pricelist: price?.prices["ANY"]
          ? (price?.prices["ANY"]?.centAmount / 100).toFixed(2)
          : null,
      })
    }
  })
  return result
}
