const addReviewRegistrationAnalytics = (
  action,
  status,
  productData,
  message
) => {
  const eventInfo = {
    eventAction:
      "my account:register online kohler products:review and register:submit registration",
    eventName:
      "my account:register online kohler products:review and register:submit registration",
    eventType: "navigation",
    eventMsg: message || "n/a",
    eventStatus: status || "n/a",
    internalLinkName: "submit registration",
    internalLinkPosition: "register products",
    internalLinkType: "my account:navigation",
    internalLinkZoneName:
      "my account:register online kohler products:review and register",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }

  const ProductInfo = []
  productData?.map(data => {
    const obj = {
      productBrand: data.brandName || "n/a",
      productCategory: data.category || "n/a",
      productColor: data.colorValue || "n/a",
      productID: data.customerFacingSKU || "n/a",
      description: data.descriptio || "n/a",
      imgSrc: data.imgSrc || "n/a",

      orderNo: data.orderNo || "n/a",
      productName: data.productName || "n/a",
      purchaseDate: data.purchased || "n/a",
      skuid: data.skuid || "n/a",
      slug: data.slug || "n/a",

      frequency: "n/a",
      isRecommended: "n/a",
      isSendNow: "n/a",
      isSubscription: "n/a",
      productCoupondiscount: "n/a",
      productPartnerBuyNow: "n/a",
      pdpType: "regular finished goods",
      productFindingMethod: "n/a",
    }
    ProductInfo.push(obj)
  })

  const { adobeDataLayer: { getState } = {} } = window
  const page = action === "cmp:show" ? window.pageObj : getState?.("page") || {}
  window.adobeDataLayer.push({
    event: action,
    page,
    eventInfo,
    productInfo: ProductInfo,
  })
}

const getReviewProductsBackToMyProductsAnalytics = (
  isSuccess,
  myProductsPath
) => {
  const pageName = isSuccess
    ? "thank you for registering the products"
    : "top navigation"
  return JSON.stringify({
    eventAction: `my account:register online kohler products:${pageName}:back to my products`,
    eventName: `my account:register online kohler products:${pageName}:back to my products`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "back to my products",
    internalLinkPosition: "register products",
    internalLinkType: "my account:navigation",
    internalLinkZoneName: `my account:register online kohler products:${pageName}`,
    internalLinkURL:
      (myProductsPath.indexOf("http") === -1 ? window.location.origin : "") +
      myProductsPath,
    clickInternalLinks: "true",
  })
}

const personalInfoEdit = JSON.stringify({
  eventAction:
    "my account:register online kohler products:personal information:edit",
  eventName:
    "my account:register online kohler products:personal information:edit",
  eventType: "navigation",
  eventMsg: "n/a",
  eventStatus: "n/a",
  internalLinkName: "edit",
  internalLinkPosition: "register products",
  internalLinkType: "my account:navigation",
  internalLinkZoneName:
    "my account:register online kohler products:personal information",
  internalLinkURL: "n/a",
  clickInternalLinks: "true",
})

const selectProdcutEdit = JSON.stringify({
  eventAction:
    "my account:register online kohler products:select the products you'd like to register:edit",
  eventName:
    "my account:register online kohler products:select the products you'd like to register:edit",
  eventType: "navigation",
  eventMsg: "n/a",
  eventStatus: "n/a",
  internalLinkName: "edit",
  internalLinkPosition: "register products",
  internalLinkType: "my account:navigation",
  internalLinkZoneName:
    "my account:register online kohler products:select the products you'd like to register",
  internalLinkURL: "n/a",
  clickInternalLinks: "true",
})

const addRegisterOnlineProductsAnalytics = (
  action,
  status,
  productData,
  message,
  eventText = "personal information"
) => {
  const eventInfo = {
    eventAction: `my account:register online kohler products:${eventText}:next step`,
    eventName: `my account:register online kohler products:${eventText}:next step`,
    eventType: "navigation",
    eventMsg: message || "n/a",
    eventStatus: status,
    internalLinkName: "next step",
    internalLinkPosition: "register products",
    internalLinkType: "my account:navigation",
    internalLinkZoneName: `my account:register online kohler products:${eventText}`,
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }
  const ProductInfo = []
  productData?.forEach(data => {
    const obj = {
      productBrand: data.brandName,
      productCategory: data.category || "n/a",
      productColor: data.colorValue || "n/a",
      productID: data.customerFacingSKU || "n/a",
      description: data.descriptio || "n/a",
      imgSrc: data.imgSrc || "n/a",

      orderNo: data.orderNo || "n/a",
      productName: data.productName || "n/a",
      purchaseDate: data.purchased || "n/a",
      skuid: data.skuid || "n/a",
      slug: data.slug || "n/a",

      frequency: "n/a",
      isRecommended: "n/a",
      isSendNow: "n/a",
      isSubscription: "n/a",
      productCoupondiscount: "n/a",
      productPartnerBuyNow: "n/a",
      pdpType: "regular finished goods",
      productFindingMethod: "n/a",
    }
    ProductInfo.push(obj)
  })

  const { adobeDataLayer: { getState } = {} } = window
  const page = action === "cmp:show" ? window.pageObj : getState?.("page") || {}
  window.adobeDataLayer.push({
    event: action,
    page,
    eventInfo,
    productInfo: ProductInfo,
  })
}

const getCheckboxAnalyticsdata = isChecked => {
  const isCheckedOrUnchecked = isChecked ? "unchecked" : "checked"
  return JSON.stringify({
    eventAction: `my account:register online kohler products:select the products you'd like to register:register:${isCheckedOrUnchecked}`,
    eventName: `my account:register online kohler products:select the products you'd like to register:register:${isCheckedOrUnchecked}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: `register:${isCheckedOrUnchecked}`,
    internalLinkPosition: "register products",
    internalLinkType: "my account:checkbox",
    internalLinkZoneName:
      "my account:register online kohler products:select the products you'd like to register",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  })
}

const getSelectAllAnalytics = (productData, data) => {
  const isSelectOrUnselectAll =
    productData.length === data?.length ? "unselect all" : "select all"
  return JSON.stringify({
    eventAction: `my account:register online kohler products:select the products you'd like to register:${isSelectOrUnselectAll}`,
    eventName: `my account:register online kohler products:select the products you'd like to register:${isSelectOrUnselectAll}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: `${isSelectOrUnselectAll}`,
    internalLinkPosition: "register products",
    internalLinkType: "my account:navigation",
    internalLinkZoneName:
      "my account:register online kohler products:select the products you'd like to register",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  })
}

const getEventInfo = (linkUrl, isForthankYOu) => {
  const type = isForthankYOu
    ? "thank you for registering the products"
    : "product registration summary"
  return JSON.stringify({
    clickInternalLinks: "true",
    eventAction: `my account:register online kohler products:${type}:review products`,
    eventName: `my account:register online kohler products:${type}:review products`,
    eventMsg: "n/a",
    eventStatus: "n/a",
    eventType: "navigation",
    internalLinkName: "review products",
    internalLinkPosition: "register products",
    internalLinkType: "my account:navigation",
    internalLinkURL:
      (linkUrl.indexOf("http") === -1 ? window.location.origin : "") + linkUrl,
    internalLinkZoneName: `my account:register online kohler products:${type}`,
  })
}

// step reviewInformation
const addReviewRegistrationEventInfo = (type, linkUrl) => {
  const linkFor = type?.toLowerCase()
  return {
    eventAction: `my account:register online kohler products:review and register:${linkFor}`,
    eventName: `my account:register online kohler products:review and register:${linkFor}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: linkFor,
    internalLinkPosition: "register products",
    internalLinkType: "my account:navigation",
    internalLinkZoneName: `my account:register online kohler products:review and register`,
    internalLinkURL: linkUrl,
    clickInternalLinks: "false",
  }
}

export {
  addReviewRegistrationAnalytics,
  getReviewProductsBackToMyProductsAnalytics,
  addRegisterOnlineProductsAnalytics,
  personalInfoEdit,
  selectProdcutEdit,
  getCheckboxAnalyticsdata,
  getSelectAllAnalytics,
  getEventInfo,
  addReviewRegistrationEventInfo,
}
