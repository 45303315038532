import React, { useEffect, useState } from "react"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import PropTypes from "prop-types"

import { getConfig } from "@/constants/config"
import { SWATCH_NA, EMPTY_PIXEL } from "@/constants/index"
import swatchMissing from "@/public/images/swatch-missing.jpeg"

const ColorSwatch = props => {
  const {
    type = "",
    id = "",
    label = "",
    name = "",
    value = "",
    checked = false,
    color = "",
    colorUrl = "",
  } = props

  const [config, setConfig] = useState({})
  const [swatchUrl, setSwatchUrl] = useState("")

  useEffect(() => {
    getConfig().then(config => setConfig(config))
  }, [])

  useEffect(() => {
    if (!_isEmpty(config)) {
      const assetAccountName = _get(
        config,
        "general.scene7AccountName",
        "PAWEB"
      )

      let swatchUrl = colorUrl ? colorUrl + "?$PDPSwatch_Desk$" : swatchMissing
      swatchUrl = swatchUrl.replace("PAWEB", assetAccountName)
      setSwatchUrl(swatchUrl)
    }
  }, [config])

  return (
    <div className="color-swatch" title={label}>
      <input
        type={type === "selector" ? "radio" : "checkbox"}
        id={id}
        name={name}
        value={value}
        checked={checked}
        style={{ backgroundColor: color }}
        onClick={e => props.onClick(e.target.value)}
        onChange={e => props.onChange(e)}
        data-testid={`color-swatch-${id ?? "0"}`}
      />
      {type !== "selector" && <label htmlFor={id}>{label}</label>}
      {type === "selector" && (
        <label
          className={`${
            !colorUrl || colorUrl.includes(SWATCH_NA) ? "--no-swatch" : ""
          }`}
          htmlFor={id}
        >
          {swatchUrl ? (
            <img role="presentation" src={swatchUrl} alt="NA" />
          ) : (
            <img role="presentation" src={EMPTY_PIXEL} alt="EMPTY_PIXEL" />
          )}
        </label>
      )}
    </div>
  )
}

ColorSwatch.defaultProps = {
  type: "",
  label: "",
  id: "",
  name: "",
  value: undefined,
  color: "",
  colorUrl: "",
  checked: undefined,
  defaultChecked: false,
  onClick: () => {},
  onChange: () => {},
}

ColorSwatch.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string || PropTypes.number,
  color: PropTypes.string,
  colorUrl: PropTypes.string,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onMouseEnter: PropTypes.func,
}

export default ColorSwatch
