import React, { useState } from "react"
import DOMPurify from "isomorphic-dompurify"
import CountryUtilNavLinks from "@/components/Default/CountryUtilNav/v1/CountryUtilNavLinks"
import useWindowDimensions from "@/hooks/useWindowDimensions"
const CountryUtilContainer = props => {
  const {
    countryarea,
    activeTab,
    title,
    description,
    countryselectorItems,
    datalayerPageName,
  } = props

  const { winWidth } = useWindowDimensions()

  const [expanded, setExpanded] = useState([])
  const handleExpand = (url, country) => {
    if (!url) {
      if (expanded.includes(country)) {
        const exp = [...expanded].filter(item => item !== country)
        setExpanded(exp)
      } else {
        const exp = [...expanded]
        exp.push(country)
        setExpanded(exp)
      }
    }
  }

  return (
    <div
      id={countryarea}
      className={`gbh-tab__content ${
        activeTab === countryarea ? "gbh-tab__content--active" : ""
      } `}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4 left d-none d-lg-block">
            <h3>{title}</h3>

            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(description),
              }}
            />
          </div>
          <div className="col-12 col-lg-8 right">
            <div className="row">
              <div className="col-lg-12 col-12 p-0">
                <ul className="gbh-tab__list" role="list">
                  {countryselectorItems &&
                    countryselectorItems.map(function (item, index) {
                      if (!item.country) return null
                      return (
                        <li
                          key={`${item.country}+${index}`}
                          role="listitem"
                          className={`${item.country}${
                            winWidth <= 1200 && !item.countryLink
                              ? " arrowdropdown"
                              : ""
                          }`}
                        >
                          <a
                            href={item.countryLink
                              ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
                              .join("")
                              .replace(".html", "")}
                            className="gbh-data-layer gbh-tab__item--link"
                            role="link"
                            data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"global header","internalLinkZoneName":"${datalayerPageName}${
                              datalayerPageName ? ":" : ""
                            }global header","internalLinkURL":"n/a","internalLinkName":"country select:${item.countryName.toLowerCase()}","eventName":"country selector","internalLinkType":"homepage:navigation","eventType":"country selector","eventAction":"country selector"}`}
                            aria-current="page"
                            onClick={() =>
                              handleExpand(item.countryLink, item.country)
                            }
                          >
                            {item.countryName}{" "}
                          </a>

                          {(item.languageOneAbbreviation ||
                            item.languageTwoAbbreviation ||
                            item.languageThreeAbbreviation) && (
                            <ul
                              style={
                                expanded.includes(item.country)
                                  ? { display: "block" }
                                  : {}
                              }
                            >
                              {item.languageOneAbbreviation && (
                                <CountryUtilNavLinks
                                  languageLink={item.languageOneLink}
                                  language={item.languageOne}
                                  countryName={item.countryName}
                                  languageAbbreviation={
                                    item.languageOneAbbreviation
                                  }
                                  datalayerPageName={datalayerPageName}
                                ></CountryUtilNavLinks>
                              )}

                              {item.languageTwoAbbreviation && (
                                <CountryUtilNavLinks
                                  languageLink={item.languageTwoLink}
                                  language={item.languageTwo}
                                  countryName={item.countryName}
                                  languageAbbreviation={
                                    item.languageTwoAbbreviation
                                  }
                                  datalayerPageName={datalayerPageName}
                                ></CountryUtilNavLinks>
                              )}
                              {item.languageThreeAbbreviation && (
                                <CountryUtilNavLinks
                                  languageLink={item.languageThreeLink}
                                  language={item.languageThree}
                                  countryName={item.countryName}
                                  languageAbbreviation={
                                    item.languageThreeAbbreviation
                                  }
                                  datalayerPageName={datalayerPageName}
                                ></CountryUtilNavLinks>
                              )}
                            </ul>
                          )}
                        </li>
                      )
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CountryUtilContainer
