export const getTranslations = t => {
  return {
    searchBrand: t("kf.search.label.searchBrand"),
    popularSearchText: t("kf.search.label.popularSearch"),
    topProductResults: category =>
      category !== "products"
        ? t("kf.search.label.topResults")
        : t("kf.search.label.topProductResults"),
    recentSearches: t("kf.search.label.recentSearch"),
    clearAll: t("kf.search.label.clearAll"),
    see: t("kf.search.label.see"),
    all: t("kf.search.label.all"),
    resultText: t("kf.search.label.resultsCaps"),
    searchCTA: t("kf.search.label.searchCTA"),
    top: t("kf.search.label.top"),
    product: t("kf.search.label.products"),
    topResult: t("kf.search.label.topResults"),
    saleText: t("kf.search.label.topResults"),
    colors: t("kf.search.label.colors"),
    color: t("kf.search.label.color"),
    brand: t("kf.search.label.brand"),
    new: t("kf.search.label.new"),
    sale: t("kf.search.label.sale"),
    topSuggestions: t("kf.search.label.topSuggestions"),
    yourSearch: t("kf.search.label.yourSearch"),
    seeAll: t("kf.search.label.seeAll"),
    results: t("kf.search.label.resultsCaps"),
    searchHeader: t("kf.search.label.searchHeader"),
    resultsCaps: t("kf.search.label.resultsCaps"),
    favSuccessMsg: t("kf.favorites.success"),
    favErrorMsg: t("kf.favorites.error"),
    favRemoveSuccessMsg: t("kf.favorites.removeItem"),
    favRemoveErrorMsg: t("kf.favorites.removeItemError"),

    sameCategory: t("kf.compare.validation.sameCategory"),
    maxProduct: t("kf.compare.validation.maxProducts"),
    addToCompare: t("kf.plp.label.addToCompare"),
    onSale: t("kf.plp.label.onSale"),
    ariaLabel: {
      close: t("kf.search.aria.label.close"),
      delete: keyword => t("kf.search.aria.label.delete", { keyword }),
      continue: t("kf.global.aria.label.continue"),
      addToCompare: product => t("kf.plp.label.aria.addToCompare", { product }),
    },
    exclusive: t("kf.plp.label.exclusive"),
    newExclusive: t("kf.plp.label.newExclusive"),
    globalHome: t("kf.global.label.home"),
  }
}
