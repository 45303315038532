export const sortOptions = [
  {
    label: "A-Z",
    key: "asc",
  },
  {
    label: "Z-A",
    key: "desc",
  },
]
export const LOCAL_PREFIX = ""
