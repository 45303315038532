import React from "react"
import Teaser from "@/components/Default/Teaser/v1/Teaser"

const CarouselFiller = ({ itemsOrder = [], items = [] }) => {
  return (
    <div className="cmp-carousel__whole">
      <div className="cmp-carousel__content">
        {itemsOrder.map((itemOrder, i) => {
          const item = items[itemOrder]
          return (
            <div
              key={`carousel-${i}`}
              className={`cmp-carousel__item${
                i === 0 ? " cmp-carousel__item--active" : ""
              }`}
              role="tabpanel"
              aria-label={`Slide ${i + 1} of ${itemsOrder?.length}`}
            >
              <Teaser data={item} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CarouselFiller
