import cartEmptyIcon from "@/public/icons/cart-icon-dark-empty.svg"
import cartNonEmptyIcon from "@/public/icons/cart-icon-dark-nonempty.svg"
import { getCartLandingPagePath } from "@/utils/cart"
import Link from "next/link"
import { memo, useEffect, useState } from "react"

const MiniCart = ({
  eventHandler: {
    store: { cartCount, ariaLabel },
  },
  color = "dark",
}) => {
  const [cartUrl, setCartUrl] = useState("")
  useEffect(() => {
    getCartLandingPagePath().then(res => {
      setCartUrl(res)
    })
  }, [])
  return (
    <div
      className={`cart-indicator v2 ${
        cartCount > 0 && "cart-indicator__with-count"
      } ${color == "dark" ? "dark" : "light"}`}
    >
      <Link
        href={cartUrl}
        aria-label={ariaLabel}
        role="link"
        className="gbh-data-layer"
        data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"global header","internalLinkZoneName":"global header","internalLinkURL":"${cartUrl}","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"cart icon","eventName":"cart icon","internalLinkType":"homepage:navigation","eventType":"global header","eventAction":"cart icon"}`}
      >
        {cartCount ? (
          <div
            className={`cart-indicator__count ${
              cartCount < 10 ? "count-single" : ""
            }`}
          >
            <img src={cartNonEmptyIcon?.src} alt="Cart" />
            <span>{cartCount}</span>
          </div>
        ) : (
          <img src={cartEmptyIcon?.src} alt="Cart" />
        )}
      </Link>
    </div>
  )
}
const checkForRerender = (prevProps, nextProps) => {
  return (
    nextProps.eventHandler.store.cartCount ===
    prevProps.eventHandler.store.cartCount
  )
}
export default memo(MiniCart, checkForRerender)
