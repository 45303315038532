import { addReviewRegistrationAnalytics } from "@/components/RegisterOnlineProduct/v1/RegisterOnlineProductAnalytics"
import { isShowAnalyticsEventLoaded } from "@/utils/helper"

const submitRegistrationGenericError = (productDetails, errorMsg) => {
  const isShowEvent = isShowAnalyticsEventLoaded()
  if (!isShowEvent) {
    addReviewRegistrationAnalytics(
      "cmp:click",
      "failure",
      productDetails,
      errorMsg
    )
  }
}

const convertToPhoneNumber = text => {
  try {
    if (text) {
      const value = text
      const x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      const suffix = x[3] ? "-" + x[3] : ""
      return !x[2] ? "(" + x[1] : "(" + x[1] + ") " + x[2] + suffix
    } else {
      return
    }
  } catch (error) {
    console.error(error)
  }
}

const getStateName = (stateVal, countryVal, data) => {
  let country = {}
  if (data.find(obj => obj.name === countryVal)) {
    country = data.find(obj => obj.name === countryVal)
  } else {
    country = data.find(obj => obj.code === countryVal) ?? {}
  }
  if (country.states?.find(obj => obj.name === stateVal)) return stateVal
  const state = country.states?.find(obj => obj.code === stateVal) ?? {}
  return state.name ?? ""
}

const getCountryName = (countryVal, data) => {
  if (data.find(obj => obj.name === countryVal)) return countryVal
  const country = data.find(obj => obj.code === countryVal) ?? {}
  return country.name ?? ""
}

const getDDMMYYYYDate = date => {
  const todayDate =
    date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
  return todayDate
}

export {
  submitRegistrationGenericError,
  convertToPhoneNumber,
  getStateName,
  getCountryName,
  getDDMMYYYYDate,
}
