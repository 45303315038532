import { pushAnalyticsObjToDataLayer } from "@/utils/helper"

export const addDataLayerOnClick = (datalayerPageName = "", item) => {
  const eventInfo = {
    clickInternalLinks: `${item.linkToNewTab ? "false" : "true"}`,
    internalLinkPosition: "global header",
    internalLinkZoneName: `${datalayerPageName}${
      datalayerPageName ? ":" : ""
    }global header`,
    internalLinkURL: `${item.link
      ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
      .join("")
      .replace(".html", "")}`,
    internalLinkName: `brand sync:${item.brandName.toLowerCase()}`,
    eventName: "brand sync",
    internalLinkType: "global header:navigation",
    eventType: "click",
    eventAction: "brand sync",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}
