import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import _isEmpty from "lodash/isEmpty"
import Input from "@/components/core/Input/Input"
import Button from "@/components/core/Button/Button"
import QuantitySelector from "@/components/cart/QuantitySelector"
import { getConfig } from "@/constants/config"
import { showToast } from "@/store/features/genericSlice"

import { isMobile } from "react-device-detect"
import { validateZip } from "@/utils/location"
import {
  sanitizeTextForAnalytics,
  validateField,
  getShortenedUrl,
  getCurrentSkuCouponDiscounts,
  sanitizeInnerHtml,
  getRandom,
} from "@/utils/helper"
import { NumericFormat } from "react-number-format"
import _get from "lodash/get"
import {
  callInstallServiceAddToCartAnalytics,
  callDesignServicesAddToCartAnalytics,
} from "@/components/productCard/v1/analytics"
import { productCardAddToCart } from "@/components/productCard/v1/productCardHelper"
import styles from "@/components/productCard/v1/index.module.scss"

const ProductCardService = props => {
  const {
    staticTexts = {},
    imagePosition = "",
    narrativeDescription,
    productFeatures = [],
    productCategory = "",
    badgeVal = "",
    currencySign = "",
    isNearestValueUpdated = Boolean,
    resetNearestValueUpdateFlag = () => {},
    cart = {},
    price = Number,
    skuId = "",
    images = "",
    userZipCode = "",
    slug = "",
    getZipCode = () => {},
    customerBrand = "",
    name: servicePlanName = "",
  } = props
  const dispatch = useDispatch()
  const [quantity, setQuantity] = useState(1)
  const [isNearest, setIsNearest] = useState(false)
  const [isNotNearestError, setIsNotNearestError] = useState(false)
  const [isCheckAvailability, setIsCheckAvailability] = useState(true)
  const [checkZipCode, setCheckZipCode] = useState("")
  const [zipCodeError, setZipCodeError] = useState({ show: false, message: "" })
  const [cartUrl, setCartUrl] = useState("")
  const [findaProLandingPagePath, setFindaProLandingPagePath] = useState("")
  const [siteName, setSiteName] = useState("")

  useEffect(() => {
    getConfig().then(CONFIG => {
      const { general } = CONFIG
      getShortenedUrl(general?.findaProLandingPagePath ?? "/").then(url => {
        setFindaProLandingPagePath(url)
      })
      getShortenedUrl(general?.cartLandingPagePath ?? "/").then(url => {
        setCartUrl(url)
      })
      setSiteName(general?.siteName ? general.siteName[0] : "")
    })
  }, [])
  const showToaster = msg => {
    dispatch(
      showToast({
        message: msg,
        isVisible: true,
      })
    )
  }

  const {
    altTextForServiceImg = "",
    virtualService = "",

    virtualTooltip = "",
    nearestInPerson = "",
    nearestService = "",
    change = "",
    zipCode = "",
    eligibleZipCodeSuccess = "",
    eligibleZipCodeError = "",

    discountPercentage = "",
    off = "",
    yourCart,
    itemAdded,
    itemsAdded,
    zipcodeEmptyError = "",
    zipodeEmptyError = "",
    validZipcode = "",
    search = "",
  } = staticTexts

  const installServices = productCategory === "Install Services"
  const designServices = productCategory === "Design Services"

  useEffect(() => {
    if (isNearestValueUpdated && resetNearestValueUpdateFlag) {
      resetNearestValueUpdateFlag()
      if (!isNearest) {
        addZipcodeAnalytics("failure", eligibleZipCodeError.toLowerCase())
      } else {
        addZipcodeAnalytics("success")
      }
    }
  }, [isNearestValueUpdated])

  let discountedPrice = props.discountedPrice
  let discountPercent = props.discountPercent
  let discountType = ""
  if (cart?.lineItems && cart.lineItems.length > 0) {
    cart.lineItems
      .filter(item => item.productSlug["en-US"] === slug)
      .map(product => {
        if (product.discountedPrice) {
          discountedPrice = (
            Number(_get(product.discountedPrice, "value.centAmount", 0)) / 100
          ).toFixed(2)
          discountType = _get(
            product.discountedPrice,
            "includedDiscounts[0].discount.obj.value.type",
            ""
          )
          discountPercent =
            discountType === "absolute"
              ? Number(
                  _get(
                    product.discountedPrice,
                    "includedDiscounts[0].discount.obj.value.money[0].centAmount",
                    0
                  )
                ) / 100
              : Number(
                  _get(
                    product.discountedPrice,
                    "includedDiscounts[0].discount.obj.value.permyriad",
                    0
                  )
                ) / 100
        }
        if (discountPercent === 0) {
          discountedPrice = Number(
            _get(product.price, "discounted.value.centAmount", 0) / 100
          ).toFixed(2)
          discountType = _get(
            product.price,
            "discounted.discount.obj.value.type",
            ""
          )
          discountPercent =
            discountType === "absolute"
              ? Number(
                  _get(
                    product.price,
                    "discounted.discount.obj.value.money[0].centAmount",
                    0
                  )
                ) / 100
              : Number(
                  _get(
                    product.price,
                    "discounted.discount.obj.value.permyriad",
                    0
                  )
                ) / 100
        }
      })
  }
  const productPrice = discountPercent > 0 ? discountedPrice : price
  if (discountType === "") {
    discountType =
      badgeVal && badgeVal?.split(" ")?.includes("Percent")
        ? "relative"
        : "absolute"
  }
  const getSaleOffer = `<p class='offer-percent'>${
    discountType === "relative"
      ? discountPercent + discountPercentage
      : currencySign +
        discountPercent +
        "<span class='offer-percent-text'>" +
        off +
        "</span>"
  }</p>`

  const productAnalyticsData = {
    desc: productFeatures
      ? sanitizeTextForAnalytics(productFeatures.join(". "))
      : "n/a",
    discountPrice: discountPercent > 0 ? discountedPrice : 0,
    discountPriceState:
      discountPercent > 0 ? "percentOff|" + discountPercent : "regular price",
    unitPrice: price,
    category: sanitizeTextForAnalytics(productCategory),
    color: "n/a",
    sku: skuId,
    name: sanitizeTextForAnalytics(props.name),
    image: images,
    customerFacingSKU:
      props.customerFacingSKU || (siteName ? siteName[0] + "-" + skuId : ""),
    skuInitials: props.customerFacingSKU
      ? props.customerFacingSKU.split("-")[0] + "-"
      : siteName
      ? siteName[0] + "-"
      : "",
    discountName: badgeVal,
    superSku: false,
    selectedColor: "n/a",
    productSaleable: true,
  }

  const {
    desc = "n/a",
    unitPrice = 0,
    discountPriceState,
    category,
    color,
    customerFacingSKU,
    name,
    discountName,
  } = productAnalyticsData

  const serviceName =
    productCategory === "Design Services"
      ? "bathroom design services"
      : sanitizeTextForAnalytics(productCategory)
  let globalDiscount = props.globalDiscount

  const {
    productCouponCode,
    productCoupondiscount,
    globalDiscountNames,
    globalDiscountValues,
  } = getCurrentSkuCouponDiscounts({ cart: cart, sku: skuId })

  let globalOffer = [discountName]

  if (globalDiscountNames?.length && globalDiscountValues) {
    globalOffer = [discountName, ...globalDiscountNames]
    globalDiscount += globalDiscountValues
  }

  const productInfo = {
    description: desc,
    frequency: "n/a",
    globalPromotionPrice:
      discountedPrice > 0 ? Number((price - globalDiscount).toFixed(2)) : 0,
    globalOffer: globalOffer.join("|") || "n/a",
    globalDiscount: globalDiscount ? Number(globalDiscount.toFixed(2)) : 0,
    isRecommended: "n/a",
    isSendNow: "n/a",
    isSubscription: "n/a",
    priceState: discountPriceState,
    productBasePrice: Number(unitPrice) || "n/a",
    productCategory: category || "n/a",
    productColor: color || "n/a",
    productCoupondiscount: productCoupondiscount || 0,
    productCouponCode: productCouponCode.join("|") || "n/a",
    productID: customerFacingSKU || "n/a",
    productName: name || desc || category || "n/a",
    productPartnerBuyNow: "n/a",
    productRoom: "service" || "n/a",
    productSalePrice: Number(productPrice),
    productSaleable: true,
    productStatus: "in stock",
    productSuperSku: false,
    productTileName: name || "n/a",
    quantity: quantity || "n/a",
    defaultImageName: productAnalyticsData.image || "n/a",
    ratings: "n/a",
    numberOfReviews: "n/a",
    pdpType: "regular finished goods & service parts",
    productFindingMethod: "n/a",
    productCollectionsName: category || "n/a",
    productBrand: siteName?.toLowerCase(),
    itemType: "service" || "n/a",
  }

  const getToasterMsg = msg => (
    <>
      {msg}
      <a href={cartUrl}>{yourCart}</a>.
    </>
  )

  const handleCallback = (res, msg) => {
    if (productCategory === "Install Services") {
      const eventInfoObj = getEventInfoObject("add to cart")
      const { status, message = "n/a" } = res

      eventInfoObj.eventStatus = status === 200 ? "success" : "failure"
      if (status !== 200) {
        eventInfoObj.eventMsg = message
          ? sanitizeTextForAnalytics(message)
          : "n/a"
      }
      callInstallServiceAddToCartAnalytics({
        resInfo: res,
        eventInfoObj,
        productInfo,
        cartID: cart?.id,
      })
    }
    if (productCategory === "Design Services") {
      callDesignServicesAddToCartAnalytics(
        res,
        productInfo,
        serviceName,
        cart?.id
      )
    }
    if (isMobile) showToaster(getToasterMsg(msg))
  }

  const addtoCart = items => {
    let totalAdded = 0
    items.forEach(item => (totalAdded += item.quantity))
    const msg =
      totalAdded === 1
        ? `${totalAdded} ${itemAdded} `
        : `${totalAdded} ${itemsAdded} `
    productCardAddToCart(
      [...items],
      true,
      "",
      res => handleCallback(res, msg),
      false
    )
  }

  const handleAddToCart = () => {
    if (productCategory === "Design Services") {
      addtoCart([{ sku: skuId, quantity: quantity, brand: customerBrand }])
    } else {
      if (!_isEmpty(userZipCode)) {
        addtoCart([
          {
            sku: skuId,
            quantity: quantity,
            userZipCode: userZipCode,
            brand: customerBrand,
          },
        ])
      }
    }
  }

  const handleChange = async qty => {
    if (qty && qty !== 0) {
      setQuantity(qty)
    }
  }

  const handleCheckAvailability = async () => {
    setZipCodeError({ show: false, message: "" })
    let valid = true
    let errorMessage = ""
    if (_isEmpty(checkZipCode)) {
      setZipCodeError({ show: true, message: zipcodeEmptyError })
      valid = false
      errorMessage = zipodeEmptyError.toLowerCase()
    } else if (!validateField("zipcode", checkZipCode)) {
      setZipCodeError({ show: true, message: validZipcode })
      valid = false
      errorMessage = validZipcode?.toLowerCase()
    } else if (!(await validateZip(checkZipCode))) {
      setZipCodeError({ show: true, message: validZipcode })
      valid = false
      errorMessage = validZipcode?.toLowerCase()
    }

    if (valid) {
      getZipCode(checkZipCode, true, callBackZipCode)
    } else {
      addZipcodeAnalytics("failure", errorMessage)
    }
  }
  const handleChangeZipCode = e => {
    const { value } = e.target
    if (validateField("zipCodeNumeric", value) || value === "") {
      setCheckZipCode(value)
    }
    setIsNotNearestError(false)
    setZipCodeError({ show: false, message: "" })
  }
  const zipCodeCheck = () => {
    if (!validateField("zipcode", checkZipCode)) {
      setZipCodeError({ show: true, message: validZipcode })
    } else {
      setZipCodeError({ show: false, message: "" })
    }
  }
  const getEventInfoObject = (eventNameText, buttonName) => {
    return {
      clickInternalLinks: "true",
      eventAction: "installation services:install service:" + eventNameText,
      eventName: "installation services:install service:" + eventNameText,
      eventType: "navigation",
      internalLinkName: buttonName ?? eventNameText,
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkPosition: "find installation services",
      internalLinkType:
        "installation services:" +
        (eventNameText !== "check availability" || !checkZipCode
          ? "navigation"
          : checkZipCode),
      internalLinkURL:
        eventNameText === "find a pro"
          ? (findaProLandingPagePath?.indexOf("http") === -1
              ? window.location.origin
              : "") + findaProLandingPagePath
          : "n/a",
      internalLinkZoneName:
        "installation services:" + productAnalyticsData.name,
    }
  }

  const addZipcodeAnalytics = (status, message) => {
    const eventInfoObj = getEventInfoObject("check availability", "search")
    eventInfoObj.eventMsg = message || "n/a"
    eventInfoObj.eventStatus = status
    callInstallServiceAddToCartAnalytics({
      resInfo: {},
      productInfo,
      eventInfoObj,
      cartID: cart?.id,
    })
  }

  const callBackZipCode = isServiceAvailable => {
    setIsNearest(isServiceAvailable)
    setIsNotNearestError(!isServiceAvailable)
    setIsCheckAvailability(!isServiceAvailable)
    if (isServiceAvailable) sessionStorage.setItem("verifiedZip", checkZipCode)
  }

  useEffect(() => {
    const isVerifiedZip = sessionStorage.getItem("verifiedZip")
    if (isVerifiedZip) {
      setIsNearest(true)
      setIsNotNearestError(false)
      setIsCheckAvailability(false)
      setCheckZipCode(isVerifiedZip)
    }
  }, [])

  const handleClickChangeBtn = () => {
    setIsCheckAvailability(true)
    setIsNearest(false)
  }

  return (
    <div className={styles.productCardWrapper}>
      <div className="product-card-service">
        <div className="kf-react-container">
          <div className="row ml-0 mr-0 no-gutters">
            <div
              className={`col product-card-service__details 
                  ${
                    imagePosition === "left"
                      ? " product-card-service__details-align"
                      : ""
                  }`}
            >
              <div
                className={`${
                  productCategory === "Install Services"
                    ? "product-card-service__details-header"
                    : "product-card-service__service-install"
                }`}
              >
                <div className="row">
                  <div className="col">
                    <h6 className="product-card-service__name">
                      {servicePlanName}
                    </h6>
                    {productCategory === "Install Services" ? (
                      <p className="product-card-service__desc">
                        {narrativeDescription}
                      </p>
                    ) : null}
                    {productCategory === "Design Services" && (
                      <div className="product-card-service__virtual-list">
                        <ul>
                          {productFeatures.map((list, index) => (
                            <li key={index}>{list}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  <div
                    className={`product-card-service__img-container 
                              ${
                                imagePosition === "left"
                                  ? " product-card-service__img-container-align"
                                  : ""
                              } 
                              mobile-landscape-image`}
                  >
                    <img
                      src={images}
                      className="product-card-service__img"
                      alt={altTextForServiceImg}
                    />
                  </div>
                </div>

                <div
                  className={`product-card-service__virtual-service 
                          ${
                            productCategory
                              ? " product-card-service__virtual-service-zip"
                              : ""
                          }`}
                >
                  <React.Fragment>
                    <div className="product-card-service__eligible-service">
                      <div className="left">
                        <p className="product-card-service__eligible-service-txt">
                          {designServices
                            ? virtualService
                            : isNearest
                            ? nearestInPerson
                            : nearestService}
                        </p>
                        {isNearest && (
                          <>
                            <p className="product-card-service__zipcode">
                              {checkZipCode}
                            </p>
                            <p
                              tabIndex="0"
                              role="link"
                              className="product-card-service__eligible-service-edit gbh-data-layer"
                              data-gbh-data-layer={JSON.stringify(
                                getEventInfoObject("edit", "change")
                              )}
                              data-gbh-data-layer-custom={JSON.stringify({
                                productInfo,
                              })}
                              onClick={handleClickChangeBtn}
                            >
                              {" "}
                              {change}
                            </p>
                          </>
                        )}
                      </div>
                      {isNearest || designServices ? (
                        <div className="product-card-service__price">
                          <NumericFormat
                            className="product-card-service__price-value"
                            value={productPrice}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={currencySign}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                          {discountPercent > 0 && (
                            <div className="product-card-service__discount-price">
                              <div className="product-card-service__offer-price">
                                {sanitizeInnerHtml(getSaleOffer)}
                              </div>
                              <NumericFormat
                                className="strike-through-price"
                                value={price}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={currencySign}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                    {isCheckAvailability && installServices ? (
                      <React.Fragment>
                        <div className="product-card-service__zipcode-availability">
                          <Input
                            id={`zipCode-${getRandom()}`}
                            type="text"
                            maxLength={60}
                            placeholder={`${zipCode}`}
                            label={`${zipCode}`}
                            value={checkZipCode}
                            customClass={`${
                              zipCodeError.show
                                ? "product-card-service__zipcode-input-error"
                                : ""
                            }`}
                            onKeyDown={e =>
                              e.key === "Enter" && handleCheckAvailability()
                            }
                            onChange={handleChangeZipCode}
                            onBlur={zipCodeCheck}
                          />
                          {checkZipCode ? (
                            <Button
                              role="button"
                              className="product-card-service__check-availability-cta"
                              label={search}
                              size="small"
                              type=" "
                              disabled={checkZipCode.length < 5}
                              onClick={handleCheckAvailability}
                            />
                          ) : null}
                        </div>
                        {zipCodeError.show && (
                          <div className="product-card-service__zipcode-error">
                            {zipCodeError.message}
                          </div>
                        )}
                      </React.Fragment>
                    ) : null}
                    {
                      <div className="product-card-service__eligible-service-container">
                        {isNearest || designServices ? (
                          <p className="product-card-service__eligible-service-message">
                            {designServices
                              ? virtualTooltip
                              : eligibleZipCodeSuccess}
                          </p>
                        ) : null}
                        {isNotNearestError ? (
                          <p className="error">
                            {eligibleZipCodeError}{" "}
                            <a
                              href={findaProLandingPagePath}
                              className="product-card-service__find-a-pro gbh-data-layer"
                              data-gbh-data-layer={JSON.stringify(
                                getEventInfoObject("find a pro")
                              )}
                              data-gbh-data-layer-custom={JSON.stringify({
                                productInfo,
                              })}
                            >
                              {staticTexts.findaPro}
                            </a>
                          </p>
                        ) : null}
                      </div>
                    }
                  </React.Fragment>
                </div>
                {isNearest || designServices ? (
                  <div className="product-card-service__price-and-cart">
                    <div className="product-card-service__cart">
                      <div className="cartAction mx-0">
                        <div className="quantity-selector-container">
                          <QuantitySelector
                            doNotRequiredAnalyticsData={true}
                            productData={productAnalyticsData}
                            value={quantity}
                            onChange={handleChange}
                            isPDPQuantitySelector={true}
                          />
                        </div>
                        <div className="add-to-cart-container">
                          <Button
                            role="button"
                            label={staticTexts.addToCart}
                            onClick={handleAddToCart}
                            size="large"
                            type="primary"
                            flexible={true}
                            disabled={
                              productCategory !== "Design Services"
                                ? !isNearest
                                : false
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={`product-card-service__img-container ${
                imagePosition === "left"
                  ? " product-card-service__img-container-align"
                  : ""
              } product-card-service-main-image`}
            >
              <img
                src={images}
                className="product-card-service__img"
                alt={altTextForServiceImg}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ProductCardService.defaultProps = {
  newTab: false,
  staticTexts: {},
}

ProductCardService.propTypes = {
  newTab: PropTypes.bool,
  staticTexts: PropTypes.object,
}

export default ProductCardService
