import _get from "lodash/get"
import { getProductInfo } from "@/components/productCard/v3/productCardHelper"

const pushToAdobeDataLayer = (eventInfo, productInfo) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = getState?.("page") || {}
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    ...(productInfo ? { productInfo } : {}),
    page,
  })
}

const dataLayershopTheRoomPlusButton = (cardTitle, url) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `shop the room:${cardTitle.toLowerCase()}:room detail card modal open`,
    eventName: `shop the room:${cardTitle.toLowerCase()}:room detail card modal open`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "room detail card modal open",
    internalLinkPosition: "shop the room",
    internalLinkType: `shop the room:${cardTitle.toLowerCase()}:room detail card modal open`,
    internalLinkZoneName: "shop the room:all rooms",
    internalLinkURL: url,
  }
  pushToAdobeDataLayer(eventInfo)
}

const addAnalyticsEventForPrevNext = navigationType => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `shop the room: room detail card modal:${navigationType.toLowerCase()} arrow`,
    eventName: `shop the room: room detail card modal:${navigationType.toLowerCase()} arrow`,
    eventType: "carousel",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: `${navigationType.toLowerCase()} arrow`,
    internalLinkPosition: "shop the room: room detail card modal:carousel",
    internalLinkType:
      "shop the room: room detail card modal:classic glam room details",
    internalLinkZoneName:
      "shop the room: room detail card modal: shop products in this room",
    internalLinkURL: "n/a",
  }
  pushToAdobeDataLayer(eventInfo)
}

const addAnalyticsEventForImageTitle = (cardTitle, url) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `shop the room: room detail card modal:${cardTitle.toLowerCase()}`,
    eventName: `shop the room: room detail card modal:${cardTitle.toLowerCase()}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: `${cardTitle.toLowerCase()}`,
    internalLinkPosition: "room detail card modal",
    internalLinkType: `shop the room: room detail card modal:${cardTitle.toLowerCase()}`,
    internalLinkZoneName: "shop the room: room detail card modal",
    internalLinkURL: url,
  }
  pushToAdobeDataLayer(eventInfo)
}

const addAnalyticsEventForFavouriteAddRemove = (favouriteType, productInfo) => {
  const favoriteText = favouriteType ? "add" : "remove"
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `shop the room: room detail card modal: favorite ${favoriteText.toLowerCase()}`,
    eventMsg: `your item has been ${favoriteText.toLowerCase()} to your Favorites`,
    eventName: `shop the room: room detail card modal: favorite ${favoriteText.toLowerCase()}`,
    eventType: "wishlist save",
    eventStatus: "sucess",
    internalLinkName: `favorite ${favoriteText.toLowerCase()}`,
    internalLinkPosition: "shop the room: room detail card modal",
    internalLinkType: "shop the room: room detail card modal: favorite",
    internalLinkZoneName: "shop the room: room detail card modal",
    internalLinkURL: "n/a",
  }
  pushToAdobeDataLayer(eventInfo, productInfo)
}

const closeButtonAnalytics = () => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: "shop the room: room detail card modal:close button",
    eventName: "shop the room: room detail card modal:close button",
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "close",
    internalLinkPosition: "shop the room: room detail card modal",
    internalLinkType: "shop the room: room detail card modal :close",
    internalLinkZoneName: "shop the room: room detail card modal:navigation",
    internalLinkURL: "n/a",
  }
  pushToAdobeDataLayer(eventInfo)
}

const addAnalyticsEventForProductInfo = (eventInfo, productInfo) => {
  pushToAdobeDataLayer(eventInfo, productInfo)
}

const shopRoomhandleProductClick = productInfoParams => {
  const { productData, sku, url, cart, config, description, persona } =
    productInfoParams
  const productInfo = getProductInfo(
    productData,
    sku,
    false,
    cart,
    config,
    description,
    persona
  )
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: "shop the room: room detail card modal: product navigation",
    eventMsg: "n/a",
    eventName: "shop the room: room detail card modal: product navigation",
    eventStatus: "n/a",
    eventType: "navigation",
    internalLinkName: _get(productData, "RegionBrandName_s", ""),
    internalLinkPosition: "shop the room: room detail card modal",
    internalLinkType: "shop the room: room detail card modal: navigation",
    internalLinkURL: url,
    internalLinkZoneName: "shop the room: room detail card modal",
  }
  addAnalyticsEventForProductInfo(eventInfo, productInfo)
}

export {
  dataLayershopTheRoomPlusButton,
  addAnalyticsEventForPrevNext,
  addAnalyticsEventForImageTitle,
  addAnalyticsEventForFavouriteAddRemove,
  closeButtonAnalytics,
  addAnalyticsEventForProductInfo,
  shopRoomhandleProductClick,
}
