export const getTranslations = t => {
  return {
    useLocation: t("kf.findStoreUtilNav.label.useLocation"),
    viewAllStore: t("kf.findStoreUtilNav.label.viewAllStore"),
    submit: t("kf.global.label.submit"),
    findStore: t("kf.findStoreUtilNav.label.findStore"),
    closestStore: t("kf.findStoreUtilNav.label.closestStore"),
    kohlerText: t("kf.findStoreUtilNav.label.kohler"),
    signStore: t("kf.findStoreUtilNav.label.signStore"),
    NotYourStore: t("kf.findStoreUtilNav.label.NotYourStore"),
    viewStoreDetail: t("kf.findStoreUtilNav.label.viewStoreDetail"),
    storeDetail: t("kf.findStoreUtilNav.label.storeDetail"),
    setAsMyStore: t("kf.findStoreUtilNav.label.setAsMystore"),
    myStore: t("kf.findStoreUtilNav.label.myStore"),
    myStoreText: t("kf.findStoreUtilNav.label.myStoreText"),
    locationDescription: t("kf.findStoreUtilNav.label.locationDescription"),
    zipCodeContent: t("kf.findStoreUtilNav.label.zipCodeContent"),
    closeAlt: t("kf.findStoreUtilNav.label.closeAlt"),
    menu: t("kf.findStoreUtilNav.label.menu"),
    leftArrow: t("kf.findStoreUtilNav.label.leftArrow"),
    enterZipCode: t("kf.findStoreUtilNav.label.enterZipCode"),
    zipcode: t("kf.findStoreUtilNav.zipcode"),
    validZipcode: t("kf.findStoreUtilNav.validZipcode"),
    MessageZipcode: t("kf.findStoreUtilNav.MessageZipcode"),
    infoModalTitle: t("kf.findStoreUtilNav.messageTitleZipcode"),
    zipcodeEmptyError: t("kf.findStoreUtilNav.zipcodeEmptyError"),
    continue: t("kf.checkout.label.continue"),
    ariaLabel: {
      enterZipCode: t("kf.findStoreUtilNav.aria.label.enterZipCode"),
      submit: t("kf.findStoreUtilNav.aria.label.submit"),
      // utilityNavLabel: t("kf.findStoreUtilNav.aria.label.utilityNavLabel", {
      //   location: !_isEmpty(userCity) ? userCity : "",
      // }),
    },
    enterCityStateZip: t("kf.findStoreUtilNav.label.countryPlaceholder"),
  }
}
