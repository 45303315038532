export const loadLazyImage = (srcRef, imgRef) => {
  const newSourceRef = Array.isArray(srcRef?.current)
    ? srcRef?.current
    : [srcRef?.current]
  if (newSourceRef?.length > 0) {
    newSourceRef.forEach((src, index) => {
      if (src?.hasAttribute("data-srcset")) {
        newSourceRef[index]?.setAttribute(
          "srcset",
          src?.getAttribute("data-srcset")
        )
        src?.removeAttribute("data-srcset")
      }
    })
  }

  const newImageRef = Array.isArray(imgRef?.current)
    ? imgRef?.current
    : [imgRef?.current]
  if (newImageRef?.length > 0) {
    newImageRef.forEach((img, index) => {
      if (img?.hasAttribute("data-src")) {
        newImageRef[index]?.setAttribute("src", img?.getAttribute("data-src"))
        img?.removeAttribute("data-src")
      }
    })
  }
}
