import React, { useEffect, useState } from "react"
import dynamic from "next/dynamic"
import { getShortenedUrl } from "@/utils/helper"
import {
  dataLayershopTheRoomPlusButton,
  closeButtonAnalytics,
} from "@/components/ShopTheRoomCard/analytics"

const DynamicImage = dynamic(() =>
  import("@/components/core/Image/DynamicImage")
)
const Modal = dynamic(() => import("@/components/core/Modal/Modal"))
const ShopTheRoomCardModalView = dynamic(() =>
  import("@/components/ShopTheRoomCard/v1/ShopTheRoomCardModalView")
)

const ShopTheRoomCard = props => {
  const {
    cardTitle = "",
    cardHoverTitle = "",
    ctalink = "",
    landingImageLink = "",
    modelImageLink = "",
    skuIds = [],
    presetConfigs = "{}",
    altLanding = "",
    altModel = "",
    linkNewTab = false,
  } = props

  const [showModal, setShowModalState] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const [link, setLink] = useState("")
  const [isImageLoaded, setImageLoaded] = useState(null)

  useEffect(() => {
    setImageLoaded(
      <DynamicImage
        imgUrl={landingImageLink}
        alt={altLanding}
        width="100%"
        style={{ aspectRatio: "1.333/1" }}
      />
    )
  }, [landingImageLink])

  useEffect(() => {
    if (ctalink) {
      getShortenedUrl(ctalink).then(link => setLink(link))
    }
  }, [ctalink])

  const handleModal = e => {
    e.stopPropagation()
    setShowModalState(!showModal)
    dataLayershopTheRoomPlusButton(cardTitle)
  }

  const handleCloseModal = e => {
    e.stopPropagation()
    setShowModalState(!showModal)
    closeButtonAnalytics()
  }

  return (
    <div className="shoptheroomcard">
      <div
        className="shop-the-room-card-container"
        style={isImageLoaded ? {} : { height: "66%" }}
      >
        <div className="shop-the-room-card">
          <a
            href={link}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            target={linkNewTab ? "_blank" : ""}
            rel="noreferrer"
          >
            <div className="shop-the-room-card-clip">
              {isImageLoaded ? (
                <>{isImageLoaded}</>
              ) : (
                <div className="product-tile">
                  <div className="product-list-tile__image wow">
                    <div className="shimmer-loading__image shimmer" />
                  </div>
                </div>
              )}
              {isHover ? (
                <div className="shop-the-room-card-hover-text">
                  {cardHoverTitle}
                </div>
              ) : null}
            </div>
          </a>
          <div className="plus" onClick={handleModal}>
            <div className="line line-1" />
            <div className="line line-2" />
          </div>
        </div>
        <div className="shop-the-room-card-modal">
          <Modal showModal={showModal} onModalClose={handleCloseModal}>
            <ShopTheRoomCardModalView
              skuIds={skuIds}
              presetConfigs={presetConfigs}
              imageLink={modelImageLink}
              cardTitle={cardTitle}
              ctalink={ctalink}
              altModel={altModel}
            />
          </Modal>
        </div>
        <p className="shop-card-title">{cardTitle}</p>
      </div>
    </div>
  )
}

export default ShopTheRoomCard
