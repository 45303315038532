import {
  pushAnalyticsObjToDataLayer,
  sanitizeTextForAnalytics,
} from "@/utils/helper"

const addFindStoreAnalytics = (zipCodeValue, zipCodeError) => {
  const { show, message } = zipCodeError
  const eventMsg = show ? message?.toLowerCase() : "n/a"

  const eventStatus = show ? "failure" : "success"
  const eventInfo = {
    eventAction: "find a store:utility nav:submit",
    eventName: "find a store:utility nav:submit",
    eventType: "navigation",
    eventMsg,
    eventStatus,
    internalLinkName: "submit",
    internalLinkPosition: "find a store",
    internalLinkType: `find a store:${zipCodeValue ?? ""}`,
    internalLinkZoneName: "find a store:utility nav",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }

  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    page,
  })
}

export const headerFindStoreUseLocationAnalytics = ({
  errorMessage = "",
  staticTexts = {},
  eventStatus = "",
}) => {
  const eventMsg = errorMessage
    ? sanitizeTextForAnalytics(
        errorMessage ? errorMessage : staticTexts.locationErrormessage
      )
    : "n/a"

  const eventInfo = {
    eventAction: "find a store:utility nav:use my location",
    eventName: "find a store:utility nav:use my location",
    eventType: "navigation",
    eventMsg,
    eventStatus,
    internalLinkName: "use my location",
    internalLinkPosition: "global header:find a store",
    internalLinkType: "global header:navigation",
    internalLinkZoneName: "global header",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

export { addFindStoreAnalytics }
