import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Heading from "@/components/core/Heading/Heading"
import useTrigger from "@/hooks/useTrigger"
import useViewport from "@/hooks/useViewport"
import SortDropdown from "@/components/PartsViewer/v2/GridAndListPanel/SortDropdown"
import ToggleView from "@/components/PartsViewer/v2/GridAndListPanel/ToggleView"
import ViewController from "@/components/PartsViewer/v2/GridAndListPanel/ViewController"
import {
  IconCollapse,
  IconExpandGrid,
  BackToTop,
} from "@/components/PartsViewer/v2/icon.js"

const GridAndListPanel = props => {
  const {
    partsList,
    setIsListExtend,
    isListExtend,
    title,
    limit = 6,
    showPopup,
    partController,
    sku,
  } = props
  const [listView, setlistView] = useState(false)
  const [trigger, callTrigger] = useTrigger()
  const device = useViewport()
  const loadCount = partsList?.length < limit ? partsList?.length : limit
  const visibleCount = partController.actions.get.firstEntries()?.length
  const totalCount = partsList?.length
  const [visibleData, setVisibleData] = useState(loadCount)

  useEffect(() => callTrigger(), [title])
  useEffect(() => {
    if (visibleCount < limit) partController.actions.fetchCTAny(loadCount)
  }, [trigger])

  const scrollToTop = () => {
    const ele = document.querySelector(".right-panel")
    if (ele) {
      ele.scrollIntoView({ behaviour: "smooth" })
    }
  }
  const loadMoreitems = e => {
    setVisibleData(visibleData + loadCount)
    partController.actions.fetchCTAny(visibleData)
  }

  const isDesktop =
    device.isLargeScreen || device.isExtraLargeScreen || device.isMediumScreen
  return (
    <div
      className="product-list-viewer h-100"
      data-testid="right-panel-wrapper"
    >
      <Container fluid>
        <Row className="right-panel">
          <Col className="d-flex justify-content-between align-items-center">
            {isDesktop ? (
              <ToggleView {...{ listView, setlistView, device, title, sku }} />
            ) : null}
            <div className="d-flex align-items-center justify-content-center">
              {isDesktop ? (
                <button
                  onClick={() => {
                    setIsListExtend(state => !state)
                  }}
                  data-testid="expand-button"
                  className="expand-button btn-expand border-0 d-flex align-items-center justify-content-center list-view-icons"
                >
                  {isListExtend ? <IconCollapse /> : <IconExpandGrid />}
                  <div className="tool-tip-custom bottom">
                    <span>{!isListExtend ? "Expand" : "Exit Expand"}</span>
                  </div>
                </button>
              ) : null}
            </div>
          </Col>
        </Row>

        <Row className="flex-column gird-content">
          {isDesktop ? (
            <Col className="mt-4">
              <Heading
                level={3}
                fontSize={26}
                className="heading-grid"
                fontWeight={300}
              >
                {title}
              </Heading>
            </Col>
          ) : (
            ""
          )}

          <Col>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="total_item">
                <span
                  className={`total-count fw-400 ${
                    isListExtend ? "full-screen-view" : "small-screen-view"
                  }`}
                >
                  {partsList?.length || 0} Results
                </span>
              </div>
              <SortDropdown
                {...{
                  partController,
                  visibleData,
                  title,
                  sku,
                  setlistView,
                  listView,
                  isListExtend,
                }}
              />
            </div>
          </Col>
          <Col className="d-flex align-items-center flex-column">
            <div
              className={`list-items ${
                listView
                  ? "d-flex justify-content-between flex-column"
                  : `thumbnail-items ${device.isDesktop ? "full-screens" : ""}`
              }`}
            >
              <ViewController
                {...{
                  partController,
                  isListView: listView,
                  isListExtend,
                  showPopup,
                  visibleData,
                  title,
                  sku,
                }}
              />

              {Array.from({ length: 6 }, (val, index) => index + 1)?.map(
                val => (
                  <div key={val} className="card empty-card"></div>
                )
              )}
            </div>
            <div className="load-more d-flex align-items-center flex-column  w-100">
              {visibleData < totalCount ? (
                <button
                  onClick={loadMoreitems}
                  className="load-more-button fs-18 fw-400 w-100"
                >
                  Load More
                </button>
              ) : null}
            </div>
            {totalCount > 0 ? (
              <div className="back-to-top">
                <button onClick={() => scrollToTop()} className="mx-2">
                  Back to Top
                </button>
                <BackToTop />
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default GridAndListPanel
