import { useTranslation } from "next-i18next"

const useFindAProByZipi18n = componentProp => {
  const { t } = useTranslation()

  const i18n = {
    useMyLocation: t("kf.findpro.label.useMyLocation"),
    zipcodeDesc: componentProp?.zipcodeDesc
      ? componentProp?.zipcodeDesc
      : t("kf.findpro.label.zipcodeDesc"),
    zipcodeInfo: componentProp?.zipcodeInfo
      ? componentProp?.zipcodeInfo
      : t("kf.findpro.label.zipcodeInfo"),
    zipcodeLabel: t("kf.findpro.label.zipcodeLabel"),
    submitCTA: t("kf.findpro.label.submitCTA"),
    validZipcode: t("kf.findStoreUtilNav.validZipcode"),
    ariaLabel: {
      zipcodeLabel: t("kf.findpro.aria.label.zipcodeLabel"),
    },
    continue: t("kf.checkout.label.continue"),
  }

  return i18n
}

export default useFindAProByZipi18n
