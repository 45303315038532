import React, { useCallback, useEffect, useRef, useState } from "react"
import { sanitizeInnerHtml } from "@/utils/helper"
import { getEventInfo } from "@/components/VirtualDesignerDetails/v1/analytics"
import MissingImage from "@/public/images/image-missing.png"

const VirtualDesignerDetailsView = props => {
  const { data, staticTexts } = props
  const [breadCrumb, setBreadCrumb] = useState("")
  const contactRef = useRef(null)

  const {
    availableCTA = "",
    userAlt = "",
    specialtiesTxt = "",
    contactTxt = "",
  } = staticTexts

  let imageUrl = ""
  if (!imageUrl && data["desktop-banner-image"]) {
    imageUrl = data["desktop-banner-image"]
  } else if (!imageUrl) {
    imageUrl = MissingImage?.src
  }

  useEffect(() => {
    const element = document.getElementsByClassName("cmp-breadcrumb__list")[0]
    if (typeof element !== "undefined" && element !== null) {
      setBreadCrumb(element.innerHTML)
      document.querySelector(".breadcrumb").style.display = "none"
    }
  }, [])

  useEffect(() => {
    if (data.contactInfo) {
      if (contactRef.current) {
        const links = contactRef.current.querySelectorAll("a")
        links.forEach(link => {
          link.classList.add("gbh-data-layer")
          const phone = link.textContent
          const url = link.getAttribute("href")
          const isEmail = url.includes("mailto")
          link.setAttribute(
            "data-gbh-data-layer",
            getEventInfo(phone, url, data?.proName, isEmail)
          )
        })
      }
    }
  }, [data])

  const computeSocialMediaList = useCallback(() => {
    const keyData = Object.keys(data)
    const socialMediaList = keyData
      .map(d => {
        const e = !d.match("@LastModified")
        if (e) {
          const matchedObjectKey = d.match("social-media-icon-path-")
          if (matchedObjectKey) {
            const splitStr = matchedObjectKey["input"].split("-")
            return {
              icon: matchedObjectKey["input"],
              alt: `social-media-alt-${splitStr[4]}`,
              redirectionLink: `social-media-redirection-link-${splitStr[4]}`,
              newTab: `newtab-${splitStr[4]}`,
            }
          }
        }
        return null // Return null for non-matching keys
      })
      .filter(item => item !== null) // Filter out null values

    return socialMediaList
  }, [data])

  const socialMediaList = computeSocialMediaList()

  return (
    <div className="virtual-designer-details">
      <div className="row top-section ml-0 mr-0">
        <div className="col-12">
          <div className="virtual-designer-details__breadcrumb">
            {sanitizeInnerHtml(breadCrumb)}
          </div>
          <div className="virtual-designer-details__container">
            <div className="row">
              <div className="virtual-designer-details__profile-section col-12 col-sm-12 col-md-5 col-lg-5 top-left">
                <div className="professional-name">{data?.proName}</div>
                <div className="profile-details">
                  <div className="professional-details">
                    <div className="professional-address">
                      <div className="partner-address">
                        {sanitizeInnerHtml(data?.address)}
                      </div>
                    </div>
                    <div className="professional-designation mt-5">
                      {data?.jobTitle}
                    </div>
                    <div className="professional-availability">
                      <span className="availability-days">
                        {data?.available === true ? (
                          <span className="available-btn">{availableCTA}</span>
                        ) : (
                          <div className="available-month">
                            {sanitizeInnerHtml(data?.duration)}
                          </div>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="partner-photography">
                    {data?.image && <img src={data?.image} alt={userAlt} />}
                  </div>
                </div>
              </div>
              <div className="cover-image-wrapper col-12 col-sm-12 col-md-7 col-lg-7 px-0 top-right">
                {data ? (
                  <picture>
                    <source
                      srcset={data["mobile-portrait-image"]}
                      media="(max-width: 489px)"
                    />
                    <source
                      srcset={data["mobile-landscape-image"]}
                      media="(max-width: 992px)"
                    />
                    <img
                      className="cover-image"
                      src={imageUrl}
                      alt={data["alt-text-banner"]}
                    />
                  </picture>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="virtual-designer-details__contact-section">
        <div className="kf-react-container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="profile-description">
                {sanitizeInnerHtml(data?.biography)}
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="contact-wrapper">
                <div className="specialities">
                  <div className="speciality-title">{specialtiesTxt}</div>
                  <div className="partner-address">
                    {sanitizeInnerHtml(data?.specialties)}
                  </div>
                </div>
                <div className="contacts">
                  <div className="contact-title">{contactTxt}</div>
                  <div className="contact-details" ref={contactRef}>
                    <div className="partner-address">
                      {sanitizeInnerHtml(data?.contactInfo)}
                    </div>
                  </div>
                  <div className="social-medias">
                    {socialMediaList.map((s, i) => {
                      return (
                        <a
                          href={data[s.redirectionLink]}
                          className="social-icon"
                          target={`${(data[s.newTab] = true ? "_blank" : "")}`}
                          key={i}
                        >
                          <img src={data[s.icon]} alt={data[s.alt]} />
                        </a>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VirtualDesignerDetailsView
