import Image from "next/image"
import { useState } from "react"

import MissingImage from "@/public/images/image-missing.png"

const ImageWrapper = props => {
  const { src = MissingImage, ...restProps } = props
  const [isError, setIsError] = useState(false)

  return (
    <Image
      {...restProps}
      src={isError ? MissingImage : src}
      onError={() => setIsError(true)}
    />
  )
}

export default ImageWrapper
