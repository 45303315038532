/**
 *
 * @param {Object} resInfo
 * @param {Object} productInfo
 * Kohler Data Layer Tech Spec 2021 - Batch 5 - Installation Services v1
 * Requirement#12 Add to Cart button
 */
import { sanitizeTextForAnalytics } from "@/utils/helper"

export const callInstallServiceAddToCartAnalytics = ({
  resInfo,
  productInfo,
  cartID,
  eventInfoObj,
}) => {
  const { status, message = "n/a" } = resInfo
  const isStatus = status === 200
  const eventInfo = eventInfoObj ?? {
    eventAction:
      "installer services:already have a product purchase a kohler installation service:add to cart",
    eventName:
      "installer services:already have a product purchase a kohler installation service:add to cart",
    eventType: "navigation",
    eventMsg: message?.toLowerCase(),
    eventStatus: isStatus ? "success" : "failure",
    internalLinkName: "add to cart",
    internalLinkPosition: "installer services",
    internalLinkType: "installer services:in-person",
    internalLinkZoneName:
      "installer services:already have a product purchase a kohler installation service",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }

  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  page.cart = { cartID }
  window.adobeDataLayer?.push({
    event: "cmp:click",
    eventInfo,
    productInfo,
    page,
  })
}

export const callDesignServicesAddToCartAnalytics = (
  resInfo,
  productInfo,
  serviceName,
  cartID
) => {
  const { status, message = "n/a" } = resInfo
  const isStatus = status === 200
  const eventInfo = {
    eventAction: serviceName + ":virtual bathroom design service:add to cart",
    eventName: serviceName + ":virtual bathroom design service:add to cart",
    eventType: "navigation",
    eventMsg: message?.toLowerCase(),
    eventStatus: isStatus ? "success" : "failure",
    internalLinkName: "add to cart",
    internalLinkPosition: serviceName,
    internalLinkType: serviceName + ":add to cart",
    internalLinkZoneName: serviceName + ":virtual bathroom design service",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }

  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  page.cart = { cartID }
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    page,
    productInfo,
  })
}

export const getMoreInCollectionsInfo = (
  title,
  needsJSON,
  pdpNavigationURL = "",
  product = {}
) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const primaryCategory = page?.category?.primaryCategory
  const type = title?.toLowerCase() === "recently viewed"
  const eventAction = `${primaryCategory}:${sanitizeTextForAnalytics(
    title ?? ""
  )}:product navigation`

  const internalLinkURL =
    (pdpNavigationURL?.indexOf("http") === -1 ? window.location.origin : "") +
    pdpNavigationURL

  const internalLinkZoneName = `${primaryCategory}:${sanitizeTextForAnalytics(
    title ?? ""
  )}:preview`

  const eventInfo = {
    eventAction,
    eventName: eventAction,
    eventNavigation: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: `${sanitizeTextForAnalytics(product?.name)} `,
    internalLinkPosition: primaryCategory,
    internalLinkType: `${primaryCategory}:navigation`,
    internalLinkURL,
    internalLinkZoneName,
    clickInternalLinks: "true",
  }

  if (type) {
    eventInfo.eventAction = "pdp:recently viewed:product navigation"
    eventInfo.eventName = "pdp:recently viewed:product navigation"
    eventInfo.internalLinkPosition = "pdp"
    eventInfo.internalLinkType = "pdp:navigation"
    eventInfo.internalLinkZoneName = "pdp:recently:viewed:preview"
  }
  if (needsJSON) {
    return eventInfo
  }
  return JSON.stringify(eventInfo)
}

export const getMoreInCollectionSlider = title => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const primaryCategory = page?.category?.primaryCategory
  const eventAction = `${primaryCategory}:${sanitizeTextForAnalytics(
    title ?? ""
  )}:product slider`

  const internalLinkZoneName = `${primaryCategory}:${sanitizeTextForAnalytics(
    title ?? ""
  )}:preview`

  const eventInfo = {
    eventAction,
    eventName: eventAction,
    eventNavigation: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "slider",
    internalLinkPosition: primaryCategory,
    internalLinkType: `${primaryCategory}:navigation`,
    internalLinkURL: "n/a",
    internalLinkZoneName,
    clickInternalLinks: "true",
  }

  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    page,
  })
}
