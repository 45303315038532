import React, { useEffect, useState } from "react"
import { convertStringToBoolean, sanitizeInnerHtml } from "@/utils/helper"
import DynamicImage from "@/components/core/Image/DynamicImage"

const Image = ({ imageData = {}, resource = {} }) => {
  const {
    imageComponentProperties = {},
    description = "",
    areas = [],
    link = "",
    displayPopupTitle = "",
    newTab = "false",
    lazyThreshold = 0,
    widths = [],
  } = imageData
  const {
    id = "",
    alt = "",
    src = "",
    smartCropRendition = "",
    fileReference = "",
  } = imageComponentProperties

  const DM_IMAGE = "image"
  const EAEM_DM_IMAGE = "eaem-image"
  const EAEM_BOUNDING_DIV_CSS = "eaem-bounding-div"

  const [imageDiv, setImageDiv] = useState(null)

  useEffect(() => {
    if (imageData) {
      init()
    }
  }, [imageData])

  const handleImageLoad = event => {
    setImageDiv(event.target)
  }
  const init = () => {
    const elements = document.querySelectorAll('[data-cmp-is="image"]')
    elements.forEach(element => {
      const smatCropRend = element.attributes.getNamedItem(
        "data-cmp-smartcroprendition"
      )
      if (!smatCropRend || smatCropRend.value !== "SmartCrop:Auto") {
        return
      }
      element.attributes.getNamedItem("data-cmp-is").value = EAEM_DM_IMAGE
    })

    addImageClusterLazyLoad()
  }

  const addImageClusterLazyLoad = () => {
    document.querySelectorAll(".gbh-img-cluster, .image").forEach(component => {
      if (
        isImageInViewport(component) ||
        isImageInViewport(component.querySelector(".gbh-img-left-half__inner"))
      ) {
        const lazyImageDiv = component.querySelectorAll(
          `[data-cmp-is="${EAEM_DM_IMAGE}"]`
        )
        if (lazyImageDiv.length > 0) {
          lazyImageDiv.forEach(childDiv => {
            setImageDiv(childDiv)
            showImage()
          })
        }
      } else {
        addListenerForImageVisibilityCheck(component)
      }
    })
  }

  const showImage = () => {
    if (imageDiv.parentNode.classList.contains(EAEM_BOUNDING_DIV_CSS)) {
      return
    }

    setImageDiv(prevImageDiv => ({
      ...prevImageDiv,
      attributes: {
        ...prevImageDiv.attributes,
        "data-cmp-is": DM_IMAGE,
      },
    }))

    const eaemDivWrapper = document.createElement("div")
    eaemDivWrapper.classList.add(EAEM_BOUNDING_DIV_CSS)
    imageDiv.parentNode.appendChild(eaemDivWrapper)
    eaemDivWrapper.appendChild(imageDiv)
  }

  const isImageInViewport = component => {
    if (!component || component.length === 0) {
      return false
    }
    const LAZY_THRESHOLD = 100
    const el = component[0]
    const rect = el?.getBoundingClientRect()
    return (
      rect?.top >= 0 &&
      rect?.left >= 0 &&
      rect?.bottom - LAZY_THRESHOLD <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect?.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  const addListenerForImageVisibilityCheck = component => {
    const scrollListen = () => {
      if (!isImageInViewport(component)) {
        return
      }
      const lazyImageDiv = component.querySelectorAll(
        `[data-cmp-is="${EAEM_DM_IMAGE}"]`
      )
      if (lazyImageDiv.length > 0) {
        lazyImageDiv.forEach(childDiv => {
          setImageDiv(childDiv)
          showImage()
        })
      }
      document.removeEventListener("scroll", scrollListen)
    }
    document.addEventListener("scroll", scrollListen)
  }

  return (
    <div className="eaem-bounding-div">
      <div
        className="cmp-image"
        data-cmp-smartcroprendition={smartCropRendition}
        data-cmp-lazythreshold={lazyThreshold}
        data-cmp-src={src}
        data-cmp-widths={widths}
        data-title={alt}
        id={id}
        data-asset={fileReference}
      >
        {link ? (
          <a
            className="cmp-image__link"
            href={link}
            target={convertStringToBoolean(newTab) ? "_blank" : "_self"}
            rel="noreferrer"
          ></a>
        ) : (
          <>
            {smartCropRendition != "SmartCrop:Auto" ? (
              <img
                src={`${src}`}
                className="cmp-image__image transition"
                alt={alt}
                onLoad={handleImageLoad}
              />
            ) : (
              <DynamicImage
                imgUrl={src}
                alt={alt}
                className="cmp-image__image transition"
                uniqueId={`${id}`}
                itemProp="contentUrl"
                onLoad={handleImageLoad}
              />
            )}
            {areas.length ? (
              <map>
                {areas?.map((area, index) => (
                  <area
                    key={`key-${id}`}
                    shape={area.shape}
                    coords={area.coordinates}
                    href={area.href}
                    target={area.target}
                    alt={area.alt}
                    data-cmp-relcoords={area.relativeCoordinates}
                  />
                ))}
              </map>
            ) : null}
          </>
        )}
        {!convertStringToBoolean(displayPopupTitle) ? (
          <>
            <span className="cmp-image__title" itemProp="caption" />
            <div>{sanitizeInnerHtml(description)}</div>
          </>
        ) : null}
      </div>
    </div>
  )
}

export default Image
