import { sanitizeTextForAnalytics } from "@/utils/helper"

const handleSearchAnalyticsEvent = (
  status,
  message,
  internalLinkURL,
  eventType,
  redirectUrl,
  analyticsEventData,
  searchString,
  searchTotal,
  topSuggestedData,
  searchHistory,
  brandName
) => {
  if (!internalLinkURL) internalLinkURL = redirectUrl
  const eventInfo = getEventInfoObj(
    status,
    message,
    internalLinkURL,
    eventType,
    analyticsEventData,
    searchString,
    searchTotal,
    topSuggestedData,
    searchHistory,
    brandName
  )
  sessionStorage.setItem("searches", eventInfo.searchCount)
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  window.adobeDataLayer.push({
    event: "cmp:click",
    page,
    eventInfo,
  })
}

const getEventInfoObj = (
  status,
  message,
  internalLinkURL,
  eventType,
  analyticsEventData,
  searchString,
  searchTotal,
  topSuggestedData,
  searchHistory,
  brandName
) => {
  const currDate = new Date()
  const contentType =
    window.adobeDataLayer && window.adobeDataLayer.getState
      ? window.adobeDataLayer.getState("page.pageInfo.contentType") ||
        window.adobeDataLayer.getState("page.category.primaryCategory")
      : ""
  const pageName = contentType ? contentType.split("-")[0] : ""
  const autoSearch = !internalLinkURL ? true : false
  const searchSuggestedTerm = []
  if (!analyticsEventData.searchString) {
    analyticsEventData.searchString = searchString
  }
  if (!analyticsEventData.searchTotal) {
    analyticsEventData.searchTotal = searchTotal
  }
  if (!analyticsEventData.topSuggestedData) {
    analyticsEventData.topSuggestedData = topSuggestedData
  }
  const searchSuggestions =
    searchHistory && searchHistory.length
      ? searchHistory
      : analyticsEventData.topSuggestedData &&
        analyticsEventData.topSuggestedData.length
      ? analyticsEventData.topSuggestedData
      : []
  if (searchSuggestions?.length) {
    searchSuggestions.slice(0, 5).map(item => {
      searchSuggestedTerm.push(
        sanitizeTextForAnalytics(item.productName_s || item.keyWord)
      )
    })
  }

  const eventInfo = {
    eventName: `internal ${autoSearch ? "automatic " : ""}search`,
    eventAction: `internal ${autoSearch ? "automatic " : ""}search`,
    internalLinkName: "internal search icon",
    internalLinkType: "global header:navigation",
    internalLinkZoneName: "global header",
    clickInternalLinks: "true",
    eventType: "global header",
    internalLinkURL: internalLinkURL
      ? (internalLinkURL.indexOf("http") === -1 ? window.location.origin : "") +
        internalLinkURL
      : "n/a",
    internalLinkPosition: "global header",
    eventMsg: status !== "success" ? message?.toLowerCase() : "n/a",
    eventStatus: status || "n/a",

    searchTerm: analyticsEventData.searchString,
    searchContentType: pageName ? pageName + " page" : "n/a",
    searchSuggestedTerm: searchSuggestedTerm.join("|") || "n/a",
    searchCount: sessionStorage.getItem("searches")
      ? Number(sessionStorage.getItem("searches")) + 1
      : 1,
    searchResult: analyticsEventData.searchTotal,
    internalZoneName: "search:search box",
    pageURL: window.location.href,
    referrer: document.referrer || "n/a",
    previousPage: document.referrer || "n/a", // we can not get previous page name
    timeStamp:
      currDate.toISOString().split("T")[0] +
      " " +
      currDate.toTimeString().split(" ")[0],
    contentType: brandName?.toLowerCase(),
  }

  if (eventType?.type) {
    eventType.term = sanitizeTextForAnalytics(eventType.term)
    eventType.type = sanitizeTextForAnalytics(eventType.type)

    eventInfo.eventName = "search:" + eventType.type + ":search term"
    eventInfo.eventAction = "search:" + eventType.type + ":search term"
    eventInfo.internalLinkZoneName = "search:" + eventType.type
    if (eventType.type === "our top suggestions") {
      eventInfo.searchTerm = analyticsEventData.searchString?.toLowerCase()
      eventInfo.searchSuggestedTerm = eventType.term
    } else {
      eventInfo.searchTerm = eventType.term
    }
    eventInfo.internalLinkName = eventType.term
    eventInfo.internalZoneName = "search:" + eventType.type
  }
  analyticsEventData = {}
  return eventInfo
}

export { handleSearchAnalyticsEvent }
