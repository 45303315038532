import React from "react"

import useSearchi18n from "@/i18n/useSearchi18n"
import { getEventInfo } from "@/components/Default/Teaser/v1/analytics"

const PrintUtility = ({
  pageType = "",
  componentPosition = "",
  mainTitle = "",
}) => {
  const { print } = useSearchi18n({})

  const handlePrint = () => {
    window.print()
  }

  return (
    <div
      class="print"
      onClick={() => handlePrint()}
      tabIndex="0"
      data-gbh-data-layer={getEventInfo(
        pageType,
        componentPosition,
        mainTitle,
        "print",
        "",
        "teaser"
      )}
      data-gbh-clickable="true"
    >
      &nbsp;
      <span>{print}</span>
    </div>
  )
}

export default PrintUtility
