import React, { useEffect, useRef } from "react"
import DOMPurify from "isomorphic-dompurify"
import { useInView } from "react-intersection-observer"
import { loadLazyImage } from "@/utils/lazyLoadImage"
import { convertStringToBoolean } from "@/utils/helper"

const CustomImage = props => {
  const { data } = props
  const {
    linkURL,
    newTab,
    fileReference,
    imageLazyLoad,
    defaultRenditions: imageModifiersScene7,
    defaultImageURL,
    imageURLWithoutCrop,
    displayPopupTitle,
    imageComponentProperties: { alt, title },
    description,
    items,
  } = data

  const pictureRef = useRef(null)
  const sourcesRef = useRef([])
  const imageRef = useRef(null)
  const { ref: inViewRef, inView } = useInView({
    threshold: 0,
  })
  const placeholderImage =
    "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
  const lazyLoadValue = convertStringToBoolean(imageLazyLoad) ? "false" : "true"
  const finalSrcValue = `${
    defaultImageURL ? defaultImageURL : imageURLWithoutCrop
  }${imageModifiersScene7 ? "?" : ""}${
    imageModifiersScene7 ? imageModifiersScene7 : ""
  }`

  const content =
    convertStringToBoolean(imageLazyLoad) && fileReference ? (
      <picture ref={pictureRef} lazyload={lazyLoadValue}>
        {items.map((obj, index) => {
          return (
            <source
              ref={el => (sourcesRef.current[index] = el)}
              key={index}
              media={`(min-width: ${obj.deviceSize})`}
              alt={alt || "true"}
              srcSet={placeholderImage}
              data-srcset={`${obj.imageURL}${imageModifiersScene7 ? "?" : ""}${
                imageModifiersScene7 ? imageModifiersScene7 : ""
              }`}
            />
          )
        })}
        <img
          ref={imageRef}
          className="cmp-image__image"
          src={placeholderImage}
          data-src={finalSrcValue}
          alt={alt || "true"}
          style={{ width: "100%" }}
        />
      </picture>
    ) : (
      <picture lazyload={lazyLoadValue}>
        {items.map((obj, index) => {
          return (
            <source
              ref={el => (sourcesRef.current[index] = el)}
              key={index}
              media={`(min-width: ${obj.deviceSize})`}
              alt={alt || "true"}
              srcSet={`${obj.imageURL}${imageModifiersScene7 ? "?" : ""}${
                imageModifiersScene7 ? imageModifiersScene7 : ""
              }`}
            />
          )
        })}

        <img
          ref={imageRef}
          className="cmp-image__image"
          src={finalSrcValue}
          alt={alt || "true"}
          style={{ width: "100%" }}
        />
      </picture>
    )
  useEffect(() => {
    if (inView) {
      if (convertStringToBoolean(imageLazyLoad)) {
        loadLazyImage(sourcesRef, imageRef)
      }
    }
  }, [inView])

  return (
    <div
      className="cmp-image"
      itemScope
      itemType="https://schema.org/ImageObject"
      ref={inViewRef}
    >
      {linkURL ? (
        <a
          className="cmp-image__link"
          href={linkURL}
          target={convertStringToBoolean(newTab) ? "_blank" : "_self"}
          rel="noreferrer"
        >
          {content}
        </a>
      ) : (
        content
      )}
      {!convertStringToBoolean(displayPopupTitle) && (
        <span
          className="cmp-image__title"
          itemProp="caption"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(description),
          }}
        />
      )}
      {convertStringToBoolean(displayPopupTitle) && title && (
        <meta itemProp="caption" content={title} />
      )}
    </div>
  )
}

export default CustomImage
