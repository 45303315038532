export const addUseMyLocationAnalytics = (
  status = "",
  message = "",
  zipcode = ""
) => {
  const eventInfo = {
    eventAction: "find a pro:see local professionals:use my location",
    eventName: "find a pro:see local professionals:use my location",
    eventType: "navigation",
    eventMsg: zipcode || message || "n/a",
    eventStatus: status,
    internalLinkName: "use my location",
    internalLinkPosition: "find a pro",
    internalLinkType: `find a pro:${zipcode || "use my location"}`,
    internalLinkZoneName: "find a pro:see local professionals",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  window.adobeDataLayer.push({
    event: "cmp:click",
    page: page,
    eventInfo: eventInfo,
  })
}
export const getSubmitBtnAnalyticsData = (zipcode = "", pageUrl = "") => {
  const url = pageUrl?.replace(".html", "")
  return JSON.stringify({
    eventAction: "find a pro:see local professionals:submit",
    eventName: "find a pro:see local professionals:submit",
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "submit",
    internalLinkPosition: "find a pro",
    internalLinkType: `find my pro:${zipcode}`,
    internalLinkZoneName: "find a pro:see local professionals",
    internalLinkURL: `${url}?q=${zipcode}`,
    clickInternalLinks: "true",
  })
}
