import { useTranslation } from "next-i18next"

const useVirtuali18n = ({ authorData }) => {
  const { t } = useTranslation()
  const i18n = {
    designers: t("kf.findpro.label.designers"),
    specialtiesTxt: t("kf.findpro.label.specialtiesTxt"),
    contactTxt: t("kf.findpro.label.contactTxt"),
    availableCTA: t("kf.findpro.label.availableCTA"),
    availableInMonths: t("kf.findpro.label.availableInMonths"),
    findaProText: t("kf.findpro.label.findaProText"),
    home: t("kf.findpro.label.home"),
  }

  return i18n
}

export default useVirtuali18n
