import React, { Component } from "react"
/**
 * This will catch errors and show alternate view
 */
class ErrorBoundary extends Component {
  state = { error: false, errorMessage: "" }
  /**
   * This function handles rejection.
   * @param {Event} event
   */
  promiseRejectionHandler = event => {
    this.setState({ error: event.reason }) // eslint-disable-line no-invalid-this
  }
  /**
   * Rejection.
   * @param {Error} error
   * @return {Object}
   */
  static getDerivedStateFromError(error) {
    return { error: true, errorMessage: error.toString() }
  }
  /**
   * Adding listner function on mount to the event.
   */
  componentDidMount() {
    window.addEventListener("unhandledrejection", this.promiseRejectionHandler)
  }
  /**
   * Remove listner function on unmount.
   */
  componentWillUnmount() {
    window.removeEventListener(
      "unhandledrejection",
      this.promiseRejectionHandler
    )
  }
  /**
   * @return {React.JSX} Return appropriate component
   */
  render() {
    const { error, errorMessage } = this.state
    const { FallBackComponent, children, params } = this.props

    return error ? (
      <FallBackComponent {...{ error, errorMessage, params }} />
    ) : (
      <React.Fragment> {children}</React.Fragment>
    )
  }
}
export default ErrorBoundary
