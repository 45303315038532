import React from "react"
import useViewport from "@/hooks/useViewport"
import { CloseIcon } from "@/components/PartsViewer/v2/icon.js"

const PopModal = props => {
  const { position, setMessage, scale } = props
  const { isMobile } = useViewport()
  const { left, top, textWidth, textBounds, textHeight } = position
  const normalizeDynamic = isMobile ? 3 : 6
  const normalizeStatic = isMobile ? 4 : 7
  const normalizedfactor = normalizeStatic + normalizeDynamic / scale
  const popUpHeight = 114
  const popUpWidth = 237

  const wrapperTop =
    document?.querySelector(".react-transform-wrapper")?.getBoundingClientRect()
      ?.top || 0
  const textTop = textBounds?.top
  const gap = textTop - wrapperTop

  const leftStyle = left - popUpWidth / 2 + textWidth / 2
  const nTop = top - textHeight / 2 + normalizeStatic / 2
  const topStyle =
    gap > popUpHeight
      ? `${nTop - (popUpHeight + normalizedfactor)}px`
      : `${nTop + normalizedfactor}px`
  const scaleTransform = `scale(${1 / scale})`

  const scaleOrigin = gap > popUpHeight ? "bottom" : "top"

  const stylePosition = {
    left: `${leftStyle}px`,
    height: `${popUpHeight}px`,
    top: topStyle,
    transform: scaleTransform,
    transformOrigin: scaleOrigin,
  }

  return (
    <>
      <div className="popup-window" style={stylePosition}>
        <div className="header-popups">
          <div className="title-notfound">PART NOT FOUND</div>
          <button
            className="button-close"
            data-testid="part-not-found-close"
            onClick={() => setMessage(false)}
          >
            <CloseIcon />
          </button>
        </div>

        <div className="content-popups">
          Please contact our customer service team
        </div>
      </div>
    </>
  )
}

export default PopModal
