import _get from "lodash/get"
import {
  getCurrentSkuCouponDiscounts,
  sanitizeTextForAnalytics,
} from "@/utils/helper"

export const getProductInfo = (productinfo = {}) => {
  const { productData, sku, needsJSON, cart, config, description, persona } =
    productinfo
  const { productCouponCode, productCoupondiscount } =
    getCurrentSkuCouponDiscounts({ cart: cart, sku: sku })
  const colorDetails = _get(productData, "Color.SKU.Details_ss", [])
  const colorDetail =
    colorDetails.find(colorDetail => colorDetail.includes(sku)) ?? "|"
  const color = colorDetail.split("|")[0]
  const productInfo = {
    productInfo: {
      productBrand: _get(config, "general.siteName", "")?.toLowerCase(),
      description: (
        sanitizeTextForAnalytics(description) || "n/a"
      ).toLowerCase(),
      globalPromotionPrice: Number(
        _get(productData, `priceList.${persona}.discountedPrice_d`, 0)
      ).toFixed(2),
      productBasePrice: Number(
        _get(productData, `priceList.${persona}.price_d`, 0)
      ).toFixed(2),
      productCategory: (
        sanitizeTextForAnalytics(productData["Product_Category"]) || "n/a"
      ).toLowerCase(),
      productColor: (sanitizeTextForAnalytics(color) || "n/a").toLowerCase(),
      productName: (
        sanitizeTextForAnalytics(_get(productData, "productName_s", "")) ||
        "n/a"
      ).toLowerCase(),
      productFindingMethod: "n/a",
      productCouponCode: productCouponCode.join("|") || "n/a",
      productCoupondiscount: productCoupondiscount,
      frequency: "n/a",
      isRecommended: "n/a",
      isSendNow: "n/a",
      isSubscription: "n/a",
      quantity: "n/a",
      ratings: "n/a",
      numberOfReviews: "n/a",
    },
  }
  if (needsJSON) {
    return productInfo.productInfo
  }
  return productInfo
}

export const getDescriptionWithElipsis = (isMobile, description) => {
  const length = isMobile ? 41 : 45
  if (description.length >= length) {
    if (description.charAt(length) === " ") {
      description = description.slice(0, length) + "..."
    } else {
      description = description.slice(0, length).split(" ")
      description.pop()
      description = description.join(" ") + "..."
    }
  }
  return description
}

export const flags = persona => {
  return [
    "id",
    "slug_s",
    "productName_s",
    "masterSKU_s",
    "Product_Category",
    "ProductBrandName_s",
    "productImages.url_s",
    "productImages.url_ss",
    "SKUColorSwatchFilename_s",
    "SKUColorSwatchFilename_ss",
    "ProductDescriptionProductShort_s",
    `priceList.${persona}.price_d`,
    `priceList.${persona}.finalPrice_d`,
    `priceList.${persona}.discountedPrice_d`,
    `priceList.${persona}.saleOffer_s`,
    "Color.SKU.Details_ss",
    "Color.SKU.Details_s",
    "ProductWebFeatures_ss",
    "ProductWebFeatures_s",
    "ProductIsExclusive_s",
    "RegionReleaseforShipment_dt",
    "Color.TPR.Details_ss",
    "RegionBrandName_s",
    "metaTitle_s",
    "ctId_s",
    "CustomerFacingBrand_s",
  ]
}
