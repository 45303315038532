import React, { useState, useEffect, useRef } from "react"
import { getConfig } from "@/constants/config"
import { useTranslation } from "next-i18next"

import { sanitizeUrl, getShortenedUrlwithGeneral } from "@/utils/helper"
import _get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import styles from "@/components/Shared/ProductSocialShare/v1/socialShare.module.scss"

const ProductSocialShare = props => {
  const {
    id = "",
    url = "",
    componentProp: {
      assetUrl = "",
      url: compUrl = "",
      showModal: componentPropShowModal = false,
    } = {},
    disableInstagram = false,
    showModal = false,
    onClose = () => {},
    mailBody = "",
    mailSubject = "",
    isBundleProduct = "",
  } = props
  const [sites, setSites] = useState([])

  const { t } = useTranslation()
  const origin = window.location.origin
  const emailBtnRef = useRef(null)
  const copyBtnRef = useRef(null)
  const shareBoxRef = useRef(null)
  const socialShareCopyIconPath = useRef("")
  const socialShareEmailIconPath = useRef("")
  const [config, setConfig] = useState({})

  const brandName = useRef("")

  const { general = {}, socialMediaConfig: socialMediaConfigs = {} } = config

  const { hostUrl = "" } = general

  useEffect(() => {
    getConfig().then(async configData => {
      setConfig(configData)

      brandName.current = _get(configData, "general.siteName", "")

      socialShareCopyIconPath.current = _get(
        configData,
        "general.socialShareCopyIconPath",
        ""
      )
      socialShareEmailIconPath.current = _get(
        configData,
        "general.socialShareEmailIconPath",
        ""
      )
    })
  }, [])
  // get component social share link url
  /**
   *
   * @return  {string} the the url
   */
  const getLink = () => {
    const isFullUrl = /^https?:\/\//i
    if (url && isFullUrl.test(url)) {
      return url
    }
    if (url) {
      return `${origin}${url}`
    } else if (assetUrl && compUrl) {
      // teaser video component link all cases
      if (!isFullUrl.test(compUrl)) {
        const newcompUrl = getShortenedUrlwithGeneral(compUrl, general)
        return `${origin}${newcompUrl}`
      } else {
        return compUrl
      }
    } else if (assetUrl) {
      return assetUrl
    } else {
      return sanitizeUrl(window.location.href)
    }
  }
  const link = getLink()
  const brandNameCurrent = brandName.current
  const i18n = {
    email: t("kf.share.label.email"),
    emailSubject: t("kf.share.label.emailSubject"),
    copyLink: t("kf.share.label.copyLink"),
    copyLinkIndication: t("kf.share.label.copyLinkIndication"),
    yourSharedCartNumber: t("kf.share.label.yourSharedCartNumber", {
      cartNumber: props.sharedCartNum,
    }),

    linksCTA: siteKey =>
      t("kf.pdp.label.aria.socialLinksCTA", { brandNameCurrent, siteKey }),
    openNewTabText: t("kf.share.label.openNewTabText"),
  }

  const [copyIcon, setCopyIcon] = useState(false)

  useEffect(() => {
    if (socialMediaConfigs.myArrayList) {
      let siteList = []

      socialMediaConfigs?.myArrayList.forEach(socialMediaConfig => {
        if (!isEmpty(socialMediaConfig.map) && socialMediaConfig.map.name) {
          const site = {
            key: socialMediaConfig.map.name ?? "",
            icon: socialMediaConfig.map.iconPath ?? "",
          }

          const url = socialMediaConfig.map.redirectUrl ?? ""
          if (socialMediaConfig.map.name.toLowerCase().includes("twitter")) {
            siteList.push({
              ...site,
              url: encodeURI(url + link + `&text=${document.title}`),
            })
          } else if (
            socialMediaConfig.map.name.toLowerCase().includes("pinterest")
          ) {
            if (props.asset)
              siteList.push({
                ...site,
                url:
                  url +
                  `${origin}&media=${props.asset.url}&is_video=${props.asset.isVideo}$description=${props.asset.description}`,
              })
          } else {
            siteList.push({
              ...site,
              url: url + link,
            })
          }
        }
      })
      if (disableInstagram && siteList.length) {
        siteList = siteList.filter(
          site => !site.key.toLowerCase().includes("instagram")
        )
      }
      setSites(siteList)
    }
  }, [socialMediaConfigs])

  const emailLink = e => {
    e.stopPropagation()
    addDataLayer("email a friend", "n/a")
  }
  /**
   *
   * @param {string} e
   */
  function socialLinks(e) {
    if (e.target && e.target.hostname && e.target.href) {
      const target = e.target

      const nodeUrl = target.hostname.replace(/(^www\.)/, "")
      if (
        !window.excludUrl ||
        (window.excludUrl && !window.excludUrl.includes(nodeUrl))
      ) {
        e.stopPropagation()
        e.preventDefault()
        if (window.handleShowModal) window.handleShowModal(target.href, target)
      } else if (target.href) {
        window.open(target.href)
      }
      const key = target.siteKey || nodeUrl || target.hostname
      try {
        addDataLayer(key.split(".com")[0], target.href)
        // eslint-disable-next-line no-empty
      } catch (ee) {}
    }
  }

  useEffect(() => {
    copyBtnRef?.current?.addEventListener("click", copyLink, false)
    shareBoxRef?.current?.addEventListener("click", shareBox, false)
    emailBtnRef?.current?.addEventListener("click", emailLink, false)
    if (shareBoxRef?.current) {
      Array.from(
        shareBoxRef.current.getElementsByClassName("kf-social-links")
      )?.forEach(link => {
        link?.addEventListener("click", socialLinks, false)
      })
    }
    return () => {
      // garbage collection
      copyBtnRef?.current?.removeEventListener("click", copyLink, false)
      shareBoxRef?.current?.removeEventListener("click", shareBox, false)
      emailBtnRef?.current?.removeEventListener("click", emailLink, false)
      if (shareBoxRef?.current) {
        Array.from(
          shareBoxRef.current.getElementsByClassName("kf-social-links")
        )?.forEach(link => {
          link?.removeEventListener("click", socialLinks, false)
        })
      }
    }
  }, [sites, props.showModal])

  const { internallinkposition, internallinktype, internallinkzonename } =
    props.componentProp || {}

  const analyticData = props.analyticData || {
    internalLinkPosition: internallinkposition || "n/a",
    internalLinkType: internallinktype || "n/a",
    internalLinkZoneName: internallinkzonename || "n/a",
  }

  const shareRef = useRef(null)

  const handleClick = e => {
    const selELement = props.iframeId
      ? document
          .getElementById(props.iframeId)
          .contentDocument.getElementById(props.id)
      : document.getElementById(props.id)
    if (props.showModal && !selELement?.contains(e.target)) props.onClose()
    if (
      props.componentProp?.showModal &&
      window.shareSrcElement !== null &&
      window.shareSrcElement !== undefined &&
      !shareRef.current.contains(e.target)
    ) {
      const shareElems = [...document.getElementsByClassName("share-cmp")]
      shareElems.forEach(el => {
        el.style.display = "none"
      })
      window.shareSrcElement = null
    }
    return true
  }

  const handleClose = e => {
    if (e.keyCode === 27) {
      props.onClose()
      props.shareBtn.current.focus()
    }
  }

  useEffect(() => {
    const selELement = props.iframeId
      ? document.getElementById(props.iframeId).contentDocument
      : document
    if (props.showModal) {
      selELement.addEventListener("click", handleClick)
      selELement.addEventListener("keydown", handleClose)
      return () => {
        selELement.removeEventListener("click", handleClick)
        selELement.removeEventListener("keydown", handleClose)
      }
    }
    if (props.componentProp?.showModal && props.showModal) {
      document.addEventListener("click", handleClick)
    }
  }, [props.showModal])

  const copyLink = e => {
    e.stopPropagation()
    e.preventDefault()
    navigator.clipboard.writeText(link)
    setCopyIcon(true)
    setTimeout(() => {
      setCopyIcon(false)
    }, 3000)
    addDataLayer("copy link", link ?? "n/a")
  }

  const shareBox = e => {
    e.stopPropagation()
    e.preventDefault()
  }

  const addDataLayer = (siteKey, link) => {
    const {
      eventAction = "n/a",
      eventName = "n/a",
      eventType = "informative",
      eventMsg = "n/a",
      eventStatus = "n/a",
      internalLinkPosition = "n/a",
      internalLinkType = "n/a",
      internalLinkZoneName = "n/a",
      internalLinkURL,
      custom,
    } = analyticData

    let eventInfo = {
      eventAction,
      eventName,
      eventType,
      eventMsg,
      eventStatus,
      internalLinkName: siteKey,
      internalLinkPosition,
      internalLinkType,
      internalLinkZoneName,
      internalLinkURL: link || internalLinkURL || "n/a",
      clickInternalLinks:
        siteKey === "copy link" || siteKey === "email a friend"
          ? "true"
          : "false",
    }

    const { adobeDataLayer: { getState } = {} } = window
    const page = (getState && getState("page")) || {}
    const {
      pageInfo: { contentType },
      productInfo,
    } = page
    const isPdp = contentType === "pdp"

    if (isPdp) {
      eventInfo = {
        eventAction: "pdp:product details:share",
        eventName: "pdp:product details:share",
        eventType: "informative",
        eventMsg: "n/a",
        eventStatus: "n/a",
        internalLinkName: siteKey,
        internalLinkPosition: `pdp${isBundleProduct ? ":bundle" : ""}`,
        internalLinkType: `pdp:${internalLinkType.split(":")[1] || ""}`,
        internalLinkZoneName: `pdp:${internalLinkZoneName.split(":")[1] || ""}`,
        internalLinkURL: link || internalLinkURL || "n/a",
        clickInternalLinks: "true",
      }
    }

    if (window.dataLayerEventType && !isPdp) {
      eventInfo.internalLinkType = window.dataLayerEventType
    }

    if (window.dataLayerEventZoneName && !isPdp) {
      eventInfo.internalLinkZoneName = window.dataLayerEventZoneName
      if (eventAction === "n/a") {
        eventInfo.eventAction = window.dataLayerEventZoneName + ":share"
        eventInfo.eventName = window.dataLayerEventZoneName + ":share"
      }
    }

    const dataLayerObj = {
      event: "cmp:click",
      page,
      eventInfo,
    }

    if (isPdp) {
      dataLayerObj["productInfo"] = productInfo
    }

    let customProductInfo
    if (custom) {
      try {
        customProductInfo = JSON.parse(custom).productInfo
        dataLayerObj["productInfo"] = customProductInfo
      } catch (e) {
        console.error(e)
      }
    }
    window.adobeDataLayer.push(dataLayerObj)
  }
  const {
    linksCTA = () => {},
    openNewTabText = "",
    copyLinkIndication = "",
    copyLink: staticTextcopyLink = "",
    email = "",
  } = i18n

  return (
    <div className={styles.socialShareWrapper}>
      {showModal ?? componentPropShowModal ? (
        <div className="social-share" id={id} ref={shareRef} data-testid={id}>
          <div className="social-share__box" ref={shareBoxRef}>
            <div className="social-share__sites">
              <ul role="list">
                {sites.map(({ url = "", key = "", icon = "" }, i) => {
                  return (
                    <li key={i} role="listitem">
                      <a
                        id={`kf-social-links-${i}`}
                        rel="noreferrer"
                        href={url}
                        className="kf-social-links"
                        aria-label={`${linksCTA(key)} ${openNewTabText}`}
                        target="_blank"
                        data-link-type="social media"
                      >
                        <img
                          aria-hidden="true"
                          src={`${hostUrl}${icon}`}
                          alt={key}
                          className={`social-share__${key}`}
                        />
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="social-share__actions" role="list">
              <div role="listitem">
                <div
                  tabIndex="0"
                  role="button"
                  ref={copyBtnRef}
                  className="social-share__cta"
                >
                  <img
                    className="social-share__copy-link-icon"
                    aria-hidden
                    src={`${hostUrl}${socialShareCopyIconPath.current}`}
                    role="presentation"
                    alt=""
                  />

                  {copyIcon ? copyLinkIndication : staticTextcopyLink}
                </div>
              </div>
              <div role="listitem">
                <a
                  className="social-share__cta social-share__email"
                  aria-label={`${email} ${openNewTabText}`}
                  onKeyDown={() => onClose()}
                  ref={emailBtnRef}
                  href={sanitizeUrl(
                    `mailto:?body=${mailBody + link}${
                      mailSubject ? "&subject=" + mailSubject : ""
                    }`
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${hostUrl}${socialShareEmailIconPath.current}`}
                    role="presentation"
                    alt=""
                  />
                  {email}
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default ProductSocialShare
