import {
  pushAnalyticsObjToDataLayer,
  sanitizeTextForAnalytics,
} from "@/utils/helper"

export const partsAddToCartEvent = (analytics, productInfo) => {
  const { page = "n/a" } = analytics
  const linkUrl = window.location.href
  const event = "cmp:click"
  const eventInfo = {
    eventAction: page + ":modal:interactive parts diagram:add to cart",
    eventName: page + ":modal:interactive parts diagram:add to cart",
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "add to cart",
    internalLinkPosition: page,
    internalLinkType: page + ":add to cart",
    internalLinkZoneName: page + ":modal:interactive parts diagram",
    internalLinkURL: linkUrl,
    clickInternalLinks: "true",
  }
  const data = getAnalyticProductInfo(productInfo)
  data.pageInfo.contentType = page
  if (data.pageInfo.pageName.match("::")) {
    const pageName = data.pageInfo.pageName.replaceAll("::", `:${page}:`)
    data.pageName = pageName
    data.pageInfo.pageName = pageName
  }
  pushAnalyticsObjToDataLayer(eventInfo, event, data)
}
export const getAnalyticProductInfo = pdpInfo => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = getState && getState("page")
  if (page) {
    const { user, category, pageInfo } = page
    delete category["productLocalCategory"]
    delete user["encodedEmailId"]
    delete user["profileType"]
    delete pageInfo["thumbnailImageUrl"]
  }
  const {
    Name = "",
    quantity = 0,
    fileTitle = "",
    currentVarent: { imgUrl, label, centPrice, value },
    data: {
      ProductDescriptionProduct_s: productDescription,
      ProductCategory_s: productCategory,
    },
    pdpDetails: { pdp },
  } = pdpInfo

  const productInfo = {
    category: pdp?.data?.category1_s,
    defaultImageName: imgUrl,
    description: sanitizeTextForAnalytics(productDescription || Name),
    frequency: "n/a",
    globalDiscount: 0,
    globalOffer: "n/a",
    globalPromotionPrice: centPrice,
    isRecommended: "n/a",
    isSendNow: "n/a",
    isSubscription: "n/a",
    itemType: sanitizeTextForAnalytics(productCategory),
    numberOfReviews: "n/a",
    pdpType: "regular finished goods & service parts",
    priceState: "regular price",
    productBasePrice: centPrice,
    productBrand: "kohler",
    productCategory: sanitizeTextForAnalytics(productCategory),
    productCollectionsName: pdp?.productBrand,
    productColor: sanitizeTextForAnalytics(label),
    productCouponCode: "n/a",
    productCoupondiscount: 0,
    productFindingMethod: `pdp:${fileTitle}`,
    productID: value,
    productName: sanitizeTextForAnalytics(Name),
    productPartnerBuyNow: false,
    productRoom: page?.category?.room,
    productSalePrice: centPrice,
    productSaleable: true,
    productStatus: "in stock",
    productSuperSku: true,
    productTileName: sanitizeTextForAnalytics(Name),
    quantity,
    ratings: "n/a",
    waterSenseProduct: false,
  }

  page.productInfo = productInfo

  return page
}
