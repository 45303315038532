import React, { useEffect, useState } from "react"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import get from "lodash/get"
import { useDispatch, useSelector } from "react-redux"

import FindStoreUtilNavView from "@/components/FindStoreUtilNav/v1/FindStoreUtilNav.view"
import { getTranslations } from "@/components/FindStoreUtilNav/v1/localization"
import { getMyLocation, getMyLocationByUserIP } from "@/utils/helper"
import {
  getStoreDataById,
  getStoreDataByDistance,
  getShortenedUrl,
  getUtilityStoreDetails,
} from "@/utils/location"
import {
  setStoreAddress,
  setUtilityStoreDetails,
  setCityLocation,
  setMyStore,
} from "@/store/features/locationSlice"
import { locationState } from "@/store/features/locationSlice"

import { getConfig } from "@/constants/config"

const FindStoreUtilNav = ({ show, active, eventHandler, zipCodeContent }) => {
  const dispatch = useDispatch()
  const { utilityStoreDetails, utilityData, userCity, myStoreId } =
    useSelector(locationState)

  const initialState = {
    userCity: "",
    utilityLocation: "",
    utilityData: {},
    utilityStoreDetails: {},
    findStores: "",
    reverseGeo: "",
    myStoreId: localStorage.getItem("myStoreId") ?? 0,
    siteName: "",
    findStoreUrl: "",
    utilityStoreDetailsUrl: "",
  }
  const { t } = useTranslation("common")
  const router = useRouter()
  const [staticTexts, setStaticTexts] = useState({})
  const [state, setState] = useState(initialState)

  useEffect(() => {
    setStaticTexts(prevVal => {
      let translations = getTranslations(t)
      if (zipCodeContent && zipCodeContent.length) {
        translations = { ...translations, zipCodeContent }
      }
      return translations
    })

    setInitalState()
  }, [])

  // useEffect(() => {
  //   document.querySelector(
  //     ".gbh-nav__findStore a.gbh-nav__findStore--link"
  //   ).innerText = "staticTexts.findStore"
  // }, [userCity, staticTexts.findStore])

  useEffect(() => {
    if (state.myStoreId !== 0) getUtilityDataById()
    else if (state.userCity !== "") getUtilityDataByDistance()
  }, [state.utilityLocation, state.userCity])

  useEffect(() => {
    if (state.utilityStoreDetailsUrl != "") setUtilityStoreDetailsUrl()
  }, [state.utilityStoreDetailsUrl])

  const setInitalState = async () => {
    const config = await getConfig()
    getFindStoreUrl(config).then(url => {
      setState({
        ...state,
        findStores: get(config, "apiEndpoints.findStores", ""),
        reverseGeo: get(config, "apiEndpoints.reverseGeo", ""),
        siteName: get(config, "general.siteName", ""),
        utilityStoreDetailsUrl: get(config, "general.storeDetailsServletPath"),
        findStoreUrl: url,
      })
    })
  }
  const getFindStoreUrl = async config => {
    const findStoreUrl = await getShortenedUrl(
      get(config, "general.findStorePagePath", "")
    )
    return findStoreUrl
  }

  const setUtilityStoreDetailsUrl = async () => {
    const utilityStoreDetails = await getUtilityStoreDetails(state)
    dispatch(setUtilityStoreDetails(utilityStoreDetails))
    setState({
      ...state,
      utilityStoreDetails,
    })
  }
  const getUtilityDataByDistance = async () => {
    const utilityData = await getStoreDataByDistance(state)
    dispatch(setStoreAddress(utilityData))
    setState({
      ...state,
      utilityData,
    })
  }
  const getUtilityDataById = async () => {
    const utilityData = await getStoreDataById(state)
    dispatch(setStoreAddress(utilityData))
    setState({
      ...state,
      utilityData,
    })
  }

  const getUtilityGeolocation = (t = false) => {
    getMyLocation()
      .then(getLocation => {
        const { zipcode: utilityLocation = "", city: userCity = "" } =
          getLocation

        const details = {
          utilityLocation,
          userCity,
        }

        if (utilityLocation && userCity) {
          dispatch(setCityLocation(details))
          setState({
            ...state,
            ...details,
          })
        }
      })
      .catch(err => {
        getMyLocationByUserIP()
          .then(getLocation => {
            const { zipcode: utilityLocation = "", city: userCity = "" } =
              getLocation

            const details = {
              utilityLocation,
              userCity,
            }

            if (utilityLocation && userCity) {
              dispatch(setCityLocation(details))

              setState({
                ...state,
                ...details,
              })
            }
          })
          .catch(error => {
            console.log(error)
          })
      })
  }

  const handleUtilityStoreCheck = entity => {
    localStorage.setItem("myStoreId", entity)
    dispatch(setMyStore(entity))
    setState({
      ...state,
      myStoreId: entity,
    })
  }

  const redirect = zipCodeValue => {
    const path = `${state.findStoreUrl}?q=${zipCodeValue}&queryAspath=${state.findStoreUrl}`
    const asPath = `${state.findStoreUrl}?q=${zipCodeValue}`
    router.push(path, asPath)
  }
  const { findStoreUrl } = state
  if (!show) return null
  return (
    <FindStoreUtilNavView
      staticTexts={staticTexts}
      getUtilityGeolocation={getUtilityGeolocation}
      redirect={zipCodeValue => redirect(zipCodeValue)}
      redirectUrl={getShortenedUrl(findStoreUrl)}
      handleUtilityStoreCheck={entity => handleUtilityStoreCheck(entity)}
      active={active}
      utilityStoreDetails={utilityStoreDetails}
      utilityData={utilityData}
      userCity={userCity}
      myStoreId={myStoreId}
      eventHandler={eventHandler}
    />
  )
}

export default FindStoreUtilNav
