const newsLetterBody = (
  sBrandName,
  sEmailAddress,
  language = "en",
  additionalValues = null
) => {
  const aValues = {
    AnnSacks: {
      "Subscribe Ann Sacks": "TRUE",
      "Interiors Recent Source": "Website Footer Email Signup_Ann Sacks",
    },
    Sterling: {
      "Subscribe Sterling Plumbing": "TRUE",
      "Interiors Recent Source": "Website Footer Email Signup_Sterling",
    },
    Kohler: {
      "Subscribe Kohler Kitchen and Bath": "TRUE",
      "Subscribe Kohler KB Website Interaction": "TRUE",
      "Kitchen Bath Recent Source": "Website Footer Email Signup_Kohler",
    },
    "kohler-canada": {
      "Subscribe Kohler Canada": "TRUE",
      "Kitchen Bath Recent Source":
        "Website Footer Email Signup_Canada_English",
      LanguagePref: language,
    },
    Kallista: {
      "Subscribe Kallista": "TRUE",
      "Interiors Recent Source": "Website Footer Email Signup_Kallista",
    },
    Robern: {
      "Subscribe Sterling Plumbing": "TRUE",
      "Interiors Recent Source": "Website Footer Email Signup_Sterling",
    },
  }

  if (aValues[sBrandName]) {
    aValues[sBrandName]["EmailAddress"] = sEmailAddress
    aValues[sBrandName]["Date Updated"] = new Date().toISOString()
  }

  if (additionalValues) {
    aValues[sBrandName] = { ...aValues[sBrandName], ...additionalValues }
  }

  const body = {
    keys: {
      SubscriberKey: sEmailAddress,
    },
    values: aValues[sBrandName] || [],
  }

  return [body]
}

export default newsLetterBody
