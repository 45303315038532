import { useEffect, useState } from "react"
import { isMobile, isTablet } from "react-device-detect"

const Spacer = ({ data }) => {
  const {
    backgroundColor = "",
    desktopHeight = "",
    ipadHeight = "",
    mobileHeight = "",
  } = data

  const [style, setStyle] = useState({
    backgroundColor: backgroundColor,
    color: backgroundColor,
    height: desktopHeight,
  })

  useEffect(() => {
    if (isTablet) {
      setStyle(style => {
        return { ...style, height: ipadHeight }
      })
    }
    if (isMobile) {
      setStyle(style => {
        return { ...style, height: mobileHeight }
      })
    }
  }, [isTablet, isMobile])

  return (
    <div className="cmp-separator" aria-hidden="true">
      <hr className="cmp-separator__horizontal-rule" style={style} />
    </div>
  )
}

export default Spacer
