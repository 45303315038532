import React from "react"
import PropTypes from "prop-types"

import { sanitizeInnerHtml } from "@/utils/helper"
import Modal from "@/components/core/Modal/Modal"
import Button from "@/components/core/Button/Button"
import { zipCodeInfoModalContinueAnalytics } from "@/components/FindStore/v1/analytics"
import { NODE_NAMES } from "@/constants"

const ZipCodeInfoModal = props => {
  const {
    staticTexts: {
      infoModalTitle = "",
      messageZipcode = "",
      continue: continueCtaLabel = "",
    } = {},
    data: { ctaLabel = "", title = "", message = "" } = {},
    dataLayerPosition = "",
  } = props

  const handleModalClose = e => {
    if (props.data.ctaLink) {
      window.open(props.data.ctaLink)
    } else {
      props.handleModalClose()
      let label = ctaLabel ? ctaLabel : continueCtaLabel
      if (e?.target?.nodeName === NODE_NAMES.IMG) {
        label = "close"
      }
      zipCodeInfoModalContinueAnalytics(label, dataLayerPosition)
    }
  }
  return (
    <Modal
      showModal={props.show}
      className={props.className}
      onModalClose={handleModalClose}
      labelledBy={props.labelledBy}
    >
      <div id="taxware-down-title" className="taxware-down__title">
        {title ? title : infoModalTitle}
      </div>
      <div className="taxware-down__desc">
        {sanitizeInnerHtml(message ? message : messageZipcode)}
      </div>
      <Button
        tag="button"
        customClass="taxware-down__cta"
        role="button"
        onClick={handleModalClose}
        flexible
        label={ctaLabel ? ctaLabel : continueCtaLabel}
        useDefaultClass={true}
      />
    </Modal>
  )
}

Modal.propTypes = {
  labelledBy: PropTypes.string,
  data: PropTypes.object,
}

export default ZipCodeInfoModal
