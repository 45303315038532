import MiniCart from "@/components/Shared/MiniCart/v1/MiniCart"

const CheckoutUtilityNavHeader = ({ data, eventHandler }) => {
  return (
    <div>
      <div role="listitem" className="cart-indicator-widget__checkout">
        <MiniCart
          checkoutPage={data?.linkCart}
          eventHandler={eventHandler}
          source={"from desk"}
        />
      </div>
    </div>
  )
}

export default CheckoutUtilityNavHeader
