import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

const ToolTip = props => {
  const { children, text = "No text", placement = "left", show = true } = props
  return show ? (
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={<Tooltip className="bg-color">{text}</Tooltip>}
      delay={{ show: 0, hide: 0 }}
    >
      {children}
    </OverlayTrigger>
  ) : (
    children
  )
}

export default ToolTip
