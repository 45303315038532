import React, { useState, useEffect, memo } from "react"
import { isSafari } from "react-device-detect"
import { getCartLandingPagePath } from "@/utils/cart"
import cartIcon from "@/public/icons/cart-icon.svg"
import cartEmptyIcon from "@/public/icons/cart-icon-empty.svg"
import cartGray from "@/public/icons/cart-gray.svg"
import cartGrayFilledIcon from "@/public/icons/cart-filled.svg"
import Link from "next/link"
import useWindowResize from "@/hooks/useWindowResize"
import useWindowDimensions from "@/hooks/useWindowDimensions"
const MiniCart = ({
  eventHandler: {
    store: { cartCount, ariaLabel },
    callbacks: { onClick },
  },
  checkoutPage,
}) => {
  const [width] = useWindowResize()
  const getWidth =
    window.outerWidth === 0
      ? window.innerWidth
      : isSafari
      ? window.innerWidth
      : window.outerWidth
  const [isMobile, setIsMobile] = useState((getWidth || width) <= 1200)
  const [cartUrl, setCartUrl] = useState("")
  const cartGrayFilledIconV3 = "/icons/cart-empty.svg"
  useEffect(() => {
    !checkoutPage
      ? setIsMobile((getWidth && width) <= 1200)
      : setIsMobile(false)
  }, [getWidth, width, checkoutPage])
  useEffect(() => {
    getCartLandingPagePath().then(res => {
      setCartUrl(res)
    })
  }, [])
  const { winWidth } = useWindowDimensions()

  const themeChange =
    typeof window !== "undefined" &&
    winWidth <= 1200 &&
    (document.body.classList.contains("nav-light-theme") ||
      document.body.classList.contains("nav-dark-theme"))

  return (
    <div
      className={`cart-indicator ${
        cartCount > 0 && "cart-indicator__with-count"
      }`}
    >
      <Link
        href={cartUrl}
        aria-label={ariaLabel}
        role="link"
        className="gbh-data-layer"
        data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"global header","internalLinkZoneName":"global header","internalLinkURL":"${cartUrl}","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"cart icon","eventName":"cart icon","internalLinkType":"homepage:navigation","eventType":"global header","eventAction":"cart icon"}`}
      >
        {cartCount ? (
          <div
            className={`cart-indicator__count ${
              cartCount < 100 && "cart-indicator__count--center"
            }`}
          >
            <img
              src={isMobile ? cartGrayFilledIcon?.src : cartIcon?.src}
              alt="Cart"
            />{" "}
            <span>{cartCount}</span>
          </div>
        ) : themeChange ? (
          <img
            src={isMobile ? cartGrayFilledIconV3 : cartEmptyIcon.src}
            alt="Cart"
          />
        ) : (
          <img src={isMobile ? cartGray?.src : cartEmptyIcon.src} alt="Cart" />
        )}
      </Link>
    </div>
  )
}
const checkForRerender = (prevProps, nextProps) => {
  return (
    nextProps.eventHandler.store.cartCount ===
    prevProps.eventHandler.store.cartCount
  )
}
export default memo(MiniCart, checkForRerender)
