import _get from "lodash/get"
import {
  getCurrentSkuCouponDiscounts,
  sanitizeTextForAnalytics,
} from "@/utils/helper"

export const getProductInfo = (
  productData,
  sku,
  needsJSON = false,
  cart,
  config,
  description,
  persona
) => {
  const { productCouponCode, productCoupondiscount } =
    getCurrentSkuCouponDiscounts({ cart: cart, sku: sku })
  const colorDetails = _get(productData, "Color.SKU.Details_ss", [])
  const colorDetail =
    colorDetails.find(colorDetail => colorDetail.includes(sku)) ?? "|"
  const color = colorDetail.split("|")[0]
  const productInfo = {
    productInfo: {
      productBrand: _get(config, "general.siteName", "")?.toLowerCase(),
      description: (
        sanitizeTextForAnalytics(description) || "n/a"
      ).toLowerCase(),
      globalPromotionPrice: Number(
        _get(productData, `priceList.${persona}.discountedPrice_d`, 0)
      ).toFixed(2),
      productBasePrice: Number(
        _get(productData, `priceList.${persona}.price_d`, 0)
      ).toFixed(2),
      productCategory: (
        sanitizeTextForAnalytics(productData["Product_Category"]) || "n/a"
      ).toLowerCase(),
      productColor: (sanitizeTextForAnalytics(color) || "n/a").toLowerCase(),
      productName: (
        sanitizeTextForAnalytics(_get(productData, "productName_s", "")) ||
        "n/a"
      ).toLowerCase(),
      productFindingMethod: "n/a",
      productCouponCode: productCouponCode.join("|") || "n/a",
      productCoupondiscount: productCoupondiscount,
      frequency: "n/a",
      isRecommended: "n/a",
      isSendNow: "n/a",
      isSubscription: "n/a",
      quantity: "n/a",
      ratings: "n/a",
      numberOfReviews: "n/a",
    },
  }
  if (needsJSON) {
    return productInfo.productInfo
  }
  return productInfo
}
