import React, { useState } from "react"
import useViewport from "@/hooks/useViewport"
import RadioButton from "@/components/core/RadioButton/RadioButton"
import ToggleView from "@/components/PartsViewer/v2/GridAndListPanel/ToggleView"
import { sortOptions } from "@/components/PartsViewer/v2/utils.js"
import {
  SortByIcon,
  SortByDropDownIcon,
  IconClose,
} from "@/components/PartsViewer/v2/icon.js"

const SortDropdown = props => {
  const {
    partController,
    visibleData,
    title,
    sku,
    setlistView,
    listView,
    isListExtend,
  } = props
  const [sort, setSort] = useState("asc")
  const [sortDesc, setSortDesc] = useState("A-Z")
  const device = useViewport()
  const [isOpen, setIsOpen] = useState(false)
  const isDesktop = device.isLargeScreen || device.isExtraLargeScreen
  const toogleDropDown = () => {
    setIsOpen(prevOpen => !prevOpen)
  }
  const sortBy = (e, order) => {
    setIsOpen(false)
    partController.actions.filterProduct(order.key)
    partController.actions.fetchForCpiPromise(visibleData)
    setSortDesc(order.label)
    setSort(order.key)
  }

  return (
    <>
      <div className="drop-down">
        <button
          type="button"
          id="dropdown-basic"
          aria-expanded="false"
          data-testid="sort-dropdown"
          className={`sorting-btn btn btn-expand border-0 ${
            isListExtend ? "full-screen-view" : "small-screen-view"
          }`}
          onClick={toogleDropDown}
        >
          <span>{isDesktop ? `Sort By: ${sortDesc}` : "Sort"}</span>
          {isDesktop ? <SortByDropDownIcon /> : <SortByIcon />}

          {device.isSmallScreen ? (
            <ToggleView {...{ listView, setlistView, device, title, sku }} />
          ) : null}
        </button>
        {isOpen ? (
          <div
            className={`drop-down-menu border-0 ${
              device.isSmallScreen ? "mobile-dropdown" : ""
            }`}
          >
            {device.isSmallScreen ? (
              <>
                <div className="sort-div d-flex justify-content-between align-items-center">
                  <div className="sort-by-title">Sort </div>
                  <div className="close-icon">
                    <button onClick={toogleDropDown} className="close-menu">
                      <IconClose />
                    </button>
                  </div>
                </div>
              </>
            ) : null}
            {sortOptions.map(val => (
              <button
                key={val.key}
                data-testid={`sort-${val.key}`}
                className={`dropdown-item drop-link ${
                  sort === val.key ? "sort-by" : ""
                }`}
                onClick={event => sortBy(event, val)}
              >
                {device.isSmallScreen ? (
                  <div className="sort__radio-group">
                    <RadioButton
                      id={val.key}
                      name={val.key}
                      value={val.label}
                      checked={sort === val.key}
                    />
                  </div>
                ) : (
                  <>{val.label}</>
                )}
              </button>
            ))}
          </div>
        ) : null}
        {device.isSmallScreen && isOpen ? (
          <div className="mobile-overlay"></div>
        ) : null}
      </div>
    </>
  )
}

export default SortDropdown
