import {
  pushAnalyticsObjToDataLayer,
  sanitizeTextArticle,
} from "@/utils/helper"

export const addAnalyticsOnClick = (eventInfoKey, item, quadrantTitle = "") => {
  const internalLinkPosition = sanitizeTextArticle(quadrantTitle.toLowerCase())
  let eventInfo
  switch (eventInfoKey) {
    case "firstLink":
      const brandName = item?.brandNameOne?.toLowerCase() ?? ""
      eventInfo = {
        clickInternalLinks: `${item?.newTabOne ? "false" : "true"}`,
        internalLinkPosition: `${internalLinkPosition}`,
        internalLinkZoneName: "global footer",
        internalLinkURL: `${item?.brandLinkOne
          ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
          .join("")
          .replace(".html", "")}`,
        internalLinkName: `${brandName}`,
        eventName: `footer menu:${brandName}`,
        internalLinkType: "global footer:navigation",
        eventType: "footer submenu",
        eventAction: `footer menu:${brandName}`,
      }
      break

    case "linkThree":
      eventInfo = {
        clickInternalLinks: `${item?.newTabFour ? "false" : "true"}`,
        internalLinkPosition: `${internalLinkPosition}`,
        internalLinkZoneName: "global footer",
        internalLinkURL: `${item?.brandLinkFour
          ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
          .join("")
          .replace(".html", "")}`,
        internalLinkName: `${item?.brandNameFour?.toLowerCase()}`,
        eventName: `footer menu:${item?.brandNameFour?.toLowerCase()}`,
        internalLinkType: "global footer:navigation",
        eventType: "footer submenu",
        eventAction: `footer menu:${item?.brandNameFour?.toLowerCase()}`,
      }
      break
    case "oddEven":
      eventInfo = {
        clickInternalLinks: `${item?.newTabTwo ? "false" : "true"}`,
        internalLinkPosition: `${internalLinkPosition}`,
        internalLinkZoneName: "global footer",
        internalLinkURL: `${item?.brandLinkTwo
          ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
          .join("")
          .replace(".html", "")}`,
        internalLinkName: `${item?.brandNameTwo?.toLowerCase()}`,
        eventName: `footer menu:${item?.brandNameTwo?.toLowerCase()}`,
        internalLinkType: "global footer:navigation",
        eventType: "footer submenu",
        eventAction: `footer menu:${item?.brandNameTwo?.toLowerCase()}`,
      }
      break
    default:
      eventInfo = null
  }

  pushAnalyticsObjToDataLayer(eventInfo ?? null)
}
