/**
 * It is Quantity Selector
 *  @returns {number} the QuantitySelector
 */

import React, { useEffect, useState, useRef, useCallback } from "react"
import {
  pdpTypeFormat,
  sanitizeTextForAnalytics,
  getCartItemType,
} from "@/utils/helper"
import _get from "lodash/get"
import { getConfig } from "@/constants/config"
import debounce from "lodash.debounce"
/**
 * It returns updated Value
 * @param {props} props is the only param.
 * @return {number} the updated Value in the cart
 */
function QuantitySelector(props) {
  const {
    isPDPQuantitySelector,
    source,
    doNotRequiredAnalyticsData,
    productData,
    productInfoData,
  } = props
  const [value, setValue] = useState(props.value)
  const [focusControl, setFocusControl] = useState(null)
  const [siteName, setSiteName] = useState(null)
  const product =
    productData && (productData.name || productData.desc) ? productData : {}
  const minusButton = useRef(null)
  const addButton = useRef(null)
  const qtyInput = useRef(null)
  useEffect(() => {
    if (!props.loading && value !== props.value) {
      setValue(props.value)
    } else if (
      !props.loading &&
      value === props.value &&
      value !== 1 &&
      focusControl
    ) {
      focusControl.current.focus()
    }
  }, [props.value, props.loading])

  useEffect(() => {
    getConfig().then(config => {
      setSiteName(_get(config, "general.siteName", ""))
    })
  }, [])

  /**
   * @param {val} val The first number.
   * @param {action} action The second number.
   */
  const handleChange = (val, action) => {
    if (val === "" || parseInt(val) < 1000) {
      if (props.isAddToCart) {
        props.onLoading()
      }
      if (!action) {
        setFocusControl(qtyInput)
      } else {
        setFocusControl(action === "add" ? addButton : minusButton)
      }

      let fieldValue = val.toString().replace(/\D/, "")
      if (fieldValue.charAt(0) === "0") {
        fieldValue = fieldValue.length === 1 ? 0 : fieldValue.substring(1)
      }
      fieldValue = fieldValue === "" ? value : Number(fieldValue)
      // if (!window.oldValue && window.oldValue !== 0) {
      window.oldValue = Number(props.value)
      // }
      setValue(fieldValue)
      delayedChange(fieldValue, action)
    }
  }
  const handleAddAnalytics = (action, val) => {
    const { adobeDataLayer: { getState } = {} } = window
    const page = (getState && getState("page")) || {}
    if (source !== "cart-preview" && page?.cart?.item?.length) {
      // this is flow of cart page only
      let updatedSku
      let pdpTypeBundle
      page.cart.item.forEach(prod => {
        const productId = prod.productInfo?.productID?.toLowerCase()
        if (
          productId === product.customerFacingSKU?.toLowerCase() ||
          productId === product.sku?.toLowerCase() ||
          (props.productData?.isBundleParent && props.productData?.sku)
        ) {
          if (props.productData?.isBundleParent && props.productData?.sku) {
            updatedSku = props.productData?.sku
            pdpTypeBundle = "bundle"
          } else {
            const iQty = prod.productInfo.quantity
            prod.productInfo = {
              ...prod.productInfo,
              quantity: val,
              initialQuantity: iQty ?? Number(value),
            }
            prod.quantity = val
            updatedSku = productId
            pdpTypeBundle = prod.productInfo.pdpType
          }
        }
      })
      if (pdpTypeBundle === "bundle") {
        productInfoData.productInfo = {
          ...productInfoData.productInfo,
          quantity: val,
          initialQuantity:
            Number(productInfoData.productInfo?.quantity) ?? Number(value),
        }
        window.adobeDataLayer.push({
          event: "cmp:click",
          eventInfo: eventInfo(action),
          ...productInfoData,
          page,
        })
      } else {
        page.eventInfo = eventInfo(action)
        page.updatedSku = updatedSku
        window.pageObj = page
      }
      // wait till api is updating cart details
    } else if (source === "cart-preview" && page?.cart?.item?.length) {
      // This is flow of cart preview updates
      let customPI
      if (page?.cart?.item?.length) {
        // update this one too to get updated page state in analytics
        page.cart.item.forEach(prod => {
          const productId = prod.productInfo?.productID?.toLowerCase()
          if (
            productId === product.customerFacingSKU?.toLowerCase() ||
            productId === product.sku?.toLowerCase()
          ) {
            const iQty = prod.productInfo.quantity

            prod.productInfo = {
              ...prod.productInfo,
              quantity: val,
              initialQuantity: iQty ?? Number(value),
            }
            prod.quantity = val
            customPI = prod.productInfo
          }
        })
      }
      if (!doNotRequiredAnalyticsData) {
        window.adobeDataLayer.push({
          event: "cmp:click",
          eventInfo: eventInfo(action),
          productInfo: customPI ?? productInfo(val),
          page,
        })
      }
    }
    if (source !== "cart-preview" && page && !window?.pageObj?.updatedSku) {
      page.updatedSku = productData?.customerFacingSKU || "true"
      window.pageObj = page
    }
  }

  const productInfo = val => {
    const customerFacingSKUId = product.customerFacingSKU || product.sku

    const itemType = getCartItemType(product.category, product.businessUnitName)
    const discountPriceProduct = Number(product.discountPrice)
    return {
      productBrand: siteName?.toLowerCase(),
      description: sanitizeTextForAnalytics(product.desc) || "n/a",
      frequency: "n/a",
      globalPromotionPrice: discountPriceProduct
        ? discountPriceProduct
        : Number(product.unitPrice),
      isRecommended: "n/a",
      isSendNow: "n/a",
      isSubscription: "n/a",
      priceState:
        product.discountPercent > 0 || product.discountAmount > 0
          ? "percentOff|" + product.discountPercent
          : "regular price",
      productBasePrice: Number(product.unitPrice) || "n/a",
      productCategory: sanitizeTextForAnalytics(product.category) || "n/a",
      productColor: sanitizeTextForAnalytics(product.color) || "n/a",
      productCoupondiscount: "n/a",
      productID: customerFacingSKUId || "n/a",
      productName: sanitizeTextForAnalytics(product.name) || "n/a",
      productPartnerBuyNow: false,
      productRoom:
        props?.isBundleParent || productData?.pdpType === "bundle"
          ? sanitizeTextForAnalytics(productData.category)
          : sanitizeTextForAnalytics(
              product.productRoom ||
                (itemType === "service" ? itemType : "non-catalog")
            ) || "n/a",
      productSalePrice: Number(product.discountPrice)
        ? Number(product.discountPrice)
        : Number(product.unitPrice),
      productSaleable: product.productSaleable ?? "true",
      productStatus:
        product.availableQty >= val || product.stock >= val
          ? "in stock"
          : "out of stock",
      productSuperSku: product.superSku ? true : false,
      productTileName: sanitizeTextForAnalytics(product.name) || "n/a",
      quantity: val,
      initialQuantity: window.oldValue ?? Number(value),
      defaultImageName: product.image || "n/a",
      ratings: "n/a",
      numberOfReviews: "n/a",
      pdpType:
        props?.isBundleParent || productData?.pdpType === "bundle"
          ? "bundle"
          : pdpTypeFormat({
              businessUnitName: props.businessUnitName,
              ProductIsRetail: props.ProductIsRetail,
              discontinuedProductb: props.discontinuedProductb,
              ProductRequiredAccessoriesGroupsList:
                props.ProductRequiredAccessoriesGroupsList,
              superSku: props.superSku,
            }),
      productFindingMethod: "n/a",
      productCollectionsName:
        sanitizeTextForAnalytics(
          product.productCollectionsName || product.category
        ) || "n/a",
      itemType,
    }
  }
  const eventInfo = action => {
    return {
      eventAction: `cart preview:cart update:${action}${
        isPDPQuantitySelector ? " quantity" : ""
      }`,
      eventName: `cart preview:cart update:${action}${
        isPDPQuantitySelector ? " quantity" : ""
      }`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: "cart update",
      internalLinkPosition: `cart${
        props?.isBundleParent || productData?.pdpType === "bundle"
          ? ":bundle"
          : ""
      }`,
      internalLinkType: `cart:${
        action === "add"
          ? "addition"
          : action === "subtract"
          ? "removal"
          : "update"
      }`,
      internalLinkZoneName: "cart:preview",
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
    }
  }

  const delayedChange = useCallback(
    debounce((val, action) => {
      if (val) {
        if (!action) {
          const oldVal = window.oldValue ?? value
          if (val > oldVal) {
            action = "add"
          } else if (val < oldVal) {
            action = "subtract"
          }
        }
        handleAddAnalytics(action, val)
        props.onChange(parseInt(val), action)
      }
    }, 1000),
    [props.onChange]
  )
  /**
   * input value updates her
   */

  const handleBlur = () => {
    if (!value || parseInt(value) === 0) setValue(props.value)
  }

  return (
    <div
      className={`quantity-selector no-print ${
        props.size === "small" ? "small" : ""
      }`}
    >
      <button
        ref={minusButton}
        onClick={() => handleChange(parseInt(value) - 1, "subtract")}
        aria-disabled={props.loading || !value || value === 1}
        disabled={props.loading || !value || value === 1}
        data-testid={"decrement-button"}
      >
        <section className="plus">
          <div className="line-1 line"></div>
        </section>
      </button>
      <input
        ref={qtyInput}
        type="number"
        className="quantity-selector__count"
        value={parseInt(value)}
        onBlur={() => handleBlur()}
        onChange={e => handleChange(e.target.value)}
        disabled={props.loading}
        data-testid={"quantity-input"}
      />
      <button
        ref={addButton}
        onClick={() => handleChange(parseInt(value) + 1, "add")}
        aria-disabled={props.loading}
        disabled={props.loading}
        data-testid={"increment-button"}
      >
        <section className="plus">
          <div className="line-1 line"></div>
          <div className="line line-2"></div>
        </section>
      </button>
    </div>
  )
}

export default QuantitySelector
