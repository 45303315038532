export const getSrc = (
  themeChange,
  favoritesPage,
  iconFavoriteBlack,
  iconFavorite
) => {
  if (!themeChange) {
    return favoritesPage && favoritesPage.length > 0
      ? "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTVweCIgaGVpZ2h0PSIxNHB4IiB2aWV3Qm94PSIwIDAgMTUgMTQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+SWNvbi9XaXNobGlzdDwvdGl0bGU+CiAgICA8ZyBpZD0iQjEtQ29tcGFyZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikljb24vV2lzaGxpc3QiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAtOC4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTguMTQwNjA3MTQsOS42ODg0ODE5NyBMNy41MDEsMTAuMzM0IEw2Ljg2MTU5MzY5LDkuNjg4NDgxOTcgQzUuMjkyNDIzNTIsOC4xMDM4MzkzNCAyLjc0NjI0NDc5LDguMTAzODM5MzQgMS4xNzcwNzQ2Myw5LjY4ODQ4MTk3IEMtMC4zNDg3ODE5NTgsMTEuMjI5MzgzOSAtMC4zOTYyNTE5NTksMTMuNzE4NTgzNCAxLjA2NDMyMjM2LDE1LjI5NzA0NDIgTDEuMTcwNzE4NTIsMTUuNDA4NTcwNyBMNy41MDExMTM0NCwyMS44MDE1MDMzIEwxMy44MDg0ODM5LDE1LjQzMjE5OTggTDEzLjkxNTE4NzgsMTUuMzIxMTQwOCBDMTUuMzk1NTk2OCwxMy43MjE3MTI1IDE1LjM1Mzk3MDMsMTEuMjMyNDAxIDEzLjgyNTEyNjIsOS42ODg0ODE5NyBDMTIuMjU1OTU2LDguMTAzODM5MzQgOS43MDk3NzczMSw4LjEwMzgzOTM0IDguMTQwNjA3MTQsOS42ODg0ODE5NyBaIiBpZD0iUGF0aCI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
      : "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgd2lkdGg9IjE1cHgiIGhlaWdodD0iMTRweCIgdmlld0JveD0iMCAwIDE1IDE0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPg0KICAgIDx0aXRsZT5JY29uL1dpc2hsaXN0PC90aXRsZT4NCiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+DQogICAgICAgIDxnIGlkPSJCdXR0b24vSW1hZ2UtSWNvbnMvV2lzaGxpc3QtRW1wdHkvRGFyay1HcmF5LUJHIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTEuMDAwMDAwLCAtMTEuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+DQogICAgICAgICAgICA8ZyBpZD0iSWNvbi9XaXNobGlzdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTEuMDAwMDAwLCAzLjAwMDAwMCkiPg0KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik04LjE0MDYwNzE0LDkuNjg4NDgxOTcgTDcuNTAxLDEwLjMzNCBMNi44NjE1OTM2OSw5LjY4ODQ4MTk3IEM1LjI5MjQyMzUyLDguMTAzODM5MzQgMi43NDYyNDQ3OSw4LjEwMzgzOTM0IDEuMTc3MDc0NjMsOS42ODg0ODE5NyBDLTAuMzQ4NzgxOTU4LDExLjIyOTM4MzkgLTAuMzk2MjUxOTU5LDEzLjcxODU4MzQgMS4wNjQzMjIzNiwxNS4yOTcwNDQyIEwxLjE3MDcxODUyLDE1LjQwODU3MDcgTDcuNTAxMTEzNDQsMjEuODAxNTAzMyBMMTMuODA4NDgzOSwxNS40MzIxOTk4IEwxMy45MTUxODc4LDE1LjMyMTE0MDggQzE1LjM5NTU5NjgsMTMuNzIxNzEyNSAxNS4zNTM5NzAzLDExLjIzMjQwMSAxMy44MjUxMjYyLDkuNjg4NDgxOTcgQzEyLjI1NTk1Niw4LjEwMzgzOTM0IDkuNzA5Nzc3MzEsOC4xMDM4MzkzNCA4LjE0MDYwNzE0LDkuNjg4NDgxOTcgWiBNMTMuMTE0NTU4OSwxMC4zOTIxMTEzIEMxNC4yNjY2NjkyLDExLjU1NTU4MTYgMTQuMjk4MTAyMiwxMy40MzUzMTE1IDEzLjE4NzU0NTEsMTQuNjM1MjQ1MyBMMTMuMDkyNTIwMiwxNC43MzQxMDUzIEw3LjUwMSwyMC4zOCBMMS44ODc2NDE4OSwxNC43MTE0NzgzIEwxLjc5Mjk1NDA5LDE0LjYxMjE4MzEgQzAuNzAxOTMzNDQxLDEzLjQzMzAxMzYgMC43Mzc3ODAxMDEsMTEuNTUzMzExIDEuODg3NjQxODksMTAuMzkyMTExMyBDMy4wNjU1MDk0NSw5LjIwMjYyOTU4IDQuOTczMTU4ODcsOS4yMDI2Mjk1OCA2LjE1MTAyNjQyLDEwLjM5MjExMTMgTDcuNTAxMTAwNDIsMTEuNzU1NDk3NCBMOC44NTExNzQ0MSwxMC4zOTIxMTEzIEMxMC4wMjkwNDIsOS4yMDI2Mjk1OCAxMS45MzY2OTE0LDkuMjAyNjI5NTggMTMuMTE0NTU4OSwxMC4zOTIxMTEzIFoiIGlkPSJQYXRoIj48L3BhdGg+DQogICAgICAgICAgICA8L2c+DQogICAgICAgIDwvZz4NCiAgICA8L2c+DQo8L3N2Zz4="
  } else {
    return favoritesPage && favoritesPage.length > 0
      ? iconFavoriteBlack
      : iconFavorite
  }
}
