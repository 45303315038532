import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import _get from "lodash/get"
import qs from "qs"
import _isEmpty from "lodash/isEmpty"
import { useTranslation } from "next-i18next"

import useWindowResize from "@/hooks/useWindowResize"
import { apim } from "@/constants/api"
import { getConfig } from "@/constants/config"
import { getUserPersona, getPDPUrl, getShortenedUrl } from "@/utils/helper"
import { getProductInfo } from "@/components/productCard/v3/productCardHelper"
import { getPresetUrl } from "@/utils"
import {
  addAnalyticsEventForFavouriteAddRemove,
  shopRoomhandleProductClick,
} from "@/components/ShopTheRoomCard/analytics"

import {
  addProduct,
  removeItems,
  selectFavoritesState,
} from "@/store/features/favoritesSlice"
import { selectCartState } from "@/store/features/cartSlice"
import {
  selectAuthState,
  setAuthModalVisibility,
} from "@/store/features/authSlice"
import { selectGenericState, showToast } from "@/store/features/genericSlice"

import MissingImage from "@/public/images/image-missing.png"
import wishIcon from "@/public/icons/wish.svg"
import filledWishIcon from "@/public/icons/wishlist-gray-filled.svg"
import styles from "@/components/productCard/v3/index.module.scss"

const ProductCard = props => {
  const {
    authorData: { skewId = "", presetConfigs = "{}" },
    isDefaultProductCard = true,
    isFavoriteIcon = false,
    selectedProduct = "",
    hotspotClicked = false,
    toasterVar = false,
  } = props

  const { t } = useTranslation("common")
  const [width] = useWindowResize()
  const dispatch = useDispatch()
  const { toaster } = useSelector(selectGenericState)
  const { cart } = useSelector(selectCartState)
  const { favorites } = useSelector(selectFavoritesState)
  const { isAuth } = useSelector(selectAuthState)

  const [presetConfig] = useState(JSON.parse(presetConfigs))
  const myRef = useRef(null)
  const [favFilled, setFavFilled] = useState(false)
  const [productData, setState] = useState({
    isLoading: false,
    image: "",
    isFavorite: false,
  })
  useEffect(() => {
    if (selectedProduct === skewId) myRef?.current?.scrollIntoView()
  }, [selectedProduct, skewId, myRef, hotspotClicked])
  const [config, setConfig] = useState({})
  const [swatchUrl, setSwatchUrl] = useState("")
  const [loginFavouriteCallback, setLoginFavouriteCallback] = useState(null)
  const [toasterContent, setToasterContent] = useState({
    successMessage: "",
    unfavoriteSuccessMessage: "",
  })
  const [pdpUrl, setPdpUrl] = useState("")

  const staticTexts = {
    removeItem: t("kf.favorites.removeItem"),
    removeItemError: t("kf.favorites.removeItemError"),
    favSuccessMsg: t("kf.favorites.success"),
    favErrorMsg: t("kf.favorites.error"),
  }

  useEffect(() => {
    getConfig().then(config => {
      setConfig(config)
      const swatchUrl = _get(config, "general.swatchUrl", "")
      setSwatchUrl(swatchUrl)
    })
  }, [])

  useEffect(() => {
    if (skewId && !_isEmpty(config)) {
      const persona = getUserPersona()
      const brandName = _get(config, "general.siteName", "")
      const fl = [
        "id",
        "slug_s",
        "productName_s",
        "masterSKU_s",
        "Product_Category",
        "ProductBrandName_s",
        "productImages.url_s",
        "productImages.url_ss",
        "SKUColorSwatchFilename_s",
        "SKUColorSwatchFilename_ss",
        "ProductDescriptionProductShort_s",
        `priceList.${persona}.price_d`,
        `priceList.${persona}.finalPrice_d`,
        `priceList.${persona}.discountedPrice_d`,
        `priceList.${persona}.saleOffer_s`,
        "Color.SKU.Details_ss",
        "Color.SKU.Details_s",
        "ProductWebFeatures_ss",
        "ProductWebFeatures_s",
        "ProductIsExclusive_s",
        "RegionReleaseforShipment_dt",
        "Color.TPR.Details_ss",
        "RegionBrandName_s",
        "metaTitle_s",
        "ctId_s",
        "CustomerFacingBrand_s",
      ]
      setState({ ...productData, isLoading: true })
      try {
        const language = config?.internationalization?.language ?? "en"
        apim
          .get(`/search/plp`, {
            params: {
              fl: fl.join(","),
              q: "*:*",
              fq: [`sku_s:("${skewId}")`, `language_s:("${language}")`],
              collections: brandName.toLowerCase(),
              profilename: `profile_${brandName.toLowerCase()}_General`,
            },
            paramsSerializer: params => {
              return qs.stringify(params, {
                arrayFormat: "repeat",
                encode: false,
              })
            },
          })
          .then(res => {
            if (res && res.data) {
              const productData = _get(res.data, "response.docs[0]", {})
              let imageId = ""
              if (skewId.includes("-")) {
                const skewIdPart = skewId.split("-")
                const selectedVariant = skewIdPart.at(-1)
                const imageData = productData["Color.TPR.Details_ss"]
                  .find(str => str.includes(`-${selectedVariant}`))
                  .split("/")
                imageId = imageData[imageData.length - 1]
              }
              const favoriteItem = favorites?.lineItems?.find(
                favorite => favorite.variant.sku === skewId
              )
              const isFavorite = favoriteItem ? true : false
              setFavFilled(isFavorite)
              setState({
                ...productData,
                isLoading: false,
                imageId,
                currencySign: _get(
                  config,
                  "internationalization.currencySign",
                  ""
                ),
                isFavorite,
              })
            } else {
              // eslint-disable-next-line no-console
              console.error("Error occured while fetching product data")
            }
          })
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Error occured while fetching product data", err)
      }
    }
  }, [config, favorites, isAuth])

  useEffect(() => {
    if (loginFavouriteCallback && isAuth) {
      handleFavorite(loginFavouriteCallback)
    }
  }, [loginFavouriteCallback, isAuth])

  useEffect(() => {
    setToasterMessage()
  }, [toaster])

  const setToasterMessage = async () => {
    if (!_isEmpty(toaster?.content)) {
      const { successMessage, unfavoriteSuccessMessage } = toaster.content
      setToasterContent({
        successMessage: await getShortenedUrl(successMessage),
        unfavoriteSuccessMessage: await getShortenedUrl(
          unfavoriteSuccessMessage
        ),
      })
    }
  }

  const handleLogin = e => {
    e.preventDefault()
    e.stopPropagation()
    window.loginFavouriteCallback = () => {
      setLoginFavouriteCallback(e)
    }
    dispatch(setAuthModalVisibility({ show: true }))
  }

  const handleFavorite = e => {
    window.preActiveElement = e.target
    e.stopPropagation()
    e.preventDefault()
    if (productData.isFavorite) {
      const favProduct = favorites?.lineItems?.find(
        fav => fav.variant.sku === skewId
      )
      if (favProduct) {
        if (!isAuth) {
          handleLogin(e)
        } else {
          dispatch(removeItems({ ids: [favProduct.id] }))
            .unwrap()
            .then(() => {
              const productInfo = getProductInfo(
                productData,
                skewId,
                false,
                cart,
                config,
                description,
                persona
              )
              addAnalyticsEventForFavouriteAddRemove(false, productInfo)
              dispatch(
                showToast({
                  message:
                    toasterContent.unfavoriteSuccessMessage ||
                    staticTexts.removeItem,
                  isVisible: true,
                })
              )
            })
            .catch(err => {
              dispatch(
                showToast({
                  message: staticTexts.removeItemError,
                  isVisible: true,
                })
              )
            })
        }
      }
    } else {
      if (!isAuth) {
        handleLogin(e)
      } else {
        dispatch(addProduct(skewId))
          .unwrap()
          .then(() => {
            const productInfo = getProductInfo(
              productData,
              skewId,
              false,
              cart,
              config,
              description,
              persona
            )
            addAnalyticsEventForFavouriteAddRemove(true, productInfo)
            dispatch(
              showToast({
                message:
                  toasterContent.successMessage || staticTexts.favSuccessMsg,
                isVisible: true,
              })
            )
          })
          .catch(err => {
            dispatch(
              showToast({
                message: staticTexts.favErrorMsg,
                isVisible: true,
              })
            )
          })
      }
    }
  }

  const getDescriptionWithElipsis = (isMobile, description) => {
    const length = isMobile ? 41 : 45
    if (description.length >= length) {
      if (description.charAt(length) === " ") {
        description = description.slice(0, length) + "..."
      } else {
        description = description.slice(0, length).split(" ")
        description.pop()
        description = description.join(" ") + "..."
      }
    }
    return description
  }

  const productCategory = _get(productData, "Product_Category", "")
  const slug = _get(productData, "slug_s", "")
  const name = _get(productData, "productName_s", "").split(" ").slice(" ")[0]
  const persona = getUserPersona()
  const currencySign = productData.currencySign ?? ""
  const price = Number(
    _get(productData, `priceList.${persona}.price_d`, 0)
  ).toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  let description = _get(productData, "ProductDescriptionProductShort_s", "")

  const isMobile =
    typeof window !== "undefined"
      ? window.matchMedia("(max-width: 1038px)").matches
      : null
  description = getDescriptionWithElipsis(isMobile, description)
  const reginalBrandName = _get(productData, "RegionBrandName_s", "")
  const productBrand = _get(productData, "CustomerFacingBrand_s", "")
  const { imageId = "" } = productData

  useEffect(() => {
    if (!_isEmpty(productData) && slug) {
      getPDPUrl(productCategory, slug).then(url => setPdpUrl(url))
    }
  }, [productData, slug])

  if (productData.isLoading) {
    return (
      <div className="product-card-loader__container">
        <div className="filter__inner-container shimmer height-300" />
        <div className="filter__footer">
          <div className="products-per-page width-240 mt60 mb20">
            <p className="shimmer-loading__headpara shimmer"></p>
          </div>
          <div className="products-per-page width-100">
            <p className="shimmer-loading__para shimmer"></p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div ref={myRef} className={styles.productCardWrapper}>
      <div
        className={"inspired-selling-module__card-container"}
        style={{
          border:
            skewId === selectedProduct
              ? "3px solid var(--Gray-Gray300, #CDCDCD)"
              : "",
          boxShadow:
            skewId === selectedProduct
              ? "0px 0px 20px 0px rgba(0, 0, 0, 0.40)"
              : "",
          width: toasterVar ? "165px" : "100%",
        }}
      >
        <a
          onClick={() =>
            shopRoomhandleProductClick({
              productData: productData,
              sku: skewId,
              url: `${pdpUrl}${skewId ? "?skuId=" + skewId : ""}}`,
              cart: cart,
              config: config,
              description: description,
              persona: persona,
            })
          }
          href={`${pdpUrl}${skewId ? "?skuId=" + skewId : ""}`}
        >
          <div className="inspired-selling-module__card">
            <div className="image__container">
              <img
                className="product-card__image"
                alt=""
                src={
                  getPresetUrl(width, 0, swatchUrl, imageId, presetConfig) ??
                  MissingImage
                }
                onError={e => (e.target.src = MissingImage)}
              />
            </div>
            {isFavoriteIcon ? (
              <div className="product-tile__share">
                <button
                  id={`kf-add-to-fav-${skewId}`}
                  className="product-tile__share-icon"
                  tabIndex="0"
                  onClick={handleFavorite}
                >
                  <img
                    role="presentation"
                    src={favFilled ? filledWishIcon.src : wishIcon.src}
                    alt=""
                  />
                </button>
              </div>
            ) : null}
          </div>
          {isDefaultProductCard ? (
            <div className="card-text__container" role="presentation">
              <div className="card-details__container">
                <div className="card-details__card-title-container">
                  <p style={{ color: toaster ? "#494949" : "" }}>{name}</p>
                </div>
                {!isMobile ? (
                  <div className="card-details__card-price">
                    <p>{`${currencySign}${price}`}</p>
                  </div>
                ) : null}
              </div>
              <div className="card-details-descriprion__container">
                <p>{description}</p>
              </div>
              {isMobile && (
                <div className="card-details__card-price">
                  <p>{`${currencySign}${price}`}</p>
                </div>
              )}
            </div>
          ) : (
            <div
              className="shop-the-room-card-details"
              style={{ color: toasterVar ? "#494949" : "" }}
            >
              {!toasterVar && (
                <p
                  className="brand-name"
                  style={{ color: toasterVar ? "#494949" : "" }}
                >
                  {productBrand}
                </p>
              )}
              <p
                className="regional-brand-name"
                style={{ color: toasterVar ? "#494949" : "" }}
              >
                {reginalBrandName}
              </p>
              <p
                className="short-description"
                style={{
                  color: toasterVar ? "#494949" : "",
                  overflow: "hidden",
                  display: "-webkit-box",
                  webkitLineClamp: "2",
                  webkitBoxOrient: "vertical",
                }}
              >
                {description}
              </p>
              <p
                className="price"
                style={{ color: toasterVar ? "#494949" : "" }}
              >{`${currencySign}${price}`}</p>
            </div>
          )}
        </a>
      </div>
    </div>
  )
}

export default ProductCard
