import React, { useState } from "react"
import styles from "@/components/core/AutoSuggestion/v1/index.module.scss"

const AutoSuggestion = ({
  id,
  borderValue,
  placeholderLabel,
  prefix,
  suffix,
  optionList,
  ariaLabel,
  selectedValue,
  iconAnalyticsdata,
  handleSelect,
  handleChange,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const getInputVal = item => {
    handleChange(item)
    setIsOpen(true)
  }

  const getSuggestionVal = item => {
    handleSelect(item)
    setIsOpen(false)
  }

  document.addEventListener("click", function (e) {
    if (
      setIsOpen &&
      !document.getElementById(`${id}-wrapper`)?.contains(e.target)
    )
      setIsOpen(false)
  })

  return (
    <React.Fragment>
      <div className={styles.autoSuggetionWrapper}>
        <div className="auto-suggestion">
          <div
            className={`auto-suggestion__input-container ${
              borderValue === "bottom"
                ? "auto-suggestion__border-bottom"
                : "auto-suggestion__border-all"
            }`}
          >
            {prefix !== undefined && (
              <div className="auto-suggestion__input-field-prefix">
                {prefix}
              </div>
            )}
            <input
              type="text"
              className="auto-suggestion__input"
              placeholder={placeholderLabel}
              value={selectedValue}
              onChange={e => getInputVal(e.target.value)}
              aria-label={ariaLabel}
              onBlur={() => setIsOpen(false)}
            />
            {suffix !== undefined && (
              <div
                className={
                  "auto-suggestion__input-field-suffix " +
                  (iconAnalyticsdata ? "gbh-data-layer" : "")
                }
                data-gbh-data-layer={iconAnalyticsdata || null}
              >
                {suffix}
              </div>
            )}
          </div>
          <div
            className={`auto-suggestion__dropdown ${isOpen ? " active" : ""}`}
          >
            {optionList?.length > 0 && (
              <ul className="auto-suggestion__dropdown-items">
                {optionList.map((item, i) => (
                  <li
                    key={i}
                    onMouseDown={() => getSuggestionVal(item)}
                    className="auto-suggestion__dropdown-item"
                  >
                    <span className="auto-suggestion__dropdown-item-value">
                      {item}
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AutoSuggestion
