export const getTranslations = t => {
  return {
    thanks: t("kf.newsletter.label.thanks"),
    getReady: t("kf.newsletter.label.getReady"),
    signupSuccess: t(`kf.newsletter.label.signupSuccess`),
    signUp: t(`kf.newsletter.label.signUp`),
    getStarted: t("kf.newsletter.label.getStarted"),
    enterEmail: t("kf.newsletter.label.enterEmail"),
    validEmail: t("kf.newsletter.label.validEmail"),
    genError: t("kf.error.message.genericError"),
    alreadySubscribed: t("kf.newsletter.label.alreadySubscribed"),
  }
}
