import { useTranslation } from "next-i18next"

const useProductCardi18n = ({ componentProps }) => {
  const { t } = useTranslation()
  const i18n = {
    color: t("kf.productcard.color"),
    colors: t("kf.productcard.colors"),
    altText: t("kf.productCard.altText"),
    altTextForServiceImg: t("kf.productCard.altTextForServiceImg"),
    virtualService: t("kf.productCard.virtualService"),
    virtualTooltip: t("kf.productCard.virtualTooltip"),
    addToCart: t("kf.productCard.addToCart"),
    toolTipAlt: t("kf.productCard.toolTipAlt"),
    nearestService: t("kf.productCard.nearestService"),
    nearestInPerson: t("kf.productCard.nearestInPerson"),
    edit: t("kf.productCard.edit"),
    eligibleZipCodeSuccess: t("kf.productCard.eligibleZipCodeSuccess"),
    eligibleZipCodeError: t("kf.productCard.eligibleZipCodeError"),
    zipcode: t("kf.productCard.zipcode"),
    findaPro: t("kf.productCard.findaPro"),
    checkAvailability: t("kf.productCard.checkAvailability"),
    maxInventory: t("kf.productCard.maxInventory"),
    itemsAdded: t("kf.productCard.itemsAdded"),
    itemAdded: t("kf.productCard.itemAdded"),
    yourCart: t("kf.productCard.yourCart"),
    zipcodeEmptyError: t("kf.productCard.zipcodeEmptyError"),
    validZipcode: t("kf.productCard.validZipcode"),
    ariaLabel: {
      color: t("kf.productCard.aria.colors"),
      tooltip: t("kf.productCard.aria.tooltip"),
      editZip: t("kf.productCard.aria.label.editZip"),
      findPro: t("kf.productCard.aria.label.findPro"),
    },
    discountPercentage: t("kf.productCard.discountPercentage"),
    off: t("kf.productCard.off"),
    lfLabel: t("annsacks.pdpTrimCalculator.label.LF"),
    sfLabel: t("annsacks.pdpTrimCalculator.label.SF"),
    boxLabel: t("annsacks.pdpProductSummary.label.perBox"),
    recentlyViewedColor: t("kf.productcard.color"),
    recentlyViewedColors: t("kf.productcard.colors"),
  }

  return i18n
}

export default useProductCardi18n
