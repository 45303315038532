import { useCallback, useEffect, useRef, useState } from "react"
import _get from "lodash/get"
import _debounce from "lodash/debounce"
import { useDispatch } from "react-redux"

import { getConfig } from "@/constants/config"
import {
  getCompareData,
  getFavouraties,
  getPDPUrl,
  getShortenedUrl,
  getUserPersona,
  removeAndCleanLink,
  sanitizeUrl,
} from "@/utils/helper"
import { addToCompareProduct, removeFromCompareProduct } from "@/utils/product"
import useIsSsr from "@/hooks/useIsSsr"

import {
  profileBrandSearch,
  profileBrandTypeahead,
  profileServicePartsSearch,
} from "@/store/features/productListSlice"

import DynamicSearchView from "@/components/Default/DynamicSearch/v1/DynamicSearch.view"
import { handleSearchAnalyticsEvent } from "@/components/Default/DynamicSearch/v1/analytics"

const DynamicSearch = props => {
  const {
    presetConfigs = {},
    disableParts = false,
    enablePotraitTile = false,
  } = props

  const dispatch = useDispatch()
  const persona = getUserPersona()
  const isSsr = useIsSsr()

  const analyticsEventData = {}

  const currencySign = useRef("")
  const currencyCode = useRef("")
  const searchPagePath = useRef("")
  const knowledgeArticleUrl = useRef("")
  const domainListUrl = useRef("")
  const bodyRef = useRef(document.body)
  const htmlRef = useRef(document.getElementsByTagName("html")[0])

  const [showSearchBar, setShowSearchBar] = useState(false)
  const [selectedData, setSelectedData] = useState({})
  const [searchString, setSearchString] = useState("")
  const [searchHistory, setSearchHistory] = useState(
    JSON.parse(localStorage.getItem("keyWordList")) ?? []
  )
  const [brandName, setBrandName] = useState("")
  const [searchTotal, setSearchTotal] = useState(0)
  const [topSuggestedData, setTopSuggestedData] = useState("")
  const [isCTAClicked, setIsCTAClicked] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState("")
  const [category, setCategory] = useState("")
  const [tilesData, setTilesData] = useState([])
  const [productsLoaded, setProductsLoaded] = useState(false)
  const [xfLink, setXfLink] = useState("")
  const [promoLayout, setPromoLayout] = useState("one-column")
  const [isGlobalConfigBlank, setIsGlobalConfigBlank] = useState(true)
  const [fusionQueryId, setFusionQueryId] = useState("")

  useEffect(() => {
    if (showSearchBar) {
      setSearchHistory(JSON.parse(localStorage.getItem("keyWordList")) ?? [])
    }
  }, [showSearchBar])

  useEffect(() => {
    if (isGlobalConfigBlank) {
      getConfig()
        .then(async config => {
          let blankConfig = false
          if (!(config && Object.keys(config).length > 1)) {
            blankConfig = true
            setIsGlobalConfigBlank(true)
          }
          if (!blankConfig) {
            setIsGlobalConfigBlank(false)
            const vbrandName = _get(config, "general.siteName", "")
            if (vbrandName) {
              setBrandName(vbrandName)
            }
            currencySign.current = _get(
              config,
              "internationalization.currencySign",
              ""
            )
            currencyCode.current = _get(
              config,
              "internationalization.currencyCode",
              ""
            )
            knowledgeArticleUrl.current = await getShortenedUrl(
              _get(config, "general.knowledgeArticlePath", "")
            )
            searchPagePath.current = await getShortenedUrl(
              _get(config, "general.searchPagePath", "")
            )
            domainListUrl.current = _get(config, "general.domainMapping", "")
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [isSsr, isGlobalConfigBlank])

  useEffect(() => {
    if (searchTotal > 0 && selectedData.key) {
      getTilesData(selectedData)
    }
  }, [searchTotal, selectedData])

  useEffect(() => {
    if (brandName) {
      if (searchString.length >= 3) {
        getSearchResult(searchString)
      } else {
        setSearchTotal(0)
      }
      setProductsLoaded(false)
    }
  }, [searchString, brandName])

  const handleToggle = () => {
    setSearchString("")
    setSearchTotal(0)
    const classNames = ["searchbar-open", "scroll-lock"]
    classNames.forEach(className =>
      bodyRef?.current?.classList?.toggle(className)
    )
    classNames.forEach(className =>
      htmlRef?.current?.classList?.toggle(className)
    )
    setShowSearchBar(!showSearchBar)
  }

  const handleRedirect = (searchTerm, isAnalyticsClick, analyticsEventType) => {
    setProductsLoaded(false)
    setIsCTAClicked(true)
    if (redirectUrl) {
      isAnalyticsClick &&
        handleSearchAnalyticsEvent(
          "success",
          null,
          null,
          analyticsEventType,
          redirectUrl,
          analyticsEventData,
          searchString,
          searchTotal,
          topSuggestedData,
          searchHistory,
          brandName
        )
      window.location.href = redirectUrl
      setRedirectUrl("")
    } else {
      let history = JSON.parse(window.localStorage.getItem("keyWordList")) ?? []
      history = history.filter(
        item => item.keyWord.toLowerCase() !== searchTerm.toLowerCase()
      )
      history.unshift({ keyWord: searchTerm, id: Date.now() })
      window.localStorage.setItem("keyWordList", JSON.stringify(history))

      Promise.all([
        dispatch(
          profileBrandSearch([
            searchTerm.trim(),
            brandName.toLowerCase(),
            "sku_s, slug_s, url_s, variantList.sku_ss, variantList.sku_s, Product_Category",
            1,
            "",
          ])
        ),
        dispatch(
          profileServicePartsSearch([searchTerm.trim(), "sku_s,slug_s", 1])
        ),
      ])
        .then(async res => {
          const [
            {
              payload: brandSearchPayload = {},
              payload: {
                fusion: brandFusion = {},
                fusion: {
                  redirect: brandFusionRedirect = [],
                  exactMatch: brandFusionExactMatch = "",
                },
                response: {
                  numFound: brandResponseNumFound = 0,
                  docs: brandResponseDocs = [],
                },
              } = {},
            },
            {
              payload: servicePartsPayload = {},
              payload: {
                fusion: { exactMatch: partsFusionExactMatch = "" },
                response: {
                  numFound: partsResponseNumFound = 0,
                  docs: partsResponseDocs = [],
                },
              } = {},
            },
          ] = res
          if (brandSearchPayload && servicePartsPayload) {
            let redirectUrl = ""
            // redirect if redirect URL is configured in LW for search keyword
            if (brandFusion && brandFusionRedirect && brandFusionRedirect[0]) {
              redirectUrl = brandFusionRedirect[0]
              isAnalyticsClick &&
                handleSearchAnalyticsEvent(
                  "success",
                  null,
                  redirectUrl,
                  analyticsEventType,
                  redirectUrl,
                  analyticsEventData,
                  searchString,
                  searchTotal,
                  topSuggestedData,
                  searchHistory,
                  brandName
                )
              window.location.href = redirectUrl
            } // get PDP / article URL if only 1 result
            else if (brandResponseNumFound === 1 && brandFusionExactMatch) {
              const d = brandResponseDocs[0]
              if (d.slug_s) {
                const param = d.sku_s ? `?skuId=${d.sku_s}` : ""
                redirectUrl =
                  (await getPDPUrl(d.Product_Category, d.slug_s)) + param
              } else if (d.url_s) {
                redirectUrl = d.url_s
              }
              isAnalyticsClick &&
                handleSearchAnalyticsEvent(
                  "success",
                  null,
                  redirectUrl,
                  analyticsEventType,
                  redirectUrl,
                  analyticsEventData,
                  searchString,
                  searchTotal,
                  topSuggestedData,
                  searchHistory,
                  brandName
                )
              window.location.href = redirectUrl
            }
            // get parts PDP URL if only 1 result
            else if (partsResponseNumFound === 1 && partsFusionExactMatch) {
              const d = partsResponseDocs[0]
              if (d.slug_s) {
                const param = d.sku_s ? `&skuId=${d.sku_s}` : ""
                redirectUrl = (await getPDPUrl(null, d.slug_s)) + param
              } else if (d.url_s) {
                redirectUrl = d.url_s
              }
              isAnalyticsClick &&
                handleSearchAnalyticsEvent(
                  "success",
                  null,
                  redirectUrl,
                  analyticsEventType,
                  redirectUrl,
                  analyticsEventData,
                  searchString,
                  searchTotal,
                  topSuggestedData,
                  searchHistory,
                  brandName
                )
              window.location.href = redirectUrl
            } else {
              redirectUrl = sanitizeUrl(
                window.location.origin +
                  removeAndCleanLink(searchPagePath.current) +
                  `?keyword=${searchTerm.trim()}`
              )
              isAnalyticsClick &&
                handleSearchAnalyticsEvent(
                  "success",
                  null,
                  redirectUrl,
                  analyticsEventType,
                  redirectUrl,
                  analyticsEventData,
                  searchString,
                  searchTotal,
                  topSuggestedData,
                  searchHistory,
                  brandName
                )
              window.location.href = redirectUrl
            }
          }
          // setProductsLoaded(true)
        })
        .catch(err => {
          console.log("Error occured while fetching Search data", err)
          isAnalyticsClick &&
            handleSearchAnalyticsEvent(
              "failure",
              "error occured while fetching search data",
              null,
              analyticsEventType,
              redirectUrl,
              analyticsEventData,
              searchString,
              searchTotal,
              topSuggestedData,
              searchHistory,
              brandName
            )
          setProductsLoaded(true)
        })
    }
  }

  const handleChange = searchTerm => {
    setProductsLoaded(false)
    setSearchString(searchTerm.trim())
  }

  const handleDelete = id => {
    let history = JSON.parse(window.localStorage.getItem("keyWordList")) ?? []
    history = history.filter(item => item.id !== id)
    window.localStorage.setItem("keyWordList", JSON.stringify(history))
    setSearchHistory(history)
  }

  const handleClearAll = () => {
    localStorage.removeItem("keyWordList")
    setSearchHistory([])
  }

  const getSearchResult = useCallback(
    _debounce(searchString => getData(searchString), 2000),
    [brandName]
  )

  const getData = searchStr => {
    if (redirectUrl) setRedirectUrl("")
    if (!isCTAClicked && searchStr.length >= 3) {
      Promise.all([
        dispatch(
          profileBrandSearch([
            searchStr.trim(),
            brandName.toLowerCase(),
            "sku_s, slug_s, url_s, variantList.sku_ss, variantList.sku_s, Product_Category",
            1,
            "",
          ])
        ),
        dispatch(
          profileServicePartsSearch([searchStr.trim(), "sku_s,slug_s", 1])
        ),
        dispatch(
          profileBrandTypeahead([searchStr.trim(), brandName.toLowerCase()])
        ),
      ])
        .then(async res => {
          const [
            {
              payload: brandSearchPayload = {},
              payload: {
                fusion: brandFusion = {},
                fusion: {
                  redirect: brandFusionRedirect = [],
                  exactMatch: brandFusionExactMatch = "",
                  fusionQueryId: brandFusionQueryId = "",
                },
                response: {
                  numFound: brandResponseNumFound = 0,
                  docs: brandResponseDocs = [],
                },
              } = {},
            },
            {
              payload: servicePartsPayload = {},
              payload: {
                fusion: {
                  exactMatch: partsFusionExactMatch = "",
                  fusionQueryId: partsFusionQueryId = "",
                },
                response: {
                  numFound: partsResponseNumFound = 0,
                  docs: partsResponseDocs = [],
                },
              } = {},
            },
            { payload: brandTypeaheadPayload = {} },
          ] = res
          if (
            brandSearchPayload &&
            servicePartsPayload &&
            brandTypeaheadPayload
          ) {
            let redirectUrl = ""
            // redirect if redirect URL is configured in LW for search keyword
            if (brandFusion && brandFusionRedirect && brandFusionRedirect[0]) {
              handleSearchAnalyticsEvent(
                "success",
                null,
                brandFusionRedirect[0],
                null,
                redirectUrl,
                analyticsEventData,
                searchString,
                searchTotal,
                topSuggestedData,
                searchHistory,
                brandName
              )
              window.location.href = brandFusionRedirect[0]
            }
            // get PDP / article URL if only 1 result
            else if (brandResponseNumFound === 1 && brandFusionExactMatch) {
              const d = brandResponseDocs[0]
              if (d.slug_s) {
                const param = d.sku_s ? `?skuId=${d.sku_s}` : ""
                redirectUrl =
                  (await getPDPUrl(d.Product_Category, d.slug_s)) + param
              } else if (d.url_s) {
                redirectUrl = d.url_s
              }
            }
            // get parts PDP URL if only 1 result
            else if (partsResponseNumFound === 1 && partsFusionExactMatch) {
              const d = partsResponseDocs[0]
              if (d.slug_s) {
                const param = d.sku_s ? `&skuId=${d.sku_s}` : ""
                redirectUrl = (await getPDPUrl(null, d.slug_s)) + param
              }
            }

            if (!brandFusionRedirect.length) {
              let groups = [
                {
                  key: "products",
                  fq: 'Products:("ct")',
                  count: _get(
                    brandSearchPayload,
                    "facet_counts.facet_fields.Products[1]",
                    0
                  ),
                },
                {
                  key: "parts",
                  count: _get(servicePartsPayload, "response.numFound", 0),
                },
                {
                  key: "technical",
                  fq: 'Technical:("ct")',
                  count: _get(
                    brandSearchPayload,
                    "facet_counts.facet_fields.Technical[1]",
                    0
                  ),
                },
                {
                  key: "inspiration",
                  fq: 'Inspiration:("Inspiration")',
                  count: _get(
                    brandSearchPayload,
                    "facet_counts.facet_fields.Inspiration[1]",
                    0
                  ),
                },
                {
                  key: "resource",
                  fq: 'Resource:("Resource")',
                  count: _get(
                    brandSearchPayload,
                    "facet_counts.facet_fields.Resource[1]",
                    0
                  ),
                },
                {
                  key: "help",
                  fq: 'Help:("sf")',
                  count: _get(
                    brandSearchPayload,
                    "facet_counts.facet_fields.Help[1]",
                    0
                  ),
                },
              ]
              if (disableParts) {
                groups = groups.filter(arr => {
                  return arr.key !== "parts"
                })
              }

              // Calculating the total results
              let total = 0
              const selected = groups.find(group => group.count > 0)
              if (brandFusionExactMatch || partsFusionExactMatch) {
                total =
                  selected.key === "parts"
                    ? partsResponseNumFound
                    : brandResponseNumFound
              } else {
                groups.forEach(group => (total += group.count))
              }

              let sFusionQueryId = brandFusionQueryId ?? ""
              sFusionQueryId = sFusionQueryId ?? partsFusionQueryId

              analyticsEventData.searchTotal = total
              analyticsEventData.redirectUrl = redirectUrl
              analyticsEventData.topSuggestedData = _get(
                brandTypeaheadPayload,
                "response.docs",
                []
              )
              analyticsEventData.selectedData = selected
              analyticsEventData.searchString = searchStr.trim()
              setSearchTotal(total)
              setRedirectUrl(redirectUrl)
              setTopSuggestedData(
                _get(brandTypeaheadPayload, "response.docs", [])
              )
              setFusionQueryId(sFusionQueryId)
              handleSearchAnalyticsEvent(
                "success",
                null,
                null,
                null,
                redirectUrl,
                analyticsEventData,
                searchString,
                searchTotal,
                topSuggestedData,
                searchHistory,
                brandName
              )
              setSelectedData(selected)
            }
          }
        })
        .catch(err => {
          setProductsLoaded(true)
          console.error("Error occured while fetching Search data", err)
        })
    } else {
      setSearchTotal(0)
      setCategory("")
      setTilesData([])
      setRedirectUrl("")
      setTopSuggestedData([])
    }
  }

  const getTilesData = selected => {
    const fl = [
      "id",
      "sku_s",
      "slug_s",
      "metaTitle_s",
      "productName_s",
      "masterSKU_s",
      "masterCustomerFacingSKU_s",
      "Product_Category",
      "ProductBrandName_s",
      "productImages.url_s",
      "productImages.url_ss",
      "displayPriceStartingAt_s",
      "SKUColorSwatchFilename_s",
      "SKUColorSwatchFilename_ss",
      "ProductDescriptionProductShort_s",
      "url_s",
      "urlName_s",
      "title_s",
      "pageTitle_s",
      "eyeBrowText_s",
      "templateType_s",
      "thumbNailImage_s",
      "searchDescription_s",
      "layoutItems.Article_Type__c.value_s",
      "layoutItems.KM_Article_Content__c.value_s",
      "layoutItems.KBNA_3C_Image_Video__c.label_s",
      "layoutItems.KBNA_3C_Image_Video__c.value_s",
      `priceList.${persona}.price_d`,
      `priceList.${persona}.finalPrice_d`,
      `priceList.${persona}.discountedPrice_d`,
      `priceList.${persona}.saleOffer_s`,
      `priceStartingAt.${persona}_d`,
      `discountedPriceStartingAt.${persona}_d`,
      "SKU.Details_ss",
      "Color.SKU.Details_ss",
      "Color.SKU.Details_s",
      `Color.${persona}.Details_ss`,
      "ProductIsExclusive_s",
      "RegionReleaseforShipment_dt",
      "productImages.labelWithUrl_ss",
      "productImages.labelWithUrl_s",
      "variantList.sku_ss",
      "variantList.sku_s",
      "RegionOnBackOrder_s",
      "RegionBrandName_s",
      "ctId_s",
      "primaryBundleSKU_s",
    ]
    setXfLink("")
    setPromoLayout("one-column")
    setCategory("")
    setTilesData([])

    dispatch(
      selected.key === "parts"
        ? profileServicePartsSearch([searchString.trim(), fl.join(","), 6])
        : profileBrandSearch([
            searchString.trim(),
            brandName.toLowerCase(),
            fl.join(","),
            6,
            selected.fq,
          ])
    )
      .then(res => {
        if (res.payload && res.payload.response?.docs) {
          setCategory(selected.key)
          setTilesData(res.payload.response?.docs)
          if (
            res.payload.fusion &&
            res.payload.fusion.experiment_fragments &&
            res.payload.fusion.experiment_fragments !== undefined
          ) {
            setPromoLayout(
              res.payload.fusion?.experiment_fragments[0]?.promoLayout_s ??
                "one-column"
            )
            setXfLink(res.payload.fusion?.experiment_fragments[0]?.url_s ?? "")
          }
        }

        setProductsLoaded(true)
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <DynamicSearchView
      xfLink={xfLink}
      promoLayout={promoLayout}
      searchTotal={searchTotal}
      searchString={searchString}
      showSearchBar={showSearchBar}
      onToggle={handleToggle}
      onRedirect={handleRedirect}
      onChange={handleChange}
      onDelete={handleDelete}
      onClearAll={handleClearAll}
      category={category}
      tilesData={tilesData}
      topSuggestedData={topSuggestedData}
      searchHistory={searchHistory}
      comparedProducts={getCompareData()}
      removeFromCompare={removeFromCompareProduct}
      addToCompare={addToCompareProduct}
      currencySign={currencySign.current}
      brand={brandName}
      favorites={getFavouraties()?.lineItems}
      presetConfigs={presetConfigs}
      knowledgeArticleUrl={knowledgeArticleUrl.current}
      fusionQueryId={fusionQueryId}
      parent="Dynamic Search"
      enablePotraitTile={enablePotraitTile}
      domainListUrl={domainListUrl.current}
      productsLoaded={productsLoaded}
    />
  )
}

export default DynamicSearch
