export const isImgUrlValid = url => {
  try {
    const img = new Image()
    img.src = url
    return new Promise(resolve => {
      img.onerror = () => resolve(false)
      img.onload = () => resolve(true)
    })
  } catch (err) {
    throw new Error("Invalid Image")
  }
}
