import dynamicImport from "next/dynamic"
import { createContext } from "react"

const DummyComponent = () => <div />

const ComponentContext = createContext([
  {
    Component: DummyComponent,
    componentProps: {},
  },
])

export const DynamicShopTheRoomList = dynamicImport(() =>
  import("@/components/ShopTheRoomList/v1/ShopTheRoomList")
)

export const DynamicFilter = dynamicImport(() =>
  import("@/components/ProductList/v2/Filter")
)

export const DynamicBreadcrumb = dynamicImport(() =>
  import("@/components/Default/BreadCrumb")
)

export const DynamicShopTheRoomCard = dynamicImport(() =>
  import("@/components/ShopTheRoomCard/v1/ShopTheRoomCard")
)

export const DynamicStickyNavHeader = dynamicImport(() =>
  import(
    "@/components/ProductList/v2/ProductListView/StickyNav/stickyNavHeader"
  )
)

export default ComponentContext
