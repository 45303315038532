import React from "react"
const CountryUtilNavLink = props => {
  const {
    languageLink,
    language,
    countryName,
    languageAbbreviation,
    isMobile,
    datalayerPageName,
  } = props

  return (
    <a
      href={languageLink}
      aria-label={`${countryName} ${language}`}
      className={`gbh-data-layer lang ${isMobile && "mobile"}`}
      data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"languageselector","internalLinkZoneName":"${datalayerPageName}${
        datalayerPageName ? ":" : ""
      }global header","internalLinkURL":"${languageLink}","internalLinkName":"language select:${
        isMobile ? language.toLowerCase() : languageAbbreviation.toLowerCase()
      }","eventName":"language selector","internalLinkType":"homepage:navigation","eventType":"language selector","eventAction":"language selector"}`}
      rel="noreferrer"
    >
      {isMobile ? language : languageAbbreviation}
    </a>
  )
}

export default CountryUtilNavLink
