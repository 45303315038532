import React from "react"
import CountryUtilNavLink from "@/components/Default/CountryUtilNav/v1/CountryUtilNavLink"
const CountryUtilNavLinks = props => {
  const {
    languageLink,
    language,
    countryName,
    languageAbbreviation,
    datalayerPageName,
  } = props

  return (
    <>
      <li>
        <CountryUtilNavLink
          languageLink={languageLink}
          language={language}
          countryName={countryName}
          languageAbbreviation={languageAbbreviation}
          isMobile={false}
          datalayerPageName={datalayerPageName}
        />
        <CountryUtilNavLink
          languageLink={languageLink}
          language={language}
          countryName={countryName}
          languageAbbreviation={languageAbbreviation}
          isMobile={true}
          datalayerPageName={datalayerPageName}
        />
      </li>
    </>
  )
}

export default CountryUtilNavLinks
