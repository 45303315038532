import React from "react"
import ColorSwatch from "@/components/core/ColorSwatch/ColorSwatch"

const SwatchItems = props => {
  const { varentList, setVarent, varent } = props
  return (
    <>
      <div className="swatch-list d-flex">
        {varentList?.map((val, i) => {
          const validSwatch = val?.colorUrl?.split("/")
          if (validSwatch[validSwatch.length - 1] === "swatch_NA") {
            return null
          }
          return (
            <div
              key={`${val?.id}-${i}`}
              role="radio"
              tabIndex={0}
              aria-label={val.label}
              aria-checked={val.id == varent ? true : false}
            >
              <ColorSwatch
                type="selector"
                id={val.id}
                name={val.name}
                value={val.value}
                label={val.label}
                colorUrl={val.colorUrl}
                checked={val?.id == varent}
                onClick={() => setVarent(val?.id)}
                key={i}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default SwatchItems
