import React, { useState, useEffect } from "react"
import { isMobile } from "react-device-detect"
import Input from "@/components/core/Input/Input"
import rightArrow from "@/public/icons/right-arrow.svg"
import leftArrow from "@/public/icons/left-arrow.svg"
import leftArrowDisabled from "@/public/icons/left-arrow-disabled.svg"
import calendar from "@/public/icons/calendar.svg"
import { MILLISECONDSADAY } from "@/constants/index"

const DatePicker = props => {
  const {
    daysData = [],
    monthsData = [],
    isShowModal = false,
    startFromTomorrow = false,
    activeDay = "",
  } = props
  const todayDate = new Date()
  const nextDayDate = new Date(+new Date() + MILLISECONDSADAY)
  const currentYear = startFromTomorrow
    ? nextDayDate.getFullYear()
    : todayDate.getFullYear()
  const currentMonth = startFromTomorrow
    ? nextDayDate.getMonth()
    : todayDate.getMonth()
  const currentDate = startFromTomorrow
    ? nextDayDate.getDate()
    : todayDate.getDate()
  const [year, setYear] = useState(currentYear)
  const [month, setMonth] = useState(currentMonth)
  const [date, setDate] = useState(currentDate)
  const [selectedValue, setSelectedValue] = useState(
    `${monthsData[currentMonth]} ${currentDate}, ${currentYear}`
  )

  const [selectedDate, setSelectedDate] = useState(
    !startFromTomorrow ? new Date() : nextDayDate
  )
  const [yearChange, setYearChange] = useState("static")
  const [isShowcalendar, setIsShowCalendar] = useState(false)
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }
  const [isKeySelect, setIsKeySelect] = useState(false)
  useEffect(() => {
    props.getInputValue(
      currentYear + "/" + (currentMonth + 1) + "/" + currentDate
    )
  }, [])
  useEffect(() => {
    if (activeDay !== "") {
      setSelectedDate(activeDay)
      setSelectedValue(
        `${
          monthsData[activeDay.getMonth()]
        } ${activeDay.getDate()}, ${activeDay.getFullYear()}`
      )
      props.getInputValue(
        `${activeDay.getFullYear()}/${
          activeDay.getMonth() + 1
        }/${activeDay.getDate()}`
      )
    } else {
      setSelectedDate(!startFromTomorrow ? new Date() : nextDayDate)
    }
  }, [isShowModal])

  useEffect(() => {
    if (isKeySelect) {
      const elem = document.getElementsByClassName(
        "date-picker__point-date"
      )?.[0]
      setElementFocus(elem)
      setIsKeySelect(false)
    }
  }, [date])

  const changeValue = (yearSelected, monthSelected, dateSelected) => {
    setSelectedValue(
      `${monthsData[monthSelected]} ${dateSelected}, ${yearSelected}`
    )
    props.dateValue(`${yearSelected}/${monthSelected + 1}/${dateSelected}`)
    props.getDayfromInput(
      new Date(
        `${monthsData[monthSelected]} ${dateSelected}, ${yearSelected}`
      ).getDay()
    )
  }
  const changeDate = e => {
    setSelectedDate(
      new Date(
        `${e.target.dataset.year}/${Number(e.target.dataset.month) + 1}/${
          e.target.innerText
        }`
      )
    )

    changeValue(
      e.target.dataset.year,
      Number(e.target.dataset.month),
      e.target.innerText
    )
    setYear(Number(e.target.dataset.year))
    setMonth(Number(e.target.dataset.month))
    setDate(Number(e.target.innerText))
  }

  const prevMonth = () => {
    const yearData = month === 0 ? year - 1 : year
    const monthData = month === 0 ? 11 : month - 1
    setYear(yearData)
    setMonth(monthData)
  }

  const nextMonth = () => {
    const checkYear = month === 11 ? year + 1 : year
    const checkMonth = month === 11 ? 0 : month + 1
    setYear(checkYear)
    setMonth(checkMonth)
  }

  const classes = (data, type = "") => {
    const dataDate = data.getDate()
    const dataMonth = data.getMonth()
    const dataYear = data.getFullYear()
    if (
      type === "isCurrent" &&
      dataDate === todayDate.getDate() &&
      dataMonth === todayDate.getMonth() &&
      dataYear === todayDate.getFullYear()
    ) {
      return "date-picker__today"
    } else if (
      dataDate === selectedDate.getDate() &&
      dataMonth === selectedDate.getMonth()
    ) {
      return "date-picker__point-date"
    } else if (
      (((props.hidePast &&
        dataMonth === currentMonth &&
        dataDate < currentDate) ||
        dataMonth < currentMonth) &&
        dataYear === currentYear) ||
      dataYear < currentYear
    ) {
      return "date-picker__past"
    }
    return "date-picker__inner-date"
  }

  const changeYear = () => {
    setYearChange("input")
    setTimeout(() => {
      const input = document.querySelector(".date-picker__year-input")
      input.focus()
    }, 100)
  }

  const inputChange = () => {
    const input = document.querySelector(".date-picker__year-input")
    const year = parseInt(input.value)
    let yearSelected = year
    if (year < currentYear || !year) {
      yearSelected = currentYear
    } else if (year > 3000) {
      yearSelected = 2999
    }
    setYear(yearSelected)
    setYearChange("static")
    setSelectedValue(monthsData[month] + " " + date + ", " + yearSelected)
  }
  const handleKeyup = e => {
    if (e.keyCode === 13) {
      inputChange()
    }
  }

  const firstDay = new Date(year, month).getDay()
  const dates = []
  for (let i = 1; i <= 42; i++) {
    dates.push(new Date(year, month, i - firstDay))
  }

  const hanldeCalendarShow = () => setIsShowCalendar(!isShowcalendar)

  const handleKeyDown = e => {
    if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
      const elem =
        e.key === "ArrowLeft" ? e.target.previousSibling : e.target.nextSibling
      e.target.tabIndex = -1
      setElementFocus(elem)
    } else if (e.key === "Enter") {
      e.target.tabIndex = -1
      setIsKeySelect(true)
    }
  }

  const setElementFocus = elem => {
    if (
      elem &&
      (elem.className === "date-picker__inner-date" ||
        elem.className === "date-picker__point-date" ||
        elem.className === "date-picker__today")
    ) {
      elem.tabIndex = 0
      elem.focus()
    }
  }

  const getAriaLabel = (date, classes, props, options) => {
    switch (classes(date)) {
      case "date-picker__point-date":
        return `${props.i18n.ariaLabel.selected} ${date.toLocaleDateString(
          "en-US",
          options
        )}`
      case "date-picker__out-date":
        return date.toLocaleDateString("en-US", options)
      default:
        return `${props.i18n.ariaLabel.notSelected} ${date.toLocaleDateString(
          "en-US",
          options
        )}`
    }
  }

  return (
    <div className="date-picker">
      <React.Fragment>
        {props.hideInput && isMobile && (
          <div className="date-picker__show-input">
            <Input
              id="date-picker__input"
              type="text"
              value={selectedValue}
              maxLength={60}
              placeholder={`${props.i18n.datePlaceholder}*`}
              label={`${props.i18n.datePlaceholder}*`}
              onChange={() =>
                props.dateValue(year + "/" + monthsData[month] + "/" + date)
              }
              onFocus={hanldeCalendarShow}
              showError={props.error}
              errorMessage={props.message}
              prefix={<img src={calendar?.src} />}
            />
          </div>
        )}
        <div
          className={`date-picker__calendar  ${
            !isMobile && props.hideInput ? "date-picker__positioning" : ""
          } ${isShowcalendar ? "date-picker__calendar-show" : ""}`}
        >
          <div className="date-picker__header">
            <div className="date-picker__month-and-year">
              <span className="date-picker__month">{monthsData[month]}</span>
              {yearChange === "static" ? (
                <span
                  className="date-picker__change-year"
                  tabIndex="0"
                  onClick={changeYear}
                  onFocus={changeYear}
                >
                  {year}
                </span>
              ) : (
                <input
                  className="date-picker__year-input"
                  role="combobox"
                  type="number"
                  aria-label={props.i18n.ariaLabel.selectYear}
                  min={currentYear}
                  max="2999"
                  defaultValue={year}
                  onKeyUp={handleKeyup}
                  onBlur={inputChange}
                />
              )}
              <span
                className="date-picker__month"
                role="alert"
                aria-live="polite"
                aria-label={`${monthsData[month]} ${year}`}
              ></span>
            </div>
            <div className="date-picker__change-month">
              {props.hidePast &&
              month === currentMonth &&
              year === currentYear ? (
                <img
                  aria-disabled="true"
                  tabIndex="0"
                  role="button"
                  aria-label={props.i18n.ariaLabel.prevMonth}
                  src={leftArrowDisabled?.src}
                  alt={props.i18n.leftArrowAlt}
                  className="date-picker__prev-month-disabled"
                />
              ) : (
                <img
                  tabIndex="0"
                  role="button"
                  aria-label={props.i18n.ariaLabel.prevMonth}
                  src={leftArrow?.src}
                  alt={props.i18n.leftArrowAlt}
                  className="date-picker__prev-month"
                  onClick={prevMonth}
                />
              )}

              <img
                tabIndex="0"
                role="button"
                aria-label={props.i18n.ariaLabel.nextMonth}
                src={rightArrow?.src}
                alt={props.i18n.rightArrowAlt}
                className="date-picker__next-month"
                onClick={nextMonth}
              />
            </div>
          </div>
          <div className="date-picker__content">
            <ul
              className="date-picker__nav"
              role="list"
              aria-label={props.i18n.ariaLabel.days}
            >
              {daysData.map((day, i) => (
                <li key={i} role="listitem">
                  <span aria-hidden="true">{day.substr(0, 2)}</span>
                  <span className="sr-only">{day}</span>
                </li>
              ))}
            </ul>
            <ul className="date-picker__dates">
              {dates.map((date, i) =>
                classes(date) === "date-picker__past" ? (
                  <li
                    className={classes(date)}
                    key={i}
                    aria-disabled="true"
                    aria-hidden="true"
                    disabled
                  >
                    {date.getDate()}
                  </li>
                ) : (
                  <li
                    className={classes(date)}
                    data-year={date.getFullYear().toString()}
                    data-month={date.getMonth().toString()}
                    onClick={changeDate}
                    onKeyDown={handleKeyDown}
                    key={i}
                    aria-current={
                      classes(date, "isCurrent") === "date-picker__today"
                    }
                    aria-disabled={classes(date) === "date-picker__out-date"}
                    tabIndex={
                      classes(date) === "date-picker__point-date" ? 0 : -1
                    }
                    aria-label={getAriaLabel(date, classes, props, options)}
                    role="button"
                    aria-selected={classes(date) === "date-picker__point-date"}
                  >
                    {date.getDate()}
                  </li>
                )
              )}
            </ul>
            {props.hideInput && isMobile ? (
              <div className="date-picker__select-done">
                <span onClick={hanldeCalendarShow}>{props.i18n.done}</span>
              </div>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    </div>
  )
}

export default DatePicker
