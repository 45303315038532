import React, { useEffect, useState } from "react"
import { GLOBAL_CONFIGURATION_ENDPOINT, authorBaseUrl } from "@/constants/index"
import { aemAxiosInstance } from "@/constants/api"

import Script from "next/script"
import { extractScriptsFromDOM } from "@/utils/helper"
const AemForm = props => {
  const {
    data: {
      formPath,
      submitType,
      thankyouMessage,
      useiframe,
      enableFocusOnFirstField,
      thankyouConfig,
    },
  } = props

  const [innerHtmlToSet, setInnerHtmlToSet] = useState("")
  const [formScripts, setFormScripts] = useState(null)
  const [noOfScripts, setNoOfScripts] = useState(0)

  useEffect(() => {
    aemAxiosInstance({
      url: GLOBAL_CONFIGURATION_ENDPOINT,
      params: {
        path: formPath + ".html",
      },
    }).then(res => {
      const scriptsList = []
      let innerHTML = res.data
      if (location?.hostname === "localhost") {
        innerHTML = innerHTML
          .split('rel="stylesheet" href="')
          .join(`rel=\"stylesheet\" href=\"${authorBaseUrl}`)
        innerHTML = innerHTML
          .split('<script src="')
          .join(`<script src=\"${authorBaseUrl}`)
      }
      const doc = new DOMParser().parseFromString(innerHTML, "text/html")

      extractScriptsFromDOM(doc, item => scriptsList.push(item))

      setFormScripts(scriptsList)

      setInnerHtmlToSet(doc)
    })
  }, [])

  return (
    <>
      <Script id="aemFormInitialLoadJavascript" data-nscript="afterInteractive">
        {`const onAemFormScriptLoad = evnt => {
    const formApp = evnt.detail.formApp
    formApp.initializeAEMForm({
      form: "true",
      submitType: "${submitType}",
      thankyouConfig: "${thankyouConfig}",
      thankyouMessage: "${encodeURI(thankyouMessage)}",
      thankyouPage: "",
      useIframe: "${useiframe}",
      height: "auto",
      aemFormComponentPath: "aemform",
      enableFocusOnFirstField: "${enableFocusOnFirstField}",
    })
    // remove the event listener after completion
    window.removeEventListener("aemform-onscript-load", onAemFormScriptLoad)
  };
  window.addEventListener("aemform-onscript-load", onAemFormScriptLoad);`}
      </Script>
      <Script src="/js/formapp.js" />

      {formScripts &&
        formScripts.map((script, i) => {
          if (!script.innerHTML && noOfScripts === i)
            return (
              <Script
                key={`aemform-src-script-${i}`}
                src={script.src}
                id={`aemform-src-script-${i}`}
                onReady={() => {
                  setNoOfScripts(i + 1)
                }}
                onError={() => {
                  setNoOfScripts(i + 1)
                }}
              />
            )
          if (script.innerHTML && noOfScripts === i)
            return (
              <Script
                key={`aemform-innerscript-${i}`}
                id={`aemform-innerscript-${i}`}
                onReady={() => {
                  setNoOfScripts(i + 1)
                }}
                onError={() => {
                  setNoOfScripts(i + 1)
                }}
              >
                {`${script.innerHTML}`}
              </Script>
            )
        })}
      <div className="aemform aem-genericForm">
        <div className="aemformcontainer" data-form-page-path={formPath}>
          <div
            dangerouslySetInnerHTML={{
              __html: innerHtmlToSet?.documentElement?.innerHTML,
            }}
          />
        </div>
      </div>
    </>
  )
}

export default AemForm
