import React, { Suspense } from "react"
import cx from "classnames"
import dynamic from "next/dynamic"
import { capitalizeFirstName } from "@/utils/helper"
import {
  selectAuthState,
  setAuthModalVisibility,
} from "@/store/features/authSlice"
import { useDispatch, useSelector } from "react-redux"
import useWindowDimensions from "@/hooks/useWindowDimensions"
import { toggeleBodyClass } from "@/utils/helper"
import {
  newdataLayerForCreateAccount,
  newdatalayerforSignIn,
  onclickForSignedFun,
} from "@/components/Shared/HeaderNavTabs/analytics/"
const MegaMenu = dynamic(
  () => import("@/components/Default/AuthUtilityNav/v1/MegaMenu"),
  {
    suspense: true,
  }
)

const AuthUtilityNavView = props => {
  const avatarIcon = "/icons/avatar.svg"
  const rightArrow = "/icons/right-white.svg"
  const iconUserSignin = "/icons/user-signin.svg"

  const {
    active,
    i18n,
    userMenuFragment,
    guestMenuFragment,
    isLoggedIn,
    logout,
    toggleAuthNav,
    setAuthNav,
    onAuthMenuClick,
  } = props
  const { user } = useSelector(selectAuthState)

  const { winWidth } = useWindowDimensions()

  const styleClass = cx({ "auth-nav": true, "auth-nav--active": active })

  const dispatch = useDispatch()

  const onClickSignIn = () => {
    dispatch(setAuthModalVisibility({ show: true }))
    newdatalayerforSignIn(themeChange)
  }

  const onClickSignUp = () => {
    dispatch(setAuthModalVisibility({ show: true, active: 1 }))
    newdataLayerForCreateAccount(themeChange)
  }

  const showMenuNav = () => {
    toggleAuthNav()
  }
  const themeChange =
    typeof window !== "undefined" &&
    winWidth <= 1200 &&
    (document.body.classList.contains("nav-light-theme") ||
      document.body.classList.contains("nav-dark-theme"))

  const signInEventInfo = JSON.stringify({
    eventName: "sign in start",
    eventAction: "sign in start",
    internalLinkName: "sign in start",
    internalLinkType: "global header:navigation",
    internalLinkZoneName: "global header",
    clickInternalLinks: "true",
    eventType: "global header",
    internalLinkURL: "n/a",
    internalLinkPosition: "global header sign in start",
    eventMsg: "n/a",
    eventStatus: "n/a",
  })

  const signedInEventInfo = JSON.stringify({
    eventName: "global header:your kohler account",
    eventAction: "global header:your kohler account",
    internalLinkName: "your kohler account",
    internalLinkType: "global header: welcome back to your kohler",
    internalLinkZoneName: "global header",
    clickInternalLinks: "true",
    eventType: "navigation",
    internalLinkURL: "n/a",
    internalLinkPosition: "global header:my account",
    eventMsg: "n/a",
    eventStatus: "n/a",
  })

  return (
    <div className={styleClass} aria-expanded={active}>
      {winWidth <= 1200 ? (
        isLoggedIn ? (
          <React.Fragment>
            <div
              tabIndex="0"
              aria-label={`${i18n.hi} ${capitalizeFirstName(user?.firstName)}`}
              role="button"
              className="auth-nav__sign-in-mobile"
              onClick={() => {
                toggleAuthNav()
                onAuthMenuClick(!active)
                setAuthNav(!active)
                toggeleBodyClass(true)
                onclickForSignedFun(themeChange)
              }}
            >
              <img
                name="avatarIcon"
                src={themeChange ? iconUserSignin : avatarIcon}
                alt={i18n.signIn}
                className="gbh-data-layer auth-nav__sign-in-avatar"
                data-gbh-data-layer={signInEventInfo}
              />
              <span name="loggedInUser" className="auth-nav__user">
                {i18n.hi}, {capitalizeFirstName(user?.firstName)}
              </span>
              <img
                src={rightArrow}
                alt={i18n.rightArrowAlt}
                className="auth-nav__sign-in-right-arrow"
              />
            </div>
          </React.Fragment>
        ) : (
          <Suspense fallback="Loading...">
            <MegaMenu
              userMenuFragment={userMenuFragment}
              guestMenuFragment={guestMenuFragment}
              isLoggedIn={isLoggedIn}
              i18n={i18n}
              user={user}
              logout={logout}
              toggleAuthNav={toggleAuthNav}
              active={active}
              handleSignin={onClickSignIn}
              handleCreateAccount={onClickSignUp}
            />
          </Suspense>
        )
      ) : isLoggedIn ? (
        <span
          tabIndex="0"
          role="button"
          aria-label={
            active
              ? `${i18n.user} ${i18n.expanded}`
              : `${i18n.user} ${i18n.collapsed}`
          }
          name="loggedInUser"
          className="auth-nav__user gbh-data-layer"
          onClick={showMenuNav}
          data-gbh-data-layer={signedInEventInfo}
        >
          {i18n.hi}, {capitalizeFirstName(user?.firstName)}
        </span>
      ) : (
        <img
          tabIndex="0"
          role="button"
          name="avatarIcon"
          aria-label={
            active
              ? `${i18n.signIn} ${i18n.expanded}`
              : `${i18n.signIn} ${i18n.collapsed}`
          }
          src={avatarIcon}
          alt={i18n.signIn}
          onClick={showMenuNav}
          className="gbh-data-layer"
          data-gbh-data-layer={signInEventInfo}
        />
      )}
      <div
        className={`auth-nav__menu ${
          isLoggedIn ? "auth-nav__menu-expand" : ""
        } ${active ? "auth-nav__menu-open" : ""}`}
        aria-hidden={!active}
      >
        {active ? (
          <Suspense fallback="Loading...">
            <MegaMenu
              userMenuFragment={userMenuFragment}
              guestMenuFragment={guestMenuFragment}
              isLoggedIn={isLoggedIn}
              i18n={i18n}
              user={user}
              logout={logout}
              toggleAuthNav={toggleAuthNav}
              handleSignin={onClickSignIn}
              handleCreateAccount={onClickSignUp}
              onAuthMenuClick={onAuthMenuClick}
            />
          </Suspense>
        ) : null}
      </div>
    </div>
  )
}

AuthUtilityNavView.displayName = "AuthUtilityNavView"

export default AuthUtilityNavView
