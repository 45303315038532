import { useState, useEffect } from "react"
import useIsSsr from "@/hooks/useIsSsr"

const useSmartCrop = () => {
  const isSsr = useIsSsr()
  const [scriptStatus, setScriptStatus] = useState(
    !isSsr && window.s7responsiveImage ? true : false
  )

  useEffect(() => {
    if (!isSsr) {
      loadSmartcrop()
    }
  }, [isSsr])

  const loadSmartcrop = () => {
    const scriptExist = document.querySelectorAll("script#smartcrop-react")
    if (scriptExist.length === 0 && !window.s7responsiveImage) {
      const script = document.createElement("script")
      script.src = "/js/responsive-images.js"
      script.id = "smartcrop-react"
      script.onload = function onload() {
        setScriptStatus(true)
      }
      document.body.appendChild(script)
    } else {
      setScriptStatus(true)
    }
  }

  const applySmartCrop = ele => {
    window.s7responsiveImage && window.s7responsiveImage(ele)
  }

  return { scriptStatus, applySmartCrop }
}

export default useSmartCrop
