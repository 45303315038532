export const getDataLayerValues = (
  isSSR,
  pageName = "",
  link = "",
  title = "",
  linkName = ""
) => {
  if (!isSSR && window) {
    const dataLayer = window?.adobeDataLayer ?? {}
    const page = dataLayer?.getState().page ?? {}
    if (Object.keys(page).length) {
      const eventInfo = {
        clickInternalLinks: "true",
        eventAction: `${pageName}:${title.toLowerCase()}:${linkName.toLowerCase()}`,
        eventMsg: "n/a",
        eventName: `${pageName}:${title.toLowerCase()}:${linkName.toLowerCase()}`,
        eventStatus: "n/a",
        eventType: "teaser",
        internalLinkName: `${linkName.toLowerCase()}`,
        internalLinkPosition: `${title.toLowerCase()}`,
        internalLinkType: `${pageName}:${linkName.toLowerCase()}`,
        internalLinkURL: `${link}`,
        internalLinkZoneName: `${pageName}:${title.toLowerCase()}`,
      }
      return JSON.stringify(eventInfo)
    }
  }
  return ""
}

export const addAnalyticsSlic = (
  event,
  pageName = "",
  datalayerTitleText = "",
  componentLinkType = "",
  componentPosition = "",
  link = ""
) => {
  const titleOptions = [
    "showerheads",
    "handshowers",
    "body sprays",
    "rainheads",
  ]

  event.stopPropagation()
  const { adobeDataLayer: {} = {} } = window
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const { productInfo } = page
  let eventInfo = {}
  if (titleOptions.some(title => datalayerTitleText === title)) {
    eventInfo = {
      eventAction: `pdp:complete the experience:${datalayerTitleText}`,
      eventName: `pdp:complete the experience:${datalayerTitleText}`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: datalayerTitleText,
      internalLinkPosition: "pdp:teaser",
      internalLinkType: `pdp:${datalayerTitleText}`,
      internalLinkZoneName: "pdp:complete the experience",
      internalLinkURL: link || "na",
      clickInternalLinks: "true",
    }
  } else {
    eventInfo = {
      eventName: `${pageName}:carousel:interaction`,
      eventAction: `${pageName}:carousel:interaction`,
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: `carousel:${datalayerTitleText || "rm"}`,
      internalLinkType: `${pageName}:${
        componentLinkType?.toLowerCase() || datalayerTitleText || "rm"
      }`,
      internalLinkZoneName: `${pageName}:carousel`,
      clickInternalLinks: "true",
      eventType: "carousel",
      internalLinkURL: link || "na",
      internalLinkPosition: `${pageName}:${componentPosition || "carousel"}`,
    }
  }
  pushAnalyticsObjToDataLayer(eventInfo, productInfo)
}

const pushAnalyticsObjToDataLayer = (eventInfo = {}, productInfo = {}) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const event = "cmp:click"
  window.adobeDataLayer.push({
    event,
    eventInfo,
    page,
    productInfo,
  })
}
