import React from "react"
import { Container } from "react-bootstrap"
import PropTypes from "prop-types"
import {
  CloseIcon,
  DownIcon,
  LoaderSpinner,
} from "@/components/PartsViewer/v2/icon.js"

const LoadingPopupection = props => {
  const { setModal, device, popupWidth, fullscreen } = props
  return (
    <div
      className="popup-default loading-popup"
      style={
        popupWidth && !device.isSmallScreen
          ? {
              width: popupWidth?.sidebarWidth + "px",
              top: !fullscreen ? popupWidth?.headHeight : 0 + "px",
            }
          : {}
      }
    >
      <Container fluid>
        <div>
          <div className="d-flex justify-content-start">
            <button className="btn closebtns" onClick={() => setModal(false)}>
              {device?.isLargeScreen || device.isExtraLargeScreen ? (
                <CloseIcon />
              ) : (
                <DownIcon />
              )}
            </button>
          </div>
        </div>
        <div className="flex-column">
          <div className={`d-flex  flex-column align-items-center mt-4 mb-1`}>
            <div className="loading-thumb mb-4">
              <LoaderSpinner />
            </div>
          </div>

          <div className={`d-flex  flex-column justify-content-start mb-30`}>
            <span className={"loading-animation loading-heading mb-4"}>
              new
            </span>
            <span className={"loading-animation mt-4 mb-4 h-16"}>
              Widespread bathroom sink faucet
            </span>
            <span className={"loading-animation loading-heading"}>
              K-5709-7
            </span>
          </div>

          <div className={`d-flex  flex-column justify-content-start mb-30`}>
            <span className={"loading-animation loading-heading"}>$800.44</span>
            <span
              className={"loading-animation mt-3 mb-2 h-16 loading-heading"}
            >
              $1000.44 List Price
            </span>
          </div>

          <div className={`d-flex  flex-column justify-content-start mb-2`}>
            <span className={"loading-animation loading-heading h-16"}>
              color/Finish white
            </span>
          </div>

          <div className={`mb-30 w-100`}>
            {Array.from({ length: 9 }, (v, index) => index + 1).map(val => (
              <span
                key={val}
                className={"loading-animation loading-buttons mb-2"}
              >
                color Finish
              </span>
            ))}
          </div>

          <div
            className={`w-100 d-flex flex-rows justify-content-between align-content-center mb-2`}
          >
            <span className={"loading-animation counter-details"}>counter</span>
            <span className={"loading-animation addtocart-details"}>
              Add to cart
            </span>
          </div>
        </div>
      </Container>
    </div>
  )
}

LoadingPopupection.propTypes = {
  fullscreen: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  popupWidth: PropTypes.shape({
    headHeight: PropTypes.number,
  }),
  device: PropTypes.object.isRequired,
}

export default LoadingPopupection
