import dynamic from "next/dynamic"
import { isMobile, withOrientationChange } from "react-device-detect"
import { useTranslation } from "next-i18next"
import CONSTANTS from "@/constants"
import { getTranslations } from "@/components/Default/DynamicSearch/v1/SearchPanel/localization"
const SearchPanel = dynamic(() =>
  import("@/components/Default/DynamicSearch/v1/SearchPanel")
)
import styles from "@/components/Default/DynamicSearch/v1/DynamicSearch.view/index.module.scss"
import searchIcon from "@/public/icons/search.svg"

const DynamicSearch = props => {
  const { showSearchBar = false, onToggle = () => {} } = props
  const { t } = useTranslation("common")
  const { searchHeader = "" } = getTranslations(t)

  return (
    <div className={styles.dynamicSearchViewWrapper}>
      <button
        className="search-button gbh-data-layer"
        id="search-side-panel__toggle"
        tabIndex="0"
        role="button"
        aria-label={searchHeader}
        data-gbh-data-layer={`{"eventAction":"internal search","eventName":"internal search","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"internal search magnifying glass button","internalLinkPosition":"global header","internalLinkType":"global navigation","internalLinkZoneName":"global header","internalLinkURL":"n/a","clickInternalLinks":"true"}`}
        onClick={onToggle}
        onKeyDown={e => e.key === CONSTANTS.KEY.ENTER && onToggle()}
      >
        <span className="search-text">{searchHeader}</span>
        <span className="search-icon">
          <img
            role="presentation"
            aria-hidden
            src={searchIcon?.src}
            alt={isMobile ? "search-icon" : ""}
          />
        </span>
      </button>
      <div
        className={`search-side-panel__overlay ${
          showSearchBar ? "search-side-panel__show-overlay" : ""
        }`}
      />
      {showSearchBar ? <SearchPanel {...props} /> : null}
    </div>
  )
}

export default withOrientationChange(DynamicSearch)
