import React from "react"
import escapeRegExp from "lodash/escapeRegExp"

const Highlight = ({ text = "", highlight = "" }) => {
  if (!highlight.trim()) return <span>{text}</span>
  const regex = new RegExp(`(${escapeRegExp(highlight)})`, "gi")
  const parts = text.split(regex)
  return parts
    .filter(part => part)
    .map((part, i) =>
      regex.test(part) ? (
        <span key={i} className="highlight-text">
          {part}
        </span>
      ) : (
        <span key={i}>{part}</span>
      )
    )
}

export default Highlight
