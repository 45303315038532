import { memo, useEffect, useState } from "react"
import Button from "@/components/core/Button/Button"
import styles from "@/components/Shared/UpsellInstallServices/ProductCardForUpsell/index.module.scss"
import useWindowResize from "@/hooks/useWindowResize"
import { getPresetUrl } from "@/utils/index"
import { getPDPUrl } from "@/utils/helper"
import {
  addAnalyticsForAddToCart,
  addClickAnalytics,
} from "@/components/Shared/UpsellInstallServices/ProductCardForUpsell/analytics/index"
import { getProductsBySku } from "@/components/sharedCart/shareCartHelper"
import Image from "@/components/Shared/Image"

const ProductCard = ({
  persona = "GST",
  currencySign = "$",
  brand = "kohler",
  presetConfigs = "",
  rowCols = 0,
  swatchUrl: baseUrl = "",
  outOfStockTitle = "",
  addToCart = () => {},
  ...item
}) => {
  const [width] = useWindowResize()
  const [pdpUrl, setPdpUrl] = useState("")
  const [loading, setLoading] = useState(false)
  const {
    sku_s: skuId = "",
    quantity: productQuantity = 0,
    ProductBrandName_s: name = "",
    productName_s: productName = "",
    [`priceList.${persona}.price_d`]: productPrice = 0,
    [`priceList.${persona}.discountedPrice_d`]: discountedPrice = 0,
    ["productImages.labelWithUrl_ss"]: labledImages = [],
    Product_Category: productCategory = "",
    slug_s: slug = "",
  } = item

  useEffect(() => {
    getPDPUrl(productCategory, slug).then(url => setPdpUrl(url))
  }, [])

  const price = Number(productPrice).toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const productPresetConfigs = presetConfigs ? JSON.parse(presetConfigs) : {}
  let imgId = ""
  if (labledImages.length) {
    const index = labledImages
      .map(item => (item.split("|")?.length ? item.split("|")[0] : ""))
      .indexOf(skuId)
    const assetId = labledImages[index]?.split("|")?.length
      ? labledImages[index].split("|")[1]
      : ""
    imgId = assetId
  }

  const handleAddToCartCb = () => {
    setLoading(false)
  }

  const handleAddToCart = async e => {
    e.preventDefault()
    e.stopPropagation()
    setLoading(true)
    const itemsProjectionApiResponse = await getProductsBySku([skuId])
    const variants = itemsProjectionApiResponse?.[0]?.variants ?? []
    variants.push(itemsProjectionApiResponse?.[0]?.masterVariant ?? {})
    let isComboSku = false
    for (let i = 0; i < variants.length; i++) {
      if (skuId === variants[i].sku) {
        isComboSku = variants[i]?.attributes.find(
          attr => attr.name === "ComboSKU"
        )
          ? true
          : false
      }
    }
    const payload = [
      {
        sku: skuId,
        quantity: 1,
        brand: brand?.toLowerCase(),
        isBundle: isComboSku,
      },
    ]
    addToCart(payload, handleAddToCartCb)
    addAnalyticsForAddToCart(
      item,
      productPrice,
      discountedPrice,
      productImageUrl
    )
  }

  const productImageUrl = getPresetUrl(
    width,
    rowCols,
    baseUrl,
    imgId,
    productPresetConfigs
  )

  const linkClicked = () => {
    addClickAnalytics({ item, productPrice, discountedPrice, productImageUrl })
  }

  return (
    <div className={styles.curatedProduct}>
      <div className="curated-product-recommendation__card-list">
        <a
          href={`${pdpUrl}${skuId ? "?skuId=" + skuId : ""}`}
          onClick={linkClicked}
        >
          <div className="curated-product-recommendation__product-card">
            <div className="curated-product-recommendation__img">
              <Image
                src={productImageUrl}
                alt={name}
                width={280}
                height={280}
              />
            </div>
            <div className="curated-product-recommendation__product-name">
              {name || productName}
            </div>
            <div className="curated-product-recommendation__product-sku">
              {skuId}
            </div>
            {discountedPrice > 0 ? (
              <div className="curated-product-recommendation__discounted-price">
                <div className="price-with-discount">{`${currencySign}${discountedPrice}`}</div>
                <div className="original-price">{`${currencySign}${price}`}</div>
              </div>
            ) : (
              <div className="curated-product-recommendation__product-price">
                {`${currencySign}${price}`}
              </div>
            )}
            {!productQuantity ? (
              <div className="curated-product-recommendation__out-of-stock">
                {outOfStockTitle}
              </div>
            ) : (
              <div className="curated-product-recommendation__add-to-cart">
                <Button
                  role="button"
                  label={"Add To Cart"}
                  size="small"
                  type="primary"
                  flexible={true}
                  onClick={handleAddToCart}
                  loading={loading}
                />
              </div>
            )}
          </div>
        </a>
      </div>
    </div>
  )
}
export default memo(ProductCard)
