const getEventFor = type => {
  if (type?.subCategory1?.includes("support:contact")) {
    return "support:contact:form"
  }
  if (type?.subCategory1?.includes("support:find a service part")) {
    return "support:find-a-service-part"
  }
  if (type?.subCategory1?.includes("my account:product registration")) {
    return "my account:register offline kohler products:product information"
  }
  return type?.subCategory1 ?? ""
}

const getAnalyticsEventInfo = () => {
  const type = window.adobeDataLayer.length
    ? window.adobeDataLayer[0]["page"]["category"]
    : {}
  const eventFor = getEventFor(type)
  const internalLinkType = type?.subCategory1?.includes(
    "my account:product registration"
  )
    ? `my account:close`
    : `${eventFor}:close`
  return JSON.stringify({
    clickInternalLinks: "true",
    eventAction: `${eventFor}:modal:where do i find my product model number:close`,
    eventName: `${eventFor}:modal:where do i find my product model number:close`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "close",
    internalLinkPosition: type?.subCategoryID || "contact us",
    internalLinkType,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${eventFor}:modal:where do i find my product model number`,
  })
}

const productInfoNextEventInfo = () => {
  ;`{"eventAction":"my account:register non kohler products:product information:next","eventName":"my account:register non kohler products:product information:next","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"next","internalLinkPosition":"register products","internalLinkType":"my account:navigation","internalLinkZoneName":"my account:register non kohler products:product information","internalLinkURL":"n/a","clickInternalLinks":"true"}`
}

export { getEventFor, getAnalyticsEventInfo, productInfoNextEventInfo }
