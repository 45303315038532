import React, { useEffect, useState } from "react"
import _get from "lodash/get"
import useVirtuali18n from "@/i18n/useVirtuali18n"
import { removeAndCleanLink } from "@/utils/helper"
import { GLOBAL_CONFIGURATION_ENDPOINT } from "@/constants"
import { aemAxiosInstance } from "@/constants/api"
import VirtualDesignerDetailsView from "@/components/VirtualDesignerDetails/v1/VirtualDesignerDetails.view"

const VirtualDesignerDetails = props => {
  const { data: authorData } = props
  const staticTexts = useVirtuali18n({
    authorData,
  })
  const [virtualData, setVirtualData] = useState({})
  const virtualDesignerDataUrl = _get(authorData, "designerFragLink", "")
  const virtualSanitizeURL = removeAndCleanLink(virtualDesignerDataUrl)
  useEffect(() => {
    aemAxiosInstance({
      url: GLOBAL_CONFIGURATION_ENDPOINT,
      params: {
        path: `${virtualSanitizeURL}/jcr:content/data/master.json`,
      },
    })
      .then(response => {
        if (response && response?.data) {
          setVirtualData(response?.data)
        }
      })
      .catch(err => {
        console.log("Failed to get compare attributes JSON file", err)
      })
  }, [])

  return (
    <VirtualDesignerDetailsView staticTexts={staticTexts} data={virtualData} />
  )
}

export default VirtualDesignerDetails
