import { apim } from "@/constants/api"

export const getFindAStoresResult = (
  url = "",
  address = "",
  brand = "",
  disMiles = 100,
  maxStorePerPage = 10,
  locationType = "",
  page = 1,
  sku = ""
) => {
  const params = {
    address,
    brand: brand.toLowerCase(),
    disMiles,
    maxItemPerPage: maxStorePerPage,
    locationType: locationType.toLowerCase(),
    page,
  }
  if (sku) params["sku"] = sku
  return apim.get(url, {
    params,
  })
}

export const getAutoSuggestionsInputBased = (
  url = "",
  brand = "",
  input = ""
) => {
  return apim.get(url, {
    params: {
      $format: "json",
      brand: brand.toLowerCase(),
      $select: "Locality",
      $filter: `StartsWith(Locality,'${input}') eq true`,
    },
  })
}
