import React, { useEffect, useReducer, useState } from "react"
import partsReducer, {
  getActions,
} from "@/components/PartsViewer/v2/partReducer.js"
import DesktopView from "@/components/PartsViewer/v2/SelectedFileView/DesktopView"
import MobileView from "@/components/PartsViewer/v2/SelectedFileView/MobileView"

const SelectedFileView = props => {
  const {
    selectedFile,
    expandHandler,
    partsList,
    setIsListExtend,
    isListExtend,
    title,
    showPopup,
    popupModal,
    setModal,
    productId,
    selected,
    tabsActive,
    panelState,
    onClose,
    sku,
    prefeedbackController,
    loading,
    analytics,
  } = props
  const [partsState, dispatch] = useReducer(partsReducer, { parts: [] })
  const [widthSize, setWidthSize] = useState(0)
  const partsActions = getActions(partsState, dispatch)

  useEffect(() => partController.actions.initPartsList(selected), [selected])

  let isDesktop
  const screenWidth = window.innerWidth
  if (screenWidth > 812) {
    isDesktop = true
  }
  const partController = {
    state: partsState,
    actions: partsActions,
  }
  return (
    <>
      {!isDesktop ? (
        <MobileView
          expandHandler={expandHandler}
          panelState={panelState}
          showPopup={showPopup}
          selectedFile={selectedFile}
          partsList={partsList}
          setIsListExtend={setIsListExtend}
          isListExtend={isListExtend}
          title={title}
          productId={productId}
          tabsActive={tabsActive}
          setModal={setModal}
          popupModal={popupModal}
          selected={selected}
          onClose={onClose}
          sku={sku}
          partController={partController}
          prefeedbackController={prefeedbackController}
          loading={loading}
          analytics={analytics}
          setWidthSize={setWidthSize}
        />
      ) : (
        <>
          <DesktopView
            expandHandler={expandHandler}
            fullscreen={panelState}
            showPopup={showPopup}
            selectedFile={selectedFile}
            partsList={partsList}
            setIsListExtend={setIsListExtend}
            isListExtend={isListExtend}
            title={title}
            productId={productId}
            setModal={setModal}
            popupModal={popupModal}
            selected={selected}
            partController={partController}
            sku={sku}
            loading={loading}
            analytics={analytics}
            setWidthSize={setWidthSize}
            widthSize={widthSize}
          />
        </>
      )}
    </>
  )
}

export default SelectedFileView
