import React from "react"
import Conditional from "@/components/core/Conditional/Conditional"
import GridView from "@/components/PartsViewer/v2/GridAndListPanel/GridView"
import ListView from "@/components/PartsViewer/v2/GridAndListPanel/ListView"

const ViewController = props => {
  const {
    partController,
    isListView,
    isListExtend,
    showPopup,
    visibleData,
    title,
    sku,
  } = props
  return (
    <>
      <Conditional show={isListView}>
        {partController?.actions?.get?.firstEntries(visibleData)?.map(input => (
          <ListView
            key={input.key}
            dataInput={input}
            dataPromise={input.ctApiPromise}
            showPopup={showPopup}
            isListExtend={isListExtend}
            title={title}
            sku={sku}
          />
        ))}
      </Conditional>
      <Conditional show={!isListView}>
        {partController?.actions?.get?.firstEntries(visibleData)?.map(input => (
          <GridView
            key={input.key}
            dataInput={input}
            dataPromise={input.ctApiPromise}
            showPopup={showPopup}
            isListExtend={isListExtend}
            title={title}
            sku={sku}
          />
        ))}
      </Conditional>
    </>
  )
}

export default ViewController
