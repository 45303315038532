import {
  getShortenedUrlwithGeneral,
  sanitizeInnerHtml,
  sanitizeTextArticle,
} from "@/utils/helper"
import { ConditionalWrapper } from "@/utils/index"
import _isEmpty from "lodash/isEmpty"
import { useEffect, useState } from "react"

const Description = props => {
  const {
    linkURL = "",
    linkURLRef = "",
    linkNewTab = "",
    description = "",
    mainTitle = "",
    componentPosition = null,
    componentLinkType = null,
    pageType: serverPageType = "",
    linkDescription = false,
    general = {},
    expandText = "",
    collapseText = "",
    disableExpandCollapse = "",
  } = props

  const [shortenedLinkUrl, setShortenedLinkUrl] = useState("")
  const [pageType, setPageType] = useState(serverPageType)
  const [target, setTarget] = useState("")
  const [details, setDetails] = useState(description)
  const [isPdp, setIsPdp] = useState("")

  useEffect(() => {
    window.expandText = expandText
    window.collapseText = collapseText
    window.disableExpandCollapse = disableExpandCollapse
  }, [expandText, collapseText, disableExpandCollapse])

  useEffect(() => {
    const pageInfoDetails = window?.adobeDataLayer?.getState("page")
    if (
      serverPageType !== pageInfoDetails?.pageInfo?.contentType &&
      pageInfoDetails?.pageInfo?.contentType === "pdp"
    ) {
      setPageType(pageInfoDetails?.pageInfo?.contentType)
      setIsPdp(true)
    }
  }, [])
  const internalLinkPositionNotPdp = `${pageType.toLowerCase()}:${
    componentPosition ? componentPosition : "teaser"
  }`
  const internalLinkTypeNotPdp = `${pageType}:${
    componentLinkType ? componentLinkType : mainTitle.toLowerCase()
  }`
  useEffect(() => {
    if (!_isEmpty(general)) {
      if (linkURL) {
        setShortenedLinkUrl(getShortenedUrlwithGeneral(linkURL, general))
      }
      if (description) {
        setDetails(getShortenedUrlwithGeneral(description, general))
      }
    }
  }, [linkURL, description, general])

  // Added to fix eslint error Disallow target="_blank" attribute without rel="noreferrer" (react/jsx-no-target-blank)
  useEffect(() => {
    if (linkNewTab) {
      setTarget("_blank")
    } else {
      setTarget("_self")
    }
  }, [linkNewTab])

  return (
    <div
      className="cmp-teaser__description"
      data-gbh-data-layer={`{"clickInternalLinks":"${
        target === "_blank" ? "false" : "true"
      }","internalLinkPosition": "${
        isPdp ? pageType.toLowerCase() : internalLinkPositionNotPdp
      }","internalLinkZoneName": "${
        mainTitle ? `${pageType?.toLowerCase()}:${mainTitle}` : "teaser"
      }","internalLinkURL":"${linkURL}","internalLinkName":"${sanitizeTextArticle(
        description.toLowerCase()
      )}","eventName":"${pageType.toLowerCase()}:${mainTitle}:rm","internalLinkType":"${
        isPdp ? `${pageType.toLowerCase()}:teaser` : internalLinkTypeNotPdp
      }","eventType": "${
        isPdp ? "navigation" : "teaser"
      }","eventMsg":"n/a","eventStatus":"n/a","eventAction":"${pageType.toLowerCase()}:${mainTitle}:rm"}`}
    >
      <ConditionalWrapper
        condition={linkDescription}
        wrapper={children => (
          <a
            href={shortenedLinkUrl}
            rel={linkURLRef ? "noreferrer" : ""}
            target={target}
          >
            {children}
          </a>
        )}
      >
        <div>{sanitizeInnerHtml(details)}</div>
      </ConditionalWrapper>
    </div>
  )
}

export default Description
