import React from "react"

import FavouritesUtility from "@/components/Default/Teaser/v1/FavouritesUtility"
import ShareUtility from "@/components/Default/Teaser/v1/ShareUtility"
import PrintUtility from "@/components/Default/Teaser/v1/PrintUtility"
import DownloadUtility from "@/components/Default/Teaser/v1/DownloadUtility"

import { UTILITY_CONSTANTS } from "@/constants"

const Utilities = ({
  utilities = [],
  title = "",
  assetID = "",
  imageServerUrl = "",
  alt = "",
  pageType = "",
  dataLayerLinkType = "",
  mainTitle = "",
  downLoadLink = "",
  containerId = "",
}) => {
  return (
    <div className="cmp-teaser__icons">
      {utilities.map((item, index) => {
        switch (item?.utilitiesType) {
          case UTILITY_CONSTANTS.FAVOURITES:
            return (
              <FavouritesUtility
                key={`${item?.utilitiesType}-${index}`}
                title={title}
                assetID={assetID}
                imageServerUrl={imageServerUrl}
                alt={alt}
                containerId={containerId}
              />
            )
          case UTILITY_CONSTANTS.SHARE:
            return (
              <ShareUtility
                key={`${item?.utilitiesType}-${index}`}
                title={title}
                assetID={assetID}
                imageServerUrl={imageServerUrl}
                alt={alt}
                pageType={pageType}
                dataLayerLinkType={dataLayerLinkType}
                containerId={containerId}
              />
            )
          case UTILITY_CONSTANTS.PRINT:
            return (
              <PrintUtility
                key={`${item?.utilitiesType}-${index}`}
                title={title}
                assetID={assetID}
                imageServerUrl={imageServerUrl}
                alt={alt}
                pageType={pageType}
                dataLayerLinkType={dataLayerLinkType}
                mainTitle={mainTitle}
              />
            )
          case UTILITY_CONSTANTS.DOWNLOAD:
            return (
              <DownloadUtility
                key={`${item?.utilitiesType}-${index}`}
                title={title}
                assetID={assetID}
                imageServerUrl={imageServerUrl}
                alt={alt}
                pageType={pageType}
                dataLayerLinkType={dataLayerLinkType}
                mainTitle={mainTitle}
                downLoadLink={downLoadLink}
              />
            )
          default:
            return null
        }
      })}
    </div>
  )
}

export default Utilities
