import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import _isEmpty from "lodash/isEmpty"
import Modal from "@/components/core/Modal/Modal"
import { sanitizeText, sanitizeInnerHtml } from "@/utils/helper"
import Button from "@/components/core/Button/Button"
import { GLOBAL_CONFIGURATION_ENDPOINT } from "@/constants/index"
import { aemAxiosInstance } from "@/constants/api"
import {
  hideFindingModelPopup,
  selectGenericState,
  showFindingModelPopup,
} from "@/store/features/genericSlice"
import {
  getAnalyticsEventInfo,
  productInfoNextEventInfo,
} from "@/components/FindingModelNumber/v1/analyticsEventType"

const FindingModelNumber = ({ authorData = {} }) => {
  const dispatch = useDispatch()
  const { FindingModelModal: { show: isShowModelNumberModal } = {} } =
    useSelector(selectGenericState)
  const [findingModelData, setFindingModelData] = useState({})

  useEffect(() => {
    document.querySelectorAll('a[href="#FindingModelNumber"]').forEach(el => {
      el.addEventListener("click", showFindingModelPopupHandler)
    })
    findModelNumber()

    return () => {
      document.querySelectorAll('a[href="#FindingModelNumber"]').forEach(el => {
        el.removeEventListener("click", showFindingModelPopupHandler)
      })
    }
  }, [])

  const showFindingModelPopupHandler = e => {
    e.preventDefault()
    dispatch(showFindingModelPopup())
  }

  const findModelNumber = () => {
    if (!_isEmpty(authorData.findModelNumber)) {
      aemAxiosInstance({
        url: GLOBAL_CONFIGURATION_ENDPOINT,
        params: {
          path: authorData.findModelNumber,
        },
      })
        .then(response => {
          if (response && response.data) {
            setFindingModelData(response.data ?? {})
          } else {
            console.error(
              "Finding a model number: Error occured while fetching data from Get URL Property"
            )
          }
        })
        .catch(err => console.error(err))
    }
  }

  const compareWidth = (a, b) => {
    if (Number(a.width) > Number(b.width)) return -1
    if (Number(a.width) < Number(b.width)) return 1
    return 0
  }

  return (
    <div className="finding-model-number">
      <Modal
        StickCloseButton={true}
        closeButtonAnalytics={getAnalyticsEventInfo()}
        needAnalyticsOnContentsLink={true}
        onModalClose={() => dispatch(hideFindingModelPopup())}
        showModal={isShowModelNumberModal}
      >
        {!_isEmpty(findingModelData) && (
          <div>
            <div className="title">
              <h5>
                {!_isEmpty(findingModelData?.title)
                  ? sanitizeText(findingModelData?.title)
                  : ""}
              </h5>
            </div>
            <div className="image-explanation">
              <img
                src={
                  findingModelData?.assetPath
                    ? findingModelData?.assetPath
                    : findingModelData?.smartcrop?.sort(compareWidth)
                }
                width="80%"
                alt=""
              />
            </div>
            <div
              className={`finding-model-number__content ${
                findingModelData?.style === "des-bg-off-white"
                  ? "finding-model-number__content-bg"
                  : ""
              }`}
            >
              <div className="description">
                {sanitizeInnerHtml(findingModelData?.description ?? "")}
              </div>
              {findingModelData?.ctaLinkText && (
                <div className="signUp-text">
                  <Button
                    className="gbh-data-layer"
                    data-gbh-data-layer={productInfoNextEventInfo()}
                    type="black"
                    label={findingModelData?.ctaLinkText ?? ""}
                    to={findingModelData?.ctaLink ?? ""}
                    target={
                      findingModelData?.newTabLink === "true"
                        ? "_blank"
                        : "_self"
                    }
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default FindingModelNumber
