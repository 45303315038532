import { useState } from 'react';

const useTrigger = (cap = 3) => {
  const [value, setValue] = useState(0);
  const triggerAction = () =>
    setValue((item) => {
      return ((item + 1) % cap) + 1;
    });
  return [value, triggerAction];
};

export default useTrigger;
