import React, { useEffect } from "react"
import styles from "@/components/ShopTheRoomDetail/v1/overlay.module.scss"

const Overlay = ({ isOpen }) => {
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto"
  }, [isOpen])

  return isOpen && <div className={styles.overlayWrapper}>Overlay</div>
}

export default Overlay
